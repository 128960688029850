import styled from "styled-components";

export const CurrImg = styled.div`
  position: relative;
  background: linear-gradient(199deg, rgba(2,0,36,0) 82%, rgba(255,206,0,1) 96%);
  width: 140vw;

  @keyframes slidein {
    from {
      margin-left: 30%;
    }

    to {
      margin-left: 0%;
  }
`;

export const AlbumTitle = styled.p`
  position: absolute;
  bottom: 0;
  left: 20px;
  color: white;
  font-size: 30px;
  text-transform: uppercase;
`;
export const Paragraph = styled.p`
  position: absolute;
  bottom: 17px;
  left: 20px;
  color: white;
  font-weight: bold;
`;

export const Paragraph2 = styled.p`
  position: absolute;
  bottom: 0px;
  left: 20px;
  color: white;
`;

export const ImgWrapper = styled.div`
  margin-left: 2px;
  background-color: #000000;
  position: relative;
  border-radius: 6px;
  width: 45vw;
`;
