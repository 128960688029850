import { useEffect, useState } from "react";
import { Calendar } from "antd";
import type { CalendarProps } from "antd";
import styles from "./LetsChatCalendar.module.css";
import "./antd-calendar-enhanced.css";
import Asset4 from "../../images/Asset4.png";
import dayjs, { Dayjs } from "dayjs";
import { letsChatAPI } from "./LetsChatCalendarAPI";
import ReservationModal from "./ReservationModal";
import Header from "./CalendarHeader/CalendarHeader";
import AvailableSlots from "./AvailableSlots/AvailableSlots";

type Slot = {
  start_time: string;
  end_time: string;
  date: string;
};

export default function LetsChatCalendar(props: any) {
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [slots, setSlots] = useState<Slot[]>([]);
  const [isReservationOpened, setReservationOpened] = useState<boolean>(false);
  const [selectedSlot, setSelecteSlot] = useState<Slot>();
  const isMobile = window.innerWidth <= 768;

  const availableDates = new Set<string>(slots.map((slot) => slot.date));

  const onMonthSelect = (month: number, year?: number) => {
    let newDate = selectedDate.month(month).startOf("month");
    if (year) {
      newDate = newDate.year(year);
    }
    if (
      newDate.isSame(dayjs().startOf("month")) ||
      newDate.isAfter(dayjs().startOf("month"))
    ) {
      setSelectedDate(newDate);
      fetchSlots(newDate);
    }
  };

  const onYearSelect = (year: number) => {
    const newDate = selectedDate.year(year);
    if (
      newDate.isSame(dayjs().startOf("year")) ||
      newDate.isAfter(dayjs().startOf("year"))
    ) {
      setSelectedDate(newDate);
      fetchSlots(newDate);
    }
  };

  // const onPanelChange = (value: Dayjs, mode: CalendarProps<Dayjs>["mode"]) => {
  //   console.log(value.format("YYYY-MM-DD"), mode);
  // };

  const handleCalendarChange = (value: Dayjs) => {
    setSelectedDate(dayjs(value.format("YYYY-MM-DD")));
  };

  const isDisabledDate = (date: Dayjs) => {
    return !availableDates.has(date.format("YYYY-MM-DD"));
  };

  const fetchSlots = (date: Dayjs = selectedDate) => {
    const range_start = date.startOf("month").format("DD/MM/YYYY");
    const range_end = date.endOf("month").format("DD/MM/YYYY");

    letsChatAPI.getAvailableSlots(range_start, range_end, 30).then((res) => {
      setSlots(res);
      res.length > 0 && setSelectedDate(dayjs(res[0].date));
    });
  };

  const onReservationModalClose = () => {
    setReservationOpened(false);
  };

  const handleSlotSelected = (slot: Slot) => {
    setSelecteSlot(slot);
    setReservationOpened(true);
  };

  const goToPreviousDate = () => {
    const getPreviousAvailableDate = () => {
      const prevMonth = dayjs(selectedDate).subtract(1, "month").month();
      const prevYear = dayjs(selectedDate).subtract(1, "month").year();
      onMonthSelect(prevMonth, prevYear);
    };
    getPreviousAvailableDate();
  };

  const goToNextDate = () => {
    const getNextAvailableDate = () => {
      const nextMonth = dayjs(selectedDate).add(1, "month").month();
      const nextYear = dayjs(selectedDate).add(1, "month").year();
      onMonthSelect(nextMonth, nextYear);
    };
    getNextAvailableDate();
  };

  useEffect(() => {
    fetchSlots();
  }, []);

  const getCellColor = (value: Dayjs) => {
    if (value.month() !== selectedDate.month()) {
      return styles.colorOtherMonth;
    } else if (value.day() === 5 || value.day() === 6) {
      return styles.colorWeekend;
    } else if (selectedDate.isSame(value, "day")) {
      return styles.colorSelectedDate;
    } else if (availableDates.has(value.format("YYYY-MM-DD"))) {
      return styles.colorAvailableDate;
    }
    return styles.colorDefault;
  };

  const dateCellRender = (value: Dayjs) => (
    <div className={styles.dateCell}>
      <div className={`${styles.dateNumber} ${getCellColor(value)}`}>
        {value.date()}
      </div>
    </div>
  );

  const monthCellRender = (value: Dayjs) => (
    <div className={styles.monthCell}>
      <div className={styles.monthName}>{value.format("dddd")}</div>
    </div>
  );

  const cellRender: CalendarProps<Dayjs>["cellRender"] = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    if (info.type === "month") return monthCellRender(current);
    return info.originNode;
  };

  const headerRender = (data: any) => {
    const currentDate = data.value as Dayjs;
    return (
      <Header
        selectedDate={currentDate}
        onMonthSelect={onMonthSelect}
        onYearSelect={onYearSelect}
      />
    );
  };

  return (
    <>
      <div className={styles.calendarContainer}>
        <AvailableSlots
          slots={slots}
          selectedDate={selectedDate}
          handleSlotSelected={handleSlotSelected}
          goToNextDate={goToNextDate}
          goToPreviousDate={goToPreviousDate}
        />
        <div className={styles.calendarLayout}>
          <div className={styles.sidebar} />
          <div className={styles.calendarMain}>
            <Calendar
              className="letschatecalendar"
              style={{ width: "100%" }}
              // onPanelChange={onPanelChange}
              fullCellRender={cellRender}
              headerRender={headerRender}
              onChange={handleCalendarChange}
              value={selectedDate}
              disabledDate={isDisabledDate}
            />
          </div>
        </div>
        {!isMobile && (
          <div className={styles.imageContainer}>
            <img src={Asset4} />
          </div>
        )}
      </div>
      <ReservationModal
        open={isReservationOpened}
        date={selectedDate}
        onClose={onReservationModalClose}
        startTime={selectedSlot?.start_time}
        validate={fetchSlots}
      />
    </>
  );
}
