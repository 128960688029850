import { Modal, Button, Form, Input, message } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { letsChatAPI } from "./LetsChatCalendarAPI";
import { useState } from "react";
import PhoneInput from "antd-phone-input";

interface ValidationModalProps {
  onClose: () => void;
  open: boolean;
  validate: () => void;
  date: Dayjs;
  startTime: string | undefined;
}
const validator = (_: any, { valid }: { valid: any }) => {
  if (valid(true)) return Promise.resolve();
  return Promise.reject("Invalid phone number");
};

const getPhoneNumber = (phoneNumberObj: any | undefined) => {
  if (!phoneNumberObj) return undefined;
  return (
    "+" +
    phoneNumberObj.countryCode +
    phoneNumberObj.areaCode +
    phoneNumberObj.phoneNumber
  );
};

const ReservationModal = (props: ValidationModalProps) => {
  const { onClose, open, validate, date, startTime } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();

  const handleCancel = () => {
    onClose();
  };

  const onFinish = async (values: any) => {
    if (!startTime) return;
    setIsSubmitting(true);
    letsChatAPI
      .reserveSlot(
        dayjs(startTime, "HH:mm:ss").format("HH:mm"),
        date.format("DD/MM/YYYY"),
        30,
        values.email,
        values.job_title,
        values.company_name,
        values.name,
        getPhoneNumber(values.phone_number || undefined)
      )
      .then((data: any) => {
        message.success(data.message);
        validate();
        onClose();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Modal
      title="Reserve Slot"
      open={props.open}
      onCancel={handleCancel}
      width={"50%"}
      footer={[
        <Button key="cancel" onClick={handleCancel} type="primary">
          Cancel
        </Button>,
      ]}
    >
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 25 }}
        layout="horizontal"
        style={{ maxWidth: 1000 }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input your Name!",
            },
          ]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
            {
              type: "email",
              message: "The input is not a valid email!",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          label="Job Title"
          name="job_title"
          rules={[
            {
              required: true,
              message: "Please input your Job Title!",
            },
          ]}
        >
          <Input placeholder="Job Title" />
        </Form.Item>
        <Form.Item
          label="Company Name"
          name="company_name"
          rules={[
            {
              required: true,
              message: "Please input your Company Name!",
            },
          ]}
        >
          <Input placeholder="Company Name" />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phone_number"
          rules={[
            {
              required: true,
            },
            {
              validator,
            },
          ]}
        >
          <PhoneInput enableSearch placeholder="Phone number" />
        </Form.Item>

        <Form.Item style={{ float: "right", marginLeft: "5px" }}>
          <Button type="primary" htmlType="submit" loading={isSubmitting}>
            Reserve
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReservationModal;
