import { styled } from "styled-components";

export const ContainerDiv = styled.div`
  background-color: #f4f4f4;
  margin: 20px -235px 30px;
  padding-bottom: 60px;

  @media screen and (max-width: 768px) {
    margin: 20px -100px 30px;
  }
`;

export const Desc = styled.p`
  font-family: Cairo-Regular, abdomaster-medium, sans-serif;
  font-size: 26pt;
  white-space: pre-line;
  text-align: center;
  margin-top: 20px;
`;

export const Desc2 = styled.p`
  font-family: abdomaster-medium, Cairo-Regular, sans-serif;
  font-size: 20pt;
  white-space: pre-line;
  text-align: center;
  color: #ffd100;
  margin-top: 20px;
`;
