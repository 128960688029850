import React from "react";
import { useGetHowDifferent } from "../../network/controllers/howDifferent";
import { ImageCard, CoverImage, Line, Header, Desc } from "./HowDifferentStyle";

interface ImProp {
  imageUrl: string;
  title: string;
  description: string;
}

const CenteredImageCard = ({ imageUrl, title, description }: ImProp) => {
  return (
    <ImageCard cover={<CoverImage alt={title} src={imageUrl} />}>
      <Line />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Header>{title}</Header>
        <Desc dangerouslySetInnerHTML={{ __html: description }}></Desc>
      </div>
    </ImageCard>
  );
};

function HowDifferent() {
  const howDifferentData: any[] = useGetHowDifferent();
  return (
    <>
      {howDifferentData &&
        howDifferentData.map((item) => {
          return (
            <CenteredImageCard
              imageUrl={item.image}
              title={item.title}
              description={item.description}
            />
          );
        })}
    </>
  );
}

export default HowDifferent;
