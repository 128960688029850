import { DeleteFilled, PlusCircleTwoTone, EditFilled } from "@ant-design/icons";
import { Space, Table } from "antd";
import { Header, InternalPages, SubHeader } from "../../types/types";
import { useState, useEffect } from "react";
import api from "../../api/axiosInterceptor";
import DeleteModal from "../Modals/DeleteModal";
import EditNavBar from "./EditNavBar";
import InsertNavBar from "./InsertNavBar";

const ManageNavBar = () => {
  const [isInsertOpen, setIsInsertOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isInsertOpen2, setIsInsertOpen2] = useState(false);
  const [isDeleteOpen2, setIsDeleteOpen2] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [internalPages, setIsInternalPages] = useState<InternalPages[]>([]);
  const [id, setId] = useState<number>(0);
  const [recordId, seRecordtId] = useState<number>(0);
  const [data, setData] = useState<Header[]>([]);
  const [data2, setData2] = useState<SubHeader[]>([]);

  const [refresh, setRefresh] = useState(false);

  const columns = [
    {
      title: "id",
      width: "5%",
      render: (_: any, record: Header) => data.indexOf(record) + 1,
    },
    {
      title: "Header Title",
      dataIndex: "title",
      key: "title",
      render: (text: string) => text,
      align: "center" as const,
    },
    {
      title: "Header Order",
      dataIndex: "order",
      key: "order",
      render: (text: string) => text,
      align: "center" as const,
    },
    {
      title: "Actions",
      key: "action",
      render: (_: any, record: any) => (
        <Space size="middle">
          <a
            onClick={() => {
              setIsEditOpen(true);
              seRecordtId(data.indexOf(record) + 1);
              setId(record.id);
            }}
          >
            <EditFilled
              style={{ fontSize: "20px", color: "green" }}
              rev={undefined}
            />
          </a>
          <a
            onClick={() => {
              setIsDeleteOpen(true);
              setId(record.id);
            }}
          >
            <DeleteFilled
              style={{ fontSize: "20px", color: "red" }}
              rev={undefined}
            />
          </a>
        </Space>
      ),
      align: "center" as const,
    },
  ];
  const columnsSub = [
    {
      title: "id",
      width: "5%",
      render: (_: any, record: SubHeader) => data2.indexOf(record) + 1,
    },
    {
      title: "Header",
      dataIndex: "header_title",
      key: "header_title",
      render: (header_title: Header) => header_title?.title,
      align: "center" as const,
    },
    {
      title: "Subtitle",
      dataIndex: "subtitle",
      key: "subtitle",
      render: (text: string) => text,
      align: "center" as const,
    },
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      render: (text: string) => text,
      align: "center" as const,
    },
    {
      title: "Actions",
      key: "action",
      render: (_: any, record: any) => (
        <Space size="middle">
          <a
            onClick={() => {
              setIsDeleteOpen2(true);
              setId(record.id);
            }}
          >
            <DeleteFilled
              style={{ fontSize: "20px", color: "red" }}
              rev={undefined}
            />
          </a>
        </Space>
      ),
      align: "center" as const,
    },
  ];
  const getData = async () => {
    try {
      const res = await api.get("api/header/");
      const res2 = await api.get("api/subheaders/");
      if (res) {
        setData(res.data);
      }
      if (res2) {
        setData2(res2.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getInternalPages = async () => {
    const res = await api.get("/api/internalPages/");
    setIsInternalPages(res.data);
  };

  useEffect(() => {
    getData();
    getInternalPages();
  }, [refresh, isInsertOpen2]);

  return (
    <div style={{ margin: "50px" }}>
      <div onClick={() => setIsInsertOpen(true)}>
        <PlusCircleTwoTone
          rev={undefined}
          style={{
            fontSize: "25px",
            margin: "15px",
            verticalAlign: "middle",
          }}
        />
        <span
          style={{
            verticalAlign: "middle",
            marginBottom: "25px",
          }}
        >
          Insert Headers
        </span>
      </div>
      <Table bordered columns={columns} dataSource={data} pagination={false} />
      <div onClick={() => setIsInsertOpen2(true)}>
        <PlusCircleTwoTone
          rev={undefined}
          style={{
            fontSize: "25px",
            margin: "15px",
            verticalAlign: "middle",
          }}
        />
        <span
          style={{
            verticalAlign: "middle",
            marginBottom: "25px",
          }}
        >
          Insert Sub Headers
        </span>
      </div>
      <Table
        bordered
        columns={columnsSub}
        dataSource={data2}
        pagination={false}
      />
      <EditNavBar
        isEditOpen={isEditOpen}
        setIsEditOpen={setIsEditOpen}
        id={id}
        data={data[recordId - 1]}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <DeleteModal
        isDeleteOpen={isDeleteOpen || isDeleteOpen2}
        setIsDeleteOpen={isDeleteOpen2 ? setIsDeleteOpen2 : setIsDeleteOpen}
        refresh={refresh}
        id={id}
        setRefresh={setRefresh}
        name={isDeleteOpen2 ? "subheaders" : "header"}
      />
      <InsertNavBar
        isInsertOpen={isInsertOpen || isInsertOpen2}
        setIsInsertOpen={isInsertOpen2 ? setIsInsertOpen2 : setIsInsertOpen}
        refresh={refresh}
        setRefresh={setRefresh}
        name={isInsertOpen ? "header" : "subheaders"}
        headers={data}
        internalPages={internalPages}
      />
    </div>
  );
};

export default ManageNavBar;
