import { Modal, Upload, Button, message, Form, Input, Checkbox } from "antd";
import api from "../../api/axiosInterceptor";
import { useEffect, useRef, useState } from "react";
import { TalentDetailsFAQ } from "../../types/types";
import { DeleteFilled } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

interface EditModalProps {
  setIsEditOpen: (isModalOpen: boolean) => void;
  isEditOpen: boolean;
  refresh: boolean;
  id: number | undefined;
  setRefresh: (refresh: boolean) => void;
  data: TalentDetailsFAQ;
}

const EditModal = (props: EditModalProps) => {
  const [Image, setImage] = useState<Blob>();
  const editorRef: any = useRef(null);
  const [form] = Form.useForm();

  const handleCancel = () => {
    props.setIsEditOpen(false);
  };

  const handleEdit = (values: any) => {
    const data = new FormData();
    data.append("title", values.title);
    data.append("description", values.description);
    let image: Blob | string = Image || props.data.image;

    if (typeof image === "string") {
      image = image.split("media")[1];
    }
    image && data.append("image", image);

    onFinish(data, false);
  };

  const onFinish = async (data: any, image: boolean) => {
    console.log(data);

    await api
      .put(`api/talentDetailsFAQ/${props?.id}/`, data)
      .then((result: { data: any }) => {
        image
          ? message.success("image deleted successfully")
          : message.success(`Edited successfully`);
        props.setRefresh(!props.refresh);
        !image && props.setIsEditOpen(false);
        return result && result.data;
      })
      .catch(() => {
        message.error(`Edited failed.`);
      });
  };

  const deleteImage = () => {
    const values = {
      title: props.data?.title,
      description: props.data?.description,
      image: null,
    };
    onFinish(values, true);
    setImage(undefined);
  };

  useEffect(() => {
    form.setFieldsValue({
      title: props.data?.title,
      description: props.data?.description,
      Image: props.data?.image,
    });
  }, [props.data]);

  return (
    <Modal
      title="Edit Section"
      open={props.isEditOpen}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel} type="primary">
          Cancel
        </Button>,
      ]}
      width={"50%"}
    >
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        style={{ maxWidth: 1000 }}
        onFinish={handleEdit}
        initialValues={{
          title: props.data?.title,
          description: props.data?.description,
          image: props.data?.image,
        }}
        form={form}
      >
        <Form.Item label="Title" name="title" rules={[{ required: true }]}>
          <Input placeholder="title" />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <TextArea />
        </Form.Item>
        <Form.Item label="Image" name="image">
          <Upload
            name={"image"}
            id={"image"}
            accept="image/*"
            beforeUpload={(file) => {
              setImage(file);
              return false;
            }}
          >
            <Button>Choose Icon</Button>
          </Upload>
          {props.data?.image && (
            <p>
              {props.data.image.split("media/")[1]}{" "}
              <DeleteFilled
                style={{ fontSize: "15px", color: "red" }}
                rev={undefined}
                onClick={() => deleteImage()}
              />
            </p>
          )}
        </Form.Item>
        <Form.Item style={{ float: "right", marginLeft: "5px" }}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditModal;
