import { useState, useEffect, SetStateAction } from "react";
import { Space, Table, message } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import api from "../../../../api/axiosInterceptor";
import DeleteModal from "../../../Modals/DeleteModal";
import styles from "./Reservations.module.css";

const Reservations = () => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [data, setData] = useState([]);
  const [id, setId] = useState();
  const [refresh, setRefresh] = useState(false);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      key: "start_time",
      render: (time: string) => time.slice(0, 5),
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      key: "end_time",
      render: (time: string) => time.slice(0, 5),
    },
    {
      title: "Actions",
      key: "action",
      render: (_: any, record: { id: SetStateAction<undefined> }) => (
        <Space size="middle">
          <a
            onClick={() => {
              setIsDeleteOpen(true);
              setId(record.id);
            }}
          >
            <DeleteFilled className={styles.deleteIcon} />
          </a>
        </Space>
      ),
    },
  ];

  const getReservedSlots = async () => {
    try {
      const res = await api.get("/api/calendar/reservations/"); // Replace with your actual API endpoint
      setData(res.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("Error fetching reserved slots");
    }
  };

  useEffect(() => {
    getReservedSlots();
  }, [refresh]);

  return (
    <div className={styles.reservationsContainer}>
      <Table
        bordered
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="id"
      />
      <DeleteModal
        isDeleteOpen={isDeleteOpen}
        setIsDeleteOpen={setIsDeleteOpen}
        id={id}
        refresh={refresh}
        setRefresh={setRefresh}
        name={"calendar/reservations"}
      />
    </div>
  );
};

export default Reservations;
