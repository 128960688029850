import { StyledCard, Line, Desc } from "./TalentDetailsStyles";
import { useGetTalentDetailsFAQs } from "../../network/controllers/talentDetailsFAQs";

interface TalentDetailsFAQsInfo {
  title: string;
  description: string;
  ImageUrl: string;
}

function TalentDetailsFAQCard({
  title,
  description,
  ImageUrl,
}: TalentDetailsFAQsInfo) {
  return (
    <>
      <StyledCard
        title={
          <div>
            <img
              src={ImageUrl}
              style={{ marginTop: "42px", width: "84px", height: "84px" }}
            />
            <br />
            <h1 style={{ fontSize: "1.5rem", fontWeight: "bolder" }}>
              {title}
            </h1>
            <br />
            <Line />
          </div>
        }
        style={{
          width: "100%",
          margin: "10px auto",
          border: "1px solid",
          borderRadius: "25px",
        }}
      >
        <Desc dangerouslySetInnerHTML={{ __html: description }}></Desc>
      </StyledCard>
    </>
  );
}

function TalentDetailsFAQs() {
  const talentDetailsFAQsData: any[] = useGetTalentDetailsFAQs();
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "80px",
        }}
      >
        {talentDetailsFAQsData &&
          talentDetailsFAQsData.map((item, index) => {
            return (
              <>
                <TalentDetailsFAQCard
                  key={index}
                  title={item.title}
                  description={item.description}
                  ImageUrl={item.image}
                />
              </>
            );
          })}
      </div>
    </>
  );
}

export default TalentDetailsFAQs;
