import { useGetActiveLifeAtAsal } from "../../network/controllers/lifeAtAsal";
import ReactPlayer from "react-player";

function LifeAtAsal() {
  const life_at_asal = useGetActiveLifeAtAsal();
  if (!life_at_asal) return <></>;
  return (
    <>
      <ReactPlayer
        url={life_at_asal.video}
        playing={true}
        muted={true}
        loop={true}
        controls={true}
        width="100%"
        style={{
          objectFit: "cover",
          zIndex: "1",
          minHeight: "90vh",
        }}
      />
    </>
  );
}

export default LifeAtAsal;
