import { useState, useEffect, useRef } from "react";
import { Image } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Album, Gallery } from "../../types/types";
import api from "../../api/axiosInterceptor";
import line from "../../images/line.png";
import { Section, Title } from "../../theme/general";
import { i18n } from "../../translations";
import {
  CurrImg,
  AlbumTitle,
  Paragraph,
  Paragraph2,
  ImgWrapper,
} from "./GalleryStyle";

interface GalleryProps {
  album?: Album;
  fromInternal?: boolean;
}

const GalleryComp = (props: GalleryProps) => {
  const [gallery, setGallery] = useState<string[]>([]);
  const [photo, setPhoto] = useState("");
  const animationFlag = useRef<HTMLInputElement>(null);

  const getImages = async () => {
    try {
      const res = await api.get("api/gallery/");
      const album = await api.get("api/gallery/all");
      let images: string[] = [];
      if (album && props.album) {
        images = album.data
          .filter((item: Gallery) => item.album.id === props.album?.id)
          .map((item: Gallery) => item.image)
          .sort(() => Math.random() - Math.random())
          .slice(0, 4);
      } else if (res) {
        images = res.data.map((item: Gallery) => item.image);
      }
      setPhoto(images[0]);
      images.shift();
      setGallery(images);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getImages();
  }, []);

  const handlePhotos = (image: string) => {
    setPhoto(image);
    let arr = [];

    if (animationFlag.current != null) {
      animationFlag.current.style.animation = "1s slidein";
    }
    arr = gallery.filter((img) => img !== image);
    arr.push(photo);
    setGallery(arr);
  };

  const handleAnimationEnd = () => {
    if (animationFlag.current != null) {
      animationFlag.current.style.animation = "none";
    }
  };

  const showGallery = () => {
    return (
      <div
        style={{
          marginTop: "50px",
          display: "flex",
        }}
      >
        <CurrImg ref={animationFlag} onAnimationEnd={handleAnimationEnd}>
          <Image
            src={photo}
            style={{
              borderRadius: "6px",
              objectFit: "cover",
              position: "relative",
              zIndex: "-1",
              opacity: props.album ? "0.8" : "1",
            }}
            width={"100%"}
            height={"430px"}
            preview={false}
          />
          {props.album?.album_title && (
            <AlbumTitle> {props.album?.album_title}</AlbumTitle>
          )}
        </CurrImg>

        {gallery.map((image) => {
          return (
            <ImgWrapper>
              <Image
                src={image}
                style={{
                  objectFit: "cover",
                  opacity: "0.5",
                  borderRadius: "6px",
                }}
                width={"100%"}
                height="430px"
                preview={false}
              />
              <a
                style={{ position: "absolute", bottom: "13px", right: "18px" }}
                onClick={() => handlePhotos(image)}
              >
                <PlusOutlined
                  style={{ color: "#FED109", fontSize: "30px" }}
                  rev={undefined}
                />
              </a>
            </ImgWrapper>
          );
        })}
      </div>
    );
  };

  return !props.album && !props.fromInternal ? (
    <Section style={{ paddingRight: "12%" }}>
      <img src={line} height={"45px"} width={"3px"} />
      <Title>{i18n.t("gallery_title")}</Title>
      {showGallery()}
    </Section>
  ) : (
    <div>{showGallery()}</div>
  );
};

export default GalleryComp;
