import React from "react";
import { LineOne } from "./Number";

const NUMBER_OF_MIN_COUNT = 23;
const Counter = ({
  number,
  prefix = "",
  postfix = "",
  startCount = false,
}: {
  number: number;
  prefix?: string;
  postfix?: string;
  startCount?: boolean;
}) => {
  const [count, setCount] = React.useState(
    number < NUMBER_OF_MIN_COUNT ? 0 : number - NUMBER_OF_MIN_COUNT,
  );

  React.useEffect(() => {
    if (!startCount) {
      return;
    }
    const interval = setInterval(() => {
      if (count < number) {
        setCount((prevCount) => prevCount + 1);
      }
    }, 50);

    return () => clearInterval(interval);
  }, [number, count, startCount]);

  return (
    <LineOne>
      {prefix}
      {count}
      {postfix}
    </LineOne>
  );
};

export default Counter;
