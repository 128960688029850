import React, { useState } from "react";
import { useGetLeaders } from "../../network/controllers/leaders";
import LeadershipCard from "./LeadershipCard";
import LeadershipDetailsPopup from "./LeadershipDetails";

function Leadership() {
  const [popup, setPopup] = useState(-1);
  const leaders = useGetLeaders();
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexDirection: "row",
          flexWrap: "wrap",
          marginBottom: "48px",
        }}
      >
        {leaders.map((leader: any) => (
          <LeadershipCard
            id={leader.id}
            name={leader.name}
            image_url={leader.cover_image}
            setLeader={setPopup}
          />
        ))}
      </div>
      <LeadershipDetailsPopup popupOpen={popup} setPopup={setPopup} />
    </>
  );
}

export default Leadership;
