import { Row, Col } from "antd";
import {
  StyledCard,
  Line,
  Desc,
  TitleDiv,
  TitleHeader,
} from "./IndustriesStyles";
import { useGetIndustry } from "../../network/controllers/industry";
import { i18n } from "../../translations";

interface IndustryInfo {
  title: string;
  description: string;
  ImageUrl: string;
}

function IndustryCard({ title, description, ImageUrl }: IndustryInfo) {
  return (
    <StyledCard
      title={
        <div>
          <img src={ImageUrl} style={{ marginTop: "25px" }} />
          <br />
          <h1>{title}</h1>
        </div>
      }
      style={{
        width: "100%",
        margin: "auto",
        marginTop: "60px",
        border: "1px solid",
        borderRadius: "25px",
      }}
    >
      <Line />
      <Desc dangerouslySetInnerHTML={{ __html: description }}></Desc>
    </StyledCard>
  );
}

function Industries() {
  const industriesData: any[] = useGetIndustry();
  return (
    <>
      <div style={{ paddingBottom: "200px" }}>
        <TitleDiv>
          <TitleHeader>
            {i18n.t("industries_title")}
            {"  "}
          </TitleHeader>
        </TitleDiv>
        <Row gutter={[64, 16]}>
          {industriesData &&
            industriesData.map((item, index) => {
              return (
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={8}
                  xl={8}
                  key={index}
                  style={{ display: "flex" }}
                >
                  <IndustryCard
                    title={item.title}
                    description={item.description}
                    ImageUrl={item.image}
                  />
                </Col>
              );
            })}
        </Row>
      </div>
    </>
  );
}

export default Industries;
