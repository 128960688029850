import HeaderComp from '../components/Header/Header'

import Footer from '../components/Footer/Footer'
import Career from '../components/Career/Careers'
import Partners from '../components/Partners/Partners'
import GalleryComp from '../components/Gallery/GallerySlider'
import Numbers from '../components/Numbers/Numbers'
import Services from '../components/Services/Services'

const Home = () => {
  return (
    <>
      <HeaderComp />
      <div>
        <Numbers />
        <Services />
        <Career />
        <GalleryComp />
        <Partners />
      </div>
      <Footer />
    </>
  )
}

export default Home
