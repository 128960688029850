import { createStore, applyMiddleware, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import sessioStorage from "redux-persist/lib/storage/session";
import thunk from "redux-thunk";
import { userReducer } from "./reducers/userReducer";
import { backgroundReducer } from "./reducers/backgroundReducer";
import { infoReducer } from "./reducers/asalDetailsReducer";
import { headerFooterItemsReducer } from "./reducers/headerFooterItemsReducer";

const persistConfig = {
  key: "root",
  storage: sessioStorage,
};

const rootReducer = combineReducers({
  userObj: userReducer,
  background: backgroundReducer,
  ASALInfo: infoReducer,
  headerFooterItems: headerFooterItemsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(thunk));
export type RootState = ReturnType<typeof rootReducer>;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const persistor = persistStore(store);
export { store, persistor };
