import React from "react";
import styles from "./AvailableSlots.module.css";
import dayjs, { Dayjs } from "dayjs";
import { Typography } from "antd";
import LeftIcon from "../../../assets/svgs/lets-chat-icons/left-icon.svg";
import RightIcon from "../../../assets/svgs/lets-chat-icons/right-icon.svg";
import CalendarIcon from "../../../assets/svgs/lets-chat-icons/calendar-icon.svg";
import SlotToIcon from "../../../assets/svgs/lets-chat-icons/slot-to-icon.svg";

type Slot = {
  start_time: string;
  end_time: string;
  date: string;
};

type AvailableSlotsProps = {
  slots: Slot[];
  selectedDate: Dayjs;
  handleSlotSelected: (slot: Slot) => void;
  goToPreviousDate: () => void;
  goToNextDate: () => void;
};

const AvailableSlots: React.FC<AvailableSlotsProps> = ({
  slots,
  selectedDate,
  handleSlotSelected,
  goToPreviousDate,
  goToNextDate,
}) => {
  const isMobile = window.innerWidth <= 768;
  const renderSlot = (slot: Slot, index: number) => (
    <div
      key={`slot-${index}-${slot.date}`}
      className={styles.slot}
      onClick={() => handleSlotSelected(slot)}
    >
      <div className={styles.slotTime}>
        {dayjs(slot.start_time, "HH:mm:ss").format("HH:mm")}
      </div>
      <img src={SlotToIcon} alt="to" />
      <div className={styles.slotTime}>
        {dayjs(slot.end_time, "HH:mm:ss").format("HH:mm")}
      </div>
      <div className={styles.slotDivider}>|</div>
    </div>
  );

  return (
    <div className={styles.availableSlotsContainer}>
      <img
        src={LeftIcon}
        className={styles.navigationIcon}
        alt="Previous"
        onClick={goToPreviousDate}
      />
      <div className={styles.slotsWrapper}>
        {!isMobile && (
          <img
            src={CalendarIcon}
            alt="Calendar"
            className={styles.calendarIcon}
          />
        )}
        <Typography className={styles.availableSlotsText}>
          Available Slots:{" "}
        </Typography>
        <div className={styles.slots}>
          {slots.map((slot, index) => {
            return slot.date === selectedDate.format("YYYY-MM-DD") ? (
              renderSlot(slot, index)
            ) : (
              <React.Fragment
                key={`empty-${index}-${slot.date}`}
              ></React.Fragment>
            );
          })}
        </div>
      </div>
      <img
        src={RightIcon}
        className={styles.navigationRightIcon}
        alt="Next"
        onClick={goToNextDate}
      />
    </div>
  );
};

export default AvailableSlots;
