import { SET_DETAILS, SetDetails, Details } from "../actions/asalDetails/types";

const initialState: Details = {
  info: {},
};

export const infoReducer = (state = initialState, action: SetDetails) => {
  console.log(action.payload);
  switch (action.type) {
    case SET_DETAILS:
      return { ...state, info: action.payload.info };
    default:
      return state;
  }
};
