import { Album, Gallery } from "../../types/types";
import { Space, Table } from "antd";
import { useState, useEffect } from "react";
import api from "../../api/axiosInterceptor";
import { DeleteFilled, PlusCircleTwoTone } from "@ant-design/icons";
import InsertModal from "./InsertModal";
import DeleteModal from "../Modals/DeleteModal";

const GalleryManage = () => {
  const columns = [
    {
      title: "id",
      width: "5%",
      render: (_: any, record: Gallery) => data.indexOf(record) + 1,
    },
    {
      title: "album",
      dataIndex: "album",
      key: "album",
      render: (album: Album) => album.album_title,
      align: "center" as const,
    },
    {
      title: "image",
      dataIndex: "image",
      key: "image",
      render: (text: string) => (
        <a href={text} target={"_blank"}>
          {text ? "see image" : "-"}
        </a>
      ),
      align: "center" as const,
    },

    {
      title: "Actions",
      key: "action",
      render: (_: any, record: any) => (
        <Space size="middle">
          <a
            onClick={() => {
              setIsDeleteOpen(true);
              setId(record.id);
            }}
          >
            <DeleteFilled
              style={{ fontSize: "20px", color: "red" }}
              rev={undefined}
            />
          </a>
        </Space>
      ),
      align: "center" as const,
    },
  ];
  const albumcolumns = [
    {
      title: "id",
      width: "5%",
      render: (_: any, record: Album) => albums.indexOf(record) + 1,
    },
    {
      title: "album title",
      dataIndex: "album_title",
      key: "title",
      render: (text: string) => text,
      align: "center" as const,
    },

    {
      title: "Actions",
      key: "action",
      render: (_: any, record: any) => (
        <Space size="middle">
          <a
            onClick={() => {
              setIsDeleteOpen2(true);
              setId(record.id);
            }}
          >
            <DeleteFilled
              style={{ fontSize: "20px", color: "red" }}
              rev={undefined}
            />
          </a>
        </Space>
      ),
      align: "center" as const,
    },
  ];
  const [data, setData] = useState<Gallery[]>([]);
  const [albums, setAlbums] = useState<Album[]>([]);
  const [isInsertOpen, setIsInsertOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isInsertOpen2, setIsInsertOpen2] = useState(false);
  const [isDeleteOpen2, setIsDeleteOpen2] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [id, setId] = useState<number>();

  const getImagesAll = async () => {
    try {
      const res = await api.get("api/gallery/all");
      if (res) {
        setData(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getAlbums = async () => {
    try {
      const res = await api.get("api/albums/");

      if (res) {
        setAlbums(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getImagesAll();
    getAlbums();
  }, [refresh]);

  return (
    <div style={{ margin: "50px" }}>
      <div onClick={() => setIsInsertOpen2(true)}>
        <PlusCircleTwoTone
          rev={undefined}
          style={{
            fontSize: "25px",
            margin: "15px",
            verticalAlign: "middle",
          }}
        />
        <span
          style={{
            verticalAlign: "middle",
            marginBottom: "25px",
          }}
        >
          Insert new album
        </span>
      </div>
      <Table
        bordered
        columns={albumcolumns}
        dataSource={albums}
        pagination={false}
      />
      <div onClick={() => setIsInsertOpen(true)}>
        <PlusCircleTwoTone
          rev={undefined}
          style={{
            fontSize: "25px",
            margin: "15px",
            verticalAlign: "middle",
          }}
        />
        <span
          style={{
            verticalAlign: "middle",
            marginBottom: "25px",
          }}
        >
          Insert new image
        </span>
      </div>
      <Table bordered columns={columns} dataSource={data} pagination={false} />
      <InsertModal
        isInsertOpen={isInsertOpen || isInsertOpen2}
        setIsInsertOpen={isInsertOpen2 ? setIsInsertOpen2 : setIsInsertOpen}
        refresh={refresh}
        setRefresh={setRefresh}
        name={isInsertOpen ? "gallery" : "albums"}
        albums={albums}
      />
      <DeleteModal
        isDeleteOpen={isDeleteOpen || isDeleteOpen2}
        setIsDeleteOpen={isDeleteOpen2 ? setIsDeleteOpen2 : setIsDeleteOpen}
        id={id}
        refresh={refresh}
        setRefresh={setRefresh}
        name={isDeleteOpen2 ? "albums" : "gallery"}
      />
    </div>
  );
};

export default GalleryManage;
