import axios from "axios";

const api = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_API_URL,
});

// Add an interceptor to include the token in each request
api.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-undef
    const token = sessionStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = "Token " + token;
      config.headers["Content-Type"] = "multipart/form-data";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const jsonApi = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_API_URL,
});

// Add an interceptor to include the token in each request
jsonApi.interceptors.request.use(
  (config) => {
    // eslint-disable-next-line no-undef
    const token = sessionStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = "Token " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
