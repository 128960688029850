import { Modal, Button, message, Form, Input, InputNumber } from "antd";
import api from "../../api/axiosInterceptor";
import { useEffect } from "react";
import { Header } from "../../types/types";

interface EditModalProps {
  setIsEditOpen: (isModalOpen: boolean) => void;
  isEditOpen: boolean;
  refresh: boolean;
  id: number | undefined;
  setRefresh: (refresh: boolean) => void;
  data: Header;
}

const EditNavBar = (props: EditModalProps) => {
  const [form] = Form.useForm();
  const handleCancel = () => {
    props.setIsEditOpen(false);
  };

  const handleEdit = (values: any) => {
    const data = new FormData();
    data.append("title", values.title);
    data.append("order", values.order);

    onFinish(data);
  };

  const onFinish = async (data: any) => {
    await api
      .put(`api/header/${props?.id}/`, data)
      .then((result: { data: any }) => {
        message.success(`Edited successfully`);
        props.setRefresh(!props.refresh);
        props.setIsEditOpen(false);

        return result && result.data;
      })
      .catch(() => {
        message.error(`Edited failed.`);
      });
  };

  useEffect(() => {
    form.setFieldsValue({
      title: props.data?.title,
    });
  }, [props.data]);

  return (
    <Modal
      title="Edit Menu Item"
      open={props.isEditOpen}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel} type="primary">
          Cancel
        </Button>,
      ]}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        style={{ maxWidth: 600 }}
        onFinish={handleEdit}
        initialValues={{
          title: props.data?.title,
          order: props.data?.order,
        }}
      >
        <Form.Item label="Menu Item Title" name="title">
          <Input />
        </Form.Item>
        <Form.Item label="Menu Item Order" name="order">
          <InputNumber formatter={(value) => `${parseInt(value as string)}`} />
        </Form.Item>
        <Form.Item style={{ float: "right", marginLeft: "5px" }}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditNavBar;
