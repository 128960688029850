import dayjs from "dayjs";

type TimeSlot = {
  startTime: dayjs.Dayjs;
  endTime: dayjs.Dayjs;
};

type DaySlotsWithBoolean = {
  [key: string]: TimeSlot | boolean;
};

type DateSlot = {
  date: dayjs.Dayjs;
  startTime: dayjs.Dayjs;
  endTime: dayjs.Dayjs;
};

type FormDataType = {
  [key: string]: DaySlotsWithBoolean | DateSlot[] | boolean;
};

type BackendData = {
  weekly: {
    day_of_week: number;
    start_time: string;
    end_time: string;
  }[];
  date_specific: {
    specific_date: string;
    start_time: string;
    end_time: string;
  }[];
};

export function formatAvailabilityData(formData: FormDataType): BackendData {
  const formattedData: BackendData = {
    weekly: [],
    date_specific: [],
  };

  const daysOfWeek: { [key: string]: number } = {
    Sunday: 6,
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
    Saturday: 5,
  };

  for (const [day, value] of Object.entries(formData)) {
    if (day === "dateSlots") {
      const dateSlots = value as DateSlot[];
      dateSlots.forEach((slot) => {
        formattedData.date_specific.push({
          specific_date: dayjs(slot.date).format("YYYY-MM-DD"),
          start_time: dayjs(slot.startTime).format("HH:mm"),
          end_time: dayjs(slot.endTime).format("HH:mm"),
        });
      });
    } else if (typeof value !== "boolean" && daysOfWeek[day] !== undefined) {
      const daySlots = value as DaySlotsWithBoolean;
      Object.entries(daySlots).forEach(([key, slot]) => {
        if (key !== "available" && typeof slot !== "boolean") {
          formattedData.weekly.push({
            day_of_week: daysOfWeek[day],
            start_time: dayjs(slot.startTime).format("HH:mm"),
            end_time: dayjs(slot.endTime).format("HH:mm"),
          });
        }
      });
    }
  }

  return formattedData;
}
