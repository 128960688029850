import NavBar from "../NavBar/NavBar";
import { Layout, Tooltip } from "antd";
import "./Bee.css";
import { ReactComponent as HeaderContent } from "../../images/header-content.svg";
import { ReactComponent as Bee } from "../../images/bee-moving.svg";
import styled from "styled-components";
import { SPECIAL_PAGES } from "../../constants/strings";
import React, { useEffect } from "react";
import {
  ecosystem,
  normal,
  reachUs,
  reachUsForm,
  leadership,
  gallery,
  newsletter,
  services,
  employeeExp,
  vacancies,
  joinUs,
  howDifferent,
  trustUs,
  wmtous,
  industries,
  securityAndCompliance,
  letsChat,
  engagementModels,
  blog,
  news,
  trainingPrograms,
  applyAnyway,
  clientWeServedOnTheMap,
  getToKnowUS,
} from "./backgrounds";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { setBackground } from "../../redux/actions/background/bgAction";
import LifeAtAsal from "../LifeAtAsal/LifeAtAsal";
import { useNavigate } from "react-router-dom";

const { Header } = Layout;

const HeaderComp = (props: { title?: string | undefined }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { title } = props;
  const { background } = useSelector((state: RootState) => ({
    background: state.background.image,
  }));

  const Content = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-bottom: 117px;
    @media (max-width: 800px) {
      display: none;
    }
    svg {
      filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 1));
    }
  `;
  const getBackground = () => {
    let background = normal;
    switch (props.title?.trim()) {
      case SPECIAL_PAGES.ECOSYSTEM:
        background = ecosystem;
        break;
      case SPECIAL_PAGES.REACH_US:
        background = reachUs;
        break;
      case SPECIAL_PAGES.REACH_US_FORM:
        background = reachUsForm;
        break;
      case SPECIAL_PAGES.OUR_LEADERSHIP:
        background = leadership;
        break;
      case SPECIAL_PAGES.GALLERY:
        background = gallery;
        break;
      case SPECIAL_PAGES.NEWSLETTER:
        background = newsletter;
        break;
      case SPECIAL_PAGES.SERVICES:
        background = services;
        break;
      case SPECIAL_PAGES.EMPLOYEE_EXP:
        background = employeeExp;
        break;
      case SPECIAL_PAGES.VACANCIES:
        background = vacancies;
        break;
      case SPECIAL_PAGES.JOIN_US:
        background = joinUs;
        break;
      case SPECIAL_PAGES.HOW_DIFFERENT:
        background = howDifferent;
        break;
      case SPECIAL_PAGES.THEY_TRUST_US:
        background = trustUs;
        break;
      case SPECIAL_PAGES.WHAT_MATTER_TO_US:
      case SPECIAL_PAGES.WITHIN_COMMUNITY:
        background = wmtous;
        break;
      case SPECIAL_PAGES.INDUSTRIES:
        background = industries;
        break;
      case SPECIAL_PAGES.SECURITY_AND_COMPLIANCE:
        background = securityAndCompliance;
        break;
      case SPECIAL_PAGES.LETS_CHAT:
        background = letsChat;
        break;
      case SPECIAL_PAGES.ENGAGEMENT_MODELS:
        background = engagementModels;
        break;
      case SPECIAL_PAGES.BLOG:
        background = blog;
        break;
      case SPECIAL_PAGES.NEWS:
        background = news;
        break;
      case SPECIAL_PAGES.TRAINING_PROGRAMS:
        background = trainingPrograms;
        break;
      case SPECIAL_PAGES.APPLAY_ANYWAY:
        background = applyAnyway;
        break;
      case SPECIAL_PAGES.CLIENT_WE_SERVED_ON_THE_MAP:
        background = clientWeServedOnTheMap;
        break;
      case SPECIAL_PAGES.KNOW_US:
        background = getToKnowUS;
        break;
      default:
        background = normal;
    }
    return background;
  };
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    const bg = getBackground();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    dispatch(setBackground(bg));
  }, [title]);

  const commonStyle = {
    minHeight: isMobile ? "20vh" : "100vh",
    boxShadow: "inset 0 120px 80px #000",
    paddingInline: isMobile ? "0" : "20px",
  };

  const HeaderStyleWithBackground = {
    ...commonStyle,
    zIndex: 1,
    backgroundImage: `url(${background})`,
    backgroundSize:
      props.title?.trim() === SPECIAL_PAGES.GALLERY ? "120%" : "cover",
  };

  const HeaderStyleWithVideo = {
    ...commonStyle,
    background: "transparent",
  };

  const HeaderStyle =
    props.title === SPECIAL_PAGES.LIFE_AT_ASAL
      ? HeaderStyleWithVideo
      : HeaderStyleWithBackground;

  return (
    <div>
      <Layout>
        <Header style={HeaderStyle}>
          <NavBar />
          {!props.title && (
            <Content>
              <HeaderContent className="headerContent" />
              <Tooltip placement="topRight" title={"Our Services"}>
                <Bee
                  className="bee"
                  onClick={() =>
                    navigate(`/internal/${SPECIAL_PAGES.SERVICES}`)
                  }
                />
              </Tooltip>
            </Content>
          )}
          {props.title === SPECIAL_PAGES.LIFE_AT_ASAL && <LifeAtAsal />}
        </Header>
      </Layout>
    </div>
  );
};
export default HeaderComp;
