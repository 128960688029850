import styled from "styled-components";

export const Box = styled.div`
  background-color: #f4f4f4;
  margin: 20px -90px 50px -235px;
  padding-bottom: 36px;

  @media (max-width: 768px) {
    margin: 20px -90px 50px -100px;
  }
`;
export const Option = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: 1px solid;
  border-radius: 25px;
  font-family: Abdo-Master-SemiBold, sans-serif;
  font-size: 20px;
  padding: 30px;
  img {
    width: 70px;
  }
`;

export const Button = styled.button`
  border: 1px solid #ffd100;
  border-radius: 10px;
  font-family: Co-Headline-W23-Arabic-Light-4, sans-serif;
  font-size: 15px;
  padding: 7px 20px;
  background-color: transparent;
  margin-top: 30px;
  line-height: 0;
`;

export const VacanciesContiner = styled.div`
  display: flex;
  justify-content: space-around;
  margin-right: 150px;
  margin-bottom: 50px;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 768px) {
    margin-right: 0px;
  }
`;
