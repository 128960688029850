import { useEffect, useState } from "react";
import api from "../../api/axiosInterceptor";

export function useGetPartners(refresh?: boolean) {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await api.get(`api/partners/`);
        const data = response?.data;
        setPartners(data);
      } catch (e) {
        console.log(e);
      }
    };
    fetch();
  }, [refresh]);

  return partners;
}
