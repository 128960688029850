import HeaderComp from "../components/Header/Header";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useGetJobDetails } from "../network/controllers/jobs";

const CareerDetails = () => {
  const param = useParams();
  const job: any = useGetJobDetails(param.id);

  const SubTitle = styled.div`
    font-size: 30px;
    color: #fdbe57;
  `;

  const Container = styled.div`
    width: 60%;
    margin: auto;
    margin-top: 32px;
  `;
  const List = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    color: #4e616a;
  `;

  const MyParagraph = styled.p`
    font-size: 22px;
    color: #4e616a;
  `;

  return (
    <div>
      <HeaderComp />
      <Container>
        <h1>{job["title"]}</h1>

        <SubTitle>Job description</SubTitle>

        <MyParagraph dangerouslySetInnerHTML={{ __html: job["description"] }} />

        <SubTitle>Job requirements</SubTitle>

        <MyParagraph
          dangerouslySetInnerHTML={{ __html: job["requirements"] }}
        />
      </Container>
    </div>
  );
};

export default CareerDetails;
