import axios from "axios";
import { useState, useEffect } from "react";
import { CONTACT_FORM_ENDPOINT } from "../endpoints";
import { message } from "antd";

export function usePost() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const postData = async (payload: any) => {
    setLoading(true);
    try {
      const response = await axios.post(CONTACT_FORM_ENDPOINT, { ...payload });
      setData(response.data);
      message.success(`Send Successfully`);
      setError(null);
    } catch (error: any) {
      message.error(`Error: ${error?.response?.statusText}`);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  return { data, error, loading, postData };
}

export function useGetMessages() {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axios.get(CONTACT_FORM_ENDPOINT, config);
      setMessages(response?.data);
    };
    fetch();
  }, []);

  return messages;
}
