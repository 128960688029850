export const SET_USER = "SET_USER";

export interface User {
  id: number;
  last_login: string;
  type: string;
  is_superuser: boolean;
  email: string;
  username: string;
  first_name: string;
  is_staff: boolean;
}

export interface Auth {
  user: User;
}

export interface SetUser {
  type: typeof SET_USER;
  payload: Auth;
}
