import { useState, useEffect } from "react";
import api from "../../api/axiosInterceptor";

export function useGetBlogs() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await api.get("api/blog/", config);
        setBlogs(response?.data);
      } catch (e) {
        console.log(e);
      }
    };
    fetch();
  }, []);

  return blogs;
}
