import { Card } from "antd";
import styled from "styled-components";

export const Line = styled.div`
  position: absolute;
  border-left: 5px solid #fff;
  height: 100px;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ImageCard = styled(Card)`
  width: "100%";
  text-align: "center";
  border: "none";
  padding-right: "0px";
  box-sizing: "border-box";

  &:hover {
    img {
      filter: brightness(70%);
    }
  }
`;

export const Header = styled.h1`
  font-family: "Abdo-Master", sans-serif;
  color: #ffd100;
  font-size: 4.5em;
  margin-bottom: 4rem;
  text-align: center;
  @media (max-width: 1300px) {
    font-size: 3em;
  }
  @media (max-width: 700px) {
    font-size: 2.5em;
  }
`;

export const Desc = styled.p`
  font-family: "Co-Headline-W23-Arabic-Light-4", sans-serif;
  color: #ffffff;
  font-size: 1.6em;
  margin-bottom: "8px";
  font-weight: bold;
  text-align: center;
  @media (max-width: 1300px) {
    font-size: 1.2em;
  }
  @media (max-width: 700px) {
    font-size: 1.01em;
  }
`;

export const CoverImage = styled.img`
  width: 100%;
  height: 36.25em;
  object-fit: cover;
  filter: brightness(50%);
  transition: filter 0.3s ease-in-out;
`;
