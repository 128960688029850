import { Modal, message } from "antd";
import api from "../../api/axiosInterceptor";

interface DeleteModalProps {
  setIsDeleteOpen: (isModalOpen: boolean) => void;
  isDeleteOpen: boolean;
  id: number | undefined;
  refresh: boolean;
  setRefresh: (refresh: boolean) => void;
  name: string;
}

const DeleteModal = (props: DeleteModalProps) => {
  const handleOk = async () => {
    await api
      .delete(`api/${props.name}/${props?.id}/`)
      .then((result: { data: any }) => {
        message.success(`deleted successfully`);
        props.setRefresh(!props.refresh);
        props.setIsDeleteOpen(false);
        return result && result.data;
      })
      .catch(() => {
        message.error(`deleted failed.`);
      });
  };

  const handleCancel = () => {
    props.setIsDeleteOpen(false);
  };

  return (
    <Modal
      title="Delete Row"
      open={props.isDeleteOpen}
      onCancel={handleCancel}
      onOk={handleOk}
      okText="Yes"
    >
      Are you sure you want to delete?
    </Modal>
  );
};

export default DeleteModal;
