import { i18n } from "../../translations";
import line from "../../images/line.png";
import f_icon from "../../images/apply_icon1.png";
import s_icon from "../../images/apply_icon2.png";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import Offers from "../Offers/Offers";
import { ContainerDiv, Desc, Desc2 } from "./ApplyAnywayStyles";

function ApplyAnyway() {
  const { info } = useSelector((state: RootState) => ({
    info: state.ASALInfo.info,
  }));

  const apply_anyway_department_name = info.APPLY_ANYWAY_DEPARTMENT_NAME
    ? info.APPLY_ANYWAY_DEPARTMENT_NAME
    : "";

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <ContainerDiv>
          <div style={{ marginBottom: "20px" }}>
            <img src={line} height={50} />
          </div>
          <img src={f_icon} />
          <Desc style={{ color: "#4D4D4D", padding: "10px 100px" }}>
            {i18n.t("apply_anyway_first_desc")}
          </Desc>
        </ContainerDiv>

        <ContainerDiv>
          <div style={{ marginBottom: "20px" }}>
            <img src={line} height={50} />
          </div>
          <img src={s_icon} style={{ marginLeft: "15px" }} />
          <Desc2 style={{ padding: "10px 100px" }}>
            {i18n.t("apply_anyway_second_desc")}
          </Desc2>
        </ContainerDiv>
      </div>
      <Offers department={apply_anyway_department_name}></Offers>
    </div>
  );
}

export default ApplyAnyway;
