import { useState, useEffect } from "react";
import api from "../../api/axiosInterceptor";
import { EmployeeExperience } from "../../types/types";

export function useGetEmployeeExperience() {
  const [employeeExperience, setEmployeeExperience] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await api.get("api/employeeExperience/", config);
        setEmployeeExperience(response?.data);
      } catch (e) {
        console.log(e);
      }
    };
    fetch();
  }, []);

  return employeeExperience;
}

export function useGetEmployeeExperienceByPage(page = 1) {
  const [employeeExperience, setEmployeeExperience] =
    useState<EmployeeExperience | null>(null);
  const [hasNext, setHasNext] = useState<boolean>(false);

  useEffect(() => {
    const fetch = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await api.get(
          `api/employeeExperienceByPage/?page=${page}`,
          config
        );
        if (response?.data?.count > 0) {
          if (response.data.next) setHasNext(true);
          setEmployeeExperience(response?.data.results[0]);
        } else {
          setEmployeeExperience(null);
          setHasNext(false);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetch();
  }, []);

  return [employeeExperience, hasNext, page + 1];
}
