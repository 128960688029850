import React from "react";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LoginForm from "../components/LoginForm/loginForm";
import { RootState } from "../redux/store";

const Login = () => {
  const Wrapper = styled.div`
    background: linear-gradient(
      180deg,
      rgba(253, 190, 87, 1) 0%,
      rgba(253, 190, 87, 0.38) 100%
    );
    height: 100vh;
  `;
  const navigate = useNavigate();

  const { userDetails } = useSelector((state: RootState) => ({
    userDetails: state.userObj.user,
  }));

  React.useEffect(() => {
    if (userDetails?.email) {
      navigate("/");
    }
  }, [userDetails]);

  return (
    (!userDetails?.email && (
      <Wrapper>
        <LoginForm />
      </Wrapper>
    )) || <div></div>
  );
};
export default Login;
