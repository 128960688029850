import { Tabs } from "antd";
import { styled } from "styled-components";
import GalleryManage from "../components/Gallery/GalleryManage";
import ManagePages from "../components/InternalPageComp/ManagePages";
import { i18n } from "../translations";
import ContactFormManage from "../components/ContactUsForm/ContactFormManage";
import PartnerManage from "../components/Partners/PartnersManage";
import ManageNavBar from "../components/NavBar/ManageNavBar";
import ManageServices from "../components/Services/ManageServices";
import ManageInfo from "../components/Asal_Info/ManageInfo";
import MattersToUsManage from "../components/MattersToUS/MattersToUsManage";
import LeadershipManage from "../components/Leadership/LeadershipManage";
import HowDifferentManage from "../components/HowDifferent/HowDifferentManage";
import IndustriesManage from "../components/Industries/IndustriesManage";
import WithinCommunityManage from "../components/WithinCommunity/WithinCommunityManage";
import SecurityAndComplianceManage from "../components/SecurityAndCompliance/SecurityAndComplianceManage";
import VacanciesManage from "../components/JoinUs/VacanciesManage";
import LetsChatCalendarManager from "../components/LetsChatCalendar/LetsChatCalendarManagement/LetsChatCalendarManagement";
import ReachUsManage from "../components/ReachUs/ReachUsManage";
import EngagementModelsManage from "../components/EngagementModels/EngagementModelsManage";
import BlogManage from "../components/Blog/BlogManage";
import NewsManage from "../components/News/NewsManage";
import EmployeeExperienceManage from "../components/EmployeeExperience/EmployeeExperienceManage";
import LifeAtAsalManage from "../components/LifeAtAsal/LifeAtAsalManage";
import TalentDetailsFAQsManage from "../components/TalentDetails/TalentDetailsFAQsManage";

const Admin = () => {
  const Wrapper = styled.div`
    margin: 50px;
    display: flex;
    flex-direction: column;
  `;

  const tabs = [
    {
      key: "1",
      label: "Internal Pages and Sections",
      children: <ManagePages />,
    },
    { key: "2", label: "Header", children: <ManageNavBar /> },
    { key: "4", label: "Gallery", children: <GalleryManage /> },
    { key: "5", label: "Partners", children: <PartnerManage /> },
    { key: "6", label: "Services", children: <ManageServices /> },
    { key: "7", label: "Contact us Messages", children: <ContactFormManage /> },
    { key: "8", label: "ASAL Info", children: <ManageInfo /> },
    { key: "9", label: "What matters to Us", children: <MattersToUsManage /> },
    { key: "10", label: "Our Leadership", children: <LeadershipManage /> },
    {
      key: "11",
      label: "How are We Different",
      children: <HowDifferentManage />,
    },
    {
      key: "12",
      label: "Industries",
      children: <IndustriesManage />,
    },
    {
      key: "13",
      label: "Community Engagement",
      children: <WithinCommunityManage />,
    },
    {
      key: "14",
      label: "Security and Compliance",
      children: <SecurityAndComplianceManage />,
    },
    {
      key: "15",
      label: "Vacancies",
      children: <VacanciesManage />,
    },
    {
      key: "16",
      label: "Let's Chat Calendar",
      children: <LetsChatCalendarManager />,
    },
    {
      key: "17",
      label: "Branches",
      children: <ReachUsManage />,
    },
    {
      key: "18",
      label: "Engagement Models",
      children: <EngagementModelsManage />,
    },
    {
      key: "19",
      label: "Blogs",
      children: <BlogManage />,
    },
    {
      key: "20",
      label: "Press Releases",
      children: <NewsManage />,
    },
    {
      key: "21",
      label: "Employee Experience",
      children: <EmployeeExperienceManage />,
    },
    {
      key: "22",
      label: "Life at ASAL",
      children: <LifeAtAsalManage />,
    },
    {
      key: "23",
      label: "Talent FAQs",
      children: <TalentDetailsFAQsManage />,
    },
  ];

  return (
    <Wrapper>
      <h4 style={{ textAlign: "center", marginBottom: "50px" }}>
        {i18n.t("content_anagement")}
      </h4>
      <Tabs defaultActiveKey="1" items={tabs} />
    </Wrapper>
  );
};

export default Admin;
