import { EditFilled } from "@ant-design/icons";
import { Space, Table } from "antd";
import { ASALInfo } from "../../types/types";
import { useState, useEffect } from "react";
import api from "../../api/axiosInterceptor";
import EditModal from "./EditModal";

const ManageInfo = () => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [id, setId] = useState<number>(0);
  const [recordId, seRecordtId] = useState<number>(0);
  const [data, setData] = useState<ASALInfo[]>([]);

  const [refresh, setRefresh] = useState(false);

  const columns = [
    {
      title: "id",
      width: "5%",
      render: (_: any, record: ASALInfo) => data.indexOf(record) + 1,
    },
    {
      title: "Key",
      dataIndex: "title",
      key: "title",
      render: (text: string) => text,
      align: "center" as const,
    },
    {
      title: "Value",
      dataIndex: "description",
      key: "description",
      render: (text: string) => text,
      align: "center" as const,
    },
    {
      title: "Actions",
      key: "action",
      render: (_: any, record: any) => (
        <Space size="middle">
          <a
            onClick={() => {
              setIsEditOpen(true);
              seRecordtId(data.indexOf(record) + 1);
              setId(record.id);
            }}
          >
            <EditFilled
              style={{ fontSize: "20px", color: "green" }}
              rev={undefined}
            />
          </a>
        </Space>
      ),
      align: "center" as const,
    },
  ];

  const getData = async () => {
    try {
      const res = await api.get("api/AsalDetails/");

      if (res) {
        setData(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getData();
  }, [refresh]);

  return (
    <div style={{ margin: "50px" }}>
      <Table bordered columns={columns} dataSource={data} pagination={false} />

      <EditModal
        isEditOpen={isEditOpen}
        setIsEditOpen={setIsEditOpen}
        id={id}
        data={data[recordId - 1]}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </div>
  );
};

export default ManageInfo;
