import line from "../../images/line.png";
import { ServicesSection, Title } from "../../theme/general";
import { ReactComponent as ServicesIcon } from "../../images/services.svg";
import { ReactComponent as HowWerAreDiff } from "../../images/howWeAreDiff.svg";
import { RightOutlined } from "@ant-design/icons";
import {
  Line,
  StyledButton,
  Desc,
  ServicesCard,
  HowWeAreDiffCard,
} from "./ServiceStyles";
import { useState } from "react";
import Popup from "./Popup";
import { i18n } from "../../translations";
import { useNavigate } from "react-router-dom";
import Asset1 from "../../images/asset-main.gif";

const Services = () => {
  const [popup, setPopup] = useState(false);
  const isMobile = window.innerWidth <= 768;
  const navigate = useNavigate();
  return (
    <>
      <ServicesSection>
        <div>
          <img src={line} />
          <Title>{i18n.t("services_title")}</Title>
          <ServicesCard
            title={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <ServicesIcon style={{ marginBottom: "22px" }} />
                <div>{i18n.t("software_dev")}</div>
              </div>
            }
            style={{
              margin: isMobile ? "30px 10px" : "60px 160px",
              border: "1px solid",
              borderRadius: "14px",
            }}
          >
            <Line />
            <Desc>{i18n.t("services_desc")}</Desc>
            <StyledButton onClick={() => navigate("/internal/Services")}>
              {i18n.t("services_button")}
              <RightOutlined rev={undefined} style={{ color: "#ffd100" }} />
            </StyledButton>
          </ServicesCard>
          <HowWeAreDiffCard onClick={() => setPopup(true)}>
            <HowWerAreDiff style={{ maxWidth: 450 }} />
          </HowWeAreDiffCard>
        </div>
        {!isMobile && (
          <img
            src={Asset1}
            style={{
              position: "absolute",
              right: "-72px",
              transform: "translateY(-50%)",
              top: "0",
            }}
          />
        )}
      </ServicesSection>
      <Popup popupOpen={popup} setPopup={setPopup} />
    </>
  );
};

export default Services;
