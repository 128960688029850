import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Login from "../pages/Login";
import InternalPages from "../pages/InternalPage";
import { NonAuthRoutes } from "./constants";
import Admin from "../pages/Admin";
import Contact from "../pages/Contact";
import CareerDetails from "../pages/CareerDetails";
import ProtectedRoute from "./protectedRoutes";

const Routers = () => {
  return (
    <Routes>
      <Route path={NonAuthRoutes.root} element={<Home />} />
      <Route path={NonAuthRoutes.login} element={<Login />} />
      <Route
        path={NonAuthRoutes.admin}
        element={
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/internal/Vacancies/:id" element={<CareerDetails />} />
      <Route path="/internal/:title/:subtitle?" element={<InternalPages />} />
      <Route path={NonAuthRoutes.contact} element={<Contact />} />
    </Routes>
  );
};

export default Routers;
