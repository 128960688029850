import React from "react";
import { Title, TitleContainer } from "./TalentDetailsStyles";
import { i18n } from "../../translations";

const TalentDetailsHeader: React.FC = () => {
  return (
    <>
      <TitleContainer>
        <Title>{i18n.t("talent_details_title")}</Title>
        <Title>{i18n.t("talent_details_programs")}</Title>
      </TitleContainer>
    </>
  );
};

export default TalentDetailsHeader;
