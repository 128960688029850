import { styled } from "styled-components";
import { Card } from "antd";

export const StyledCard = styled(Card)`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  .ant-card-head {
    min-width: 100%;
    text-align: center;
  }

  .ant-card-head-title {
    width: 100%;
    h1 {
      padding: 4% 2% 2%;
      font-size: 14pt;
      font-weight: bold;
      white-space: normal;
    }

    img {
      display: block;
      margin: 0 auto;
    }
  }

  .ant-card-body {
    padding: 0 10% 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .ant-card-head {
    border-bottom: none;
  }
`;

export const Line = styled.hr`
  margin: 0 auto 2rem !important;
  border-top: 2px solid #ffd100;
  opacity: 1;
  width: 100%;
`;

export const Desc = styled.p`
  font-family: Cairo-Regular, sans-serif;
  font-size: 14pt;
  color: #808080;
  text-align: center;
`;

export const TitleDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 160px;
  max-width: 80%;
  min-height: 100px;
  margin: 0 auto;
  text-align: center;
  white-space: normal;
  fontfamily: Abdo-Master-SemiBold, sans-serif;
  @media (max-width: 768px) {
    margin: 50px 0 20px 0;
  }
`;

export const TitleHeader = styled.h1`
  color: #ffd100;
  font-size: 3.5em;

  @media (max-width: 1090px) {
    font-size: 2.5em;
  }
`;
