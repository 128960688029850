import { StyledCard, Line, Desc } from "./EngagementModelsStyles";
import { useGetEngagementModels } from "../../network/controllers/engagementModels";
import line from "../../images/line.png";

interface EngagmentModelsInfo {
  title: string;
  description: string;
  ImageUrl: string;
  displayLine: boolean;
}

function EngagmentModelsCard({
  title,
  description,
  ImageUrl,
  displayLine,
}: EngagmentModelsInfo) {
  return (
    <>
      <StyledCard
        title={
          <div>
            <img src={ImageUrl} style={{ marginTop: "42px" }} />
            <br />
            <h1>{title}</h1>
            <br />
          </div>
        }
        style={{
          width: "100%",
          margin: "auto",
          border: "1px solid",
          borderRadius: "25px",
        }}
      >
        <Line />
        <Desc dangerouslySetInnerHTML={{ __html: description }}></Desc>
      </StyledCard>
      {displayLine && (
        <img
          src={line}
          style={{ width: "5px", height: "70px", margin: "auto auto" }}
        />
      )}
    </>
  );
}

function EngagementModels() {
  const engagementModelsData: any[] = useGetEngagementModels();
  const engagementModelsLen = engagementModelsData.length;
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "80px",
          marginBottom: "80px",
        }}
      >
        {engagementModelsData &&
          engagementModelsData.map((item, index) => {
            return (
              <>
                <EngagmentModelsCard
                  key={index}
                  title={item.title}
                  description={item.description}
                  ImageUrl={item.image}
                  displayLine={index !== engagementModelsLen - 1}
                />
              </>
            );
          })}
      </div>
    </>
  );
}

export default EngagementModels;
