import styled from "styled-components";
import { useGetLeaderDetails } from "../../network/controllers/leaders";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Modal } from "antd";

export const Description = styled.div`
  font-size: 26px;
  font-family: Cairo-Regular, sans-serif;
  color: #4d4d4d;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const Image = styled.div<{ url: string }>`
  background-image: url(${(props) => props.url});
  height: 100vh;
  background-position-y: center;
  background-size: cover;
  margin-bottom: "150px";

  @media (max-width: 768px) {
    height: 20vh;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 0px;
  }
`;

const DetailsDiv = styled.div`
  padding: 60px;
  @media (max-width: 768px) {
    padding: 25px;
  }
`;

const LeadershipDetails = ({
  leaderId,
  onClose,
}: {
  leaderId: number;
  onClose: (popup: number) => void;
}) => {
  const leader: any = useGetLeaderDetails(String(leaderId));

  const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
  `;

  return (
    <div>
      <Container>
        <Image url={leader.desc_image} />
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            padding: 8,
            borderRadius: 4,
            fontSize: 20,
          }}
        >
          <ArrowLeftOutlined
            rev={undefined}
            style={{ color: "#FFFFFF" }}
            onClick={() => onClose(-1)}
          />
        </div>
        <DetailsDiv>
          {leader.description && (
            <Description
              dangerouslySetInnerHTML={{ __html: leader.description }}
            ></Description>
          )}
        </DetailsDiv>
      </Container>
    </div>
  );
};

const LeadershipDetailsPopup = (props: {
  popupOpen: number;
  setPopup: (popup: number) => void;
}) => {
  return (
    <StyledModal
      open={Boolean(props.popupOpen >= 0)}
      onCancel={() => props.setPopup(-1)}
      footer={null}
      centered
      closeIcon={null}
      destroyOnClose={true}
      maskClosable={true}
      width={"80%"}
      bodyStyle={{ padding: "0px" }}
    >
      <LeadershipDetails leaderId={props.popupOpen} onClose={props.setPopup} />
    </StyledModal>
  );
};

export default LeadershipDetailsPopup;
