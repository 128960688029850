import styled from "styled-components";
import { Space, Layout, Image, Carousel } from "antd";

export const Arrow = styled(Space)`
  font-size: 30px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 22pt;
`;

export const Divider = styled.div`
  border-style: solid;
  border-right-style: none;
  height: 55px;
  width: 0.5px;
`;

export const Container = styled(Layout)`
  min-height: 310px;
  padding: 0px 50px;
`;

export const SliderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ant-carousel {
    width: 96%;
    min-height: 180px;

    .slick-slider,
    .slick-list,
    .slick-track,
    .slick-slide {
      min-height: inherit;
    }

    .slick-slider,
    .slick-list,
    .slick-track {
      min-height: inherit;
    }

    .slick-slide > div {
      min-height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 768px) {
        margin: 0 20px;
      }
    }

    .slick-slide > div > div > .ant-image-img {
      max-height: 100px;
    }
  }
`;
export const TransImage = styled(Image)`
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.8);
  }
`;
