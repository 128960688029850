import api from "./axiosInterceptor";

export const LoginApi = async (username: string, password: string) => {
  try {
    const result = await api.post("api/auth/", {
      username: username,
      password: password,
    });
    return result && result.data;
  } catch {
    console.log("Something went wrong");
  }
};
