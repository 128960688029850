import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header/Header";
import InternalPageComp from "../components/InternalPageComp/InternalPageComp";
import Asset3 from "../images/Asset3.png";
import Asset4 from "../images/Asset4.png";
import Asset1 from "../images/Asset1.png";
import Asset5 from "../images/Asset5.png";
import Asset6 from "../images/Asset6.png";
import Asset7 from "../images/Asset7.png";
import Footer from "../components/Footer/Footer";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { styled } from "styled-components";
import { Album, InternalPages } from "../types/types";
import api from "../api/axiosInterceptor";
import { SPECIAL_PAGES, SPECIAL_PAGES_TITLES } from "../constants/strings";
import Contact from "./Contact";
import GalleryComp from "../components/Gallery/GallerySlider";
import ServicesPage from "../components/Services/ServicesPage";
import Leadership from "../components/Leadership/Leadership";
import JoinUs from "../components/JoinUs/JoinUs";
import HowDifferent from "../components/HowDifferent/HowDifferent";
import TrustUs from "../components/TrustUs/TrustUs";
import MattersToUs from "../components/MattersToUS/MattersToUS";
import Industries from "../components/Industries/Industries";
import WithinCommunity from "../components/WithinCommunity/WithinCommunity";
import SecurityAndCompliance from "../components/SecurityAndCompliance/SecurityAndCompliance";
import OffersContainer from "../components/OffersContainer/OffersContainer";
import LetsChatCalendar from "../components/LetsChatCalendar/LestChatCalendar";
import ReachUs from "../components/ReachUs/ReachUs";
import EngagementModels from "../components/EngagementModels/EngagementModels";
import Blog from "../components/Blog/Blog";
import News from "../components/News/News";
import EmployeeExperience from "../components/EmployeeExperience/EmployeeExperience";
import TrainingPrograms from "../components/TrainingPrograms/TrainingPrograms";
import ApplyAnyway from "../components/ApplyAnyway/ApplyAnyway";

const InternalPage = () => {
  const divRef = useRef<HTMLInputElement>(null);
  const { title = "", subtitle = "" } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<InternalPages>();
  const [albums, setAlbums] = useState<Album[]>();
  const isMobile = window.innerWidth <= 768;

  const scrollToBottom = () => {
    divRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
    const fetchData = async () => {
      try {
        const albums = await api.get("api/albums/");
        albums && setAlbums(albums.data);
        const res = await api.get(
          `api/internalPages/section/${encodeURIComponent(title as string)}/`
        );
        res && setData(res.data);
      } catch (e) {
        navigate("/");
        setData(undefined);
      }
    };
    fetchData();
  }, [title]);

  const BackTo = styled.div`
    cursor: pointer;
    padding-left: ${!isMobile &&
    [
      SPECIAL_PAGES.HOW_DIFFERENT,
      SPECIAL_PAGES.LETS_CHAT,
      SPECIAL_PAGES.EMPLOYEE_EXP,
    ].includes(title || "")
      ? "235px"
      : "0px"};
    font-size: 15pt;
    color: #838383;
    font-family: "DIN-NEXT-ARABIC-LIGHT", sans-serif;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  `;

  const Title = styled.div`
    padding-left: ${!isMobile &&
    [
      SPECIAL_PAGES.HOW_DIFFERENT,
      SPECIAL_PAGES.LETS_CHAT,
      SPECIAL_PAGES.EMPLOYEE_EXP,
    ].includes(title || "")
      ? "235px"
      : "0px"};
    padding-bottom: ${[
      SPECIAL_PAGES.HOW_DIFFERENT,
      SPECIAL_PAGES.LETS_CHAT,
      SPECIAL_PAGES.EMPLOYEE_EXP,
      SPECIAL_PAGES.KNOW_US,
      SPECIAL_PAGES.ECOSYSTEM,
      SPECIAL_PAGES.WHAT_MATTER_TO_US,
    ].includes(title || "")
      ? "20px"
      : "0px"};
    font-size: 31pt;
    font-family: "Abdo-Master-SemiBold", sans-serif;

    @media (max-width: 768px) {
      font-size: 25pt;
    }
  `;

  const Container = styled.div<{ $title?: string }>`
    display: flex;
    align-items: flex-end;
    position: relative;
    padding: ${(props) =>
      props.$title === SPECIAL_PAGES.GALLERY
        ? "0 0 20px 0"
        : props.$title === SPECIAL_PAGES.HOW_DIFFERENT ||
          props.$title === SPECIAL_PAGES.EMPLOYEE_EXP
        ? "0 0 0 0"
        : "0 20px 0 0"};

    flex-wrap: wrap;
    flex-direction: ${(props) =>
      props.$title === SPECIAL_PAGES.GALLERY ||
      props.$title === SPECIAL_PAGES.EMPLOYEE_EXP ||
      props.$title === SPECIAL_PAGES.THEY_TRUST_US ||
      props.$title === SPECIAL_PAGES.INDUSTRIES
        ? "column"
        : "row"};
    background-color: ${(props) =>
      props.$title === SPECIAL_PAGES.OUR_LEADERSHIP
        ? "#EEEEEE"
        : props.$title === SPECIAL_PAGES.GALLERY
        ? "transparent"
        : "#FFFFFF"};
  `;

  const getInternalPages = () => {
    switch (title) {
      case SPECIAL_PAGES.REACH_US_FORM:
        return <Contact />;
      case SPECIAL_PAGES.GALLERY: {
        return albums?.map((album) => {
          return <GalleryComp album={album} />;
        });
      }
      case SPECIAL_PAGES.SERVICES: {
        return <ServicesPage />;
      }
      case SPECIAL_PAGES.OUR_LEADERSHIP:
        return <Leadership />;
      case SPECIAL_PAGES.EMPLOYEE_EXP: {
        return <EmployeeExperience />;
      }
      case SPECIAL_PAGES.VACANCIES:
      case SPECIAL_PAGES.JOIN_US: {
        return <JoinUs />;
      }
      case SPECIAL_PAGES.HOW_DIFFERENT: {
        return <HowDifferent />;
      }
      case SPECIAL_PAGES.THEY_TRUST_US: {
        return <TrustUs />;
      }
      case SPECIAL_PAGES.WHAT_MATTER_TO_US: {
        return <MattersToUs />;
      }
      case SPECIAL_PAGES.INDUSTRIES: {
        return <Industries />;
      }
      case SPECIAL_PAGES.WITHIN_COMMUNITY: {
        return <WithinCommunity />;
      }
      case SPECIAL_PAGES.SECURITY_AND_COMPLIANCE: {
        return <SecurityAndCompliance />;
      }
      case SPECIAL_PAGES.OFFERS: {
        return <OffersContainer />;
      }
      case SPECIAL_PAGES.LETS_CHAT: {
        return <LetsChatCalendar />;
      }
      case SPECIAL_PAGES.REACH_US: {
        return <ReachUs />;
      }
      case SPECIAL_PAGES.ENGAGEMENT_MODELS: {
        return <EngagementModels />;
      }
      case SPECIAL_PAGES.BLOG: {
        return <Blog />;
      }
      case SPECIAL_PAGES.NEWS: {
        return <News />;
      }
      case SPECIAL_PAGES.TRAINING_PROGRAMS: {
        return <TrainingPrograms />;
      }
      case SPECIAL_PAGES.APPLAY_ANYWAY: {
        return <ApplyAnyway />;
      }
      case SPECIAL_PAGES.LIFE_AT_ASAL:
        return null;
      default:
        return <InternalPageComp title={title} data={data} />;
    }
  };

  const getImage = () => {
    switch (title?.trim()) {
      case SPECIAL_PAGES.GALLERY:
      case SPECIAL_PAGES.THEY_TRUST_US:
      case SPECIAL_PAGES.INDUSTRIES:
      case SPECIAL_PAGES.CLIENT_WE_SERVED_ON_THE_MAP:
        return Asset4;
      case SPECIAL_PAGES.JOIN_US:
      case SPECIAL_PAGES.VACANCIES:
      case SPECIAL_PAGES.HOW_DIFFERENT:
      case SPECIAL_PAGES.LETS_CHAT:
      case SPECIAL_PAGES.TRAINING_PROGRAMS:
      case SPECIAL_PAGES.APPLAY_ANYWAY:
      case SPECIAL_PAGES.LIFE_AT_ASAL:
        return undefined;
      case SPECIAL_PAGES.EMPLOYEE_EXP:
        return Asset6;
      case SPECIAL_PAGES.SERVICES:
        return Asset1;
      case SPECIAL_PAGES.OFFERS:
        return Asset7;
      default:
        return Asset3;
    }
  };

  const ZeroPadding = [
    SPECIAL_PAGES.HOW_DIFFERENT,
    SPECIAL_PAGES.LETS_CHAT,
    SPECIAL_PAGES.EMPLOYEE_EXP,
  ];
  const getRightPadding = () => {
    if (!ZeroPadding.includes(title?.trim() || "") && isMobile) {
      return "15px";
    }
    switch (title) {
      case SPECIAL_PAGES.GALLERY:
        return "150px";
      case SPECIAL_PAGES.HOW_DIFFERENT:
      case SPECIAL_PAGES.LETS_CHAT:
      case SPECIAL_PAGES.EMPLOYEE_EXP:
        return "0px";
      default:
        return "70px";
    }
  };

  const getLeftPadding = () => {
    if (!ZeroPadding.includes(title?.trim() || "") && isMobile) {
      return "15px";
    }
    switch (title) {
      case SPECIAL_PAGES.HOW_DIFFERENT:
      case SPECIAL_PAGES.LETS_CHAT:
      case SPECIAL_PAGES.EMPLOYEE_EXP:
        return "0px";
      case SPECIAL_PAGES.OUR_LEADERSHIP:
        return "100px";
      default:
        return "235px";
    }
  };

  const getTitle = () => {
    switch (title) {
      case SPECIAL_PAGES.OFFERS:
        if (
          subtitle?.indexOf(SPECIAL_PAGES_TITLES.OFFERS_INTERNSHIP_SUBTITLE) >
          -1
        ) {
          return subtitle.trim();
        }
        return SPECIAL_PAGES_TITLES.OFFERS_TITLE;
      default:
        return title;
    }
  };

  const needInternalContainer = () => {
    switch (title) {
      case SPECIAL_PAGES.LIFE_AT_ASAL:
        return false;
      default:
        return true;
    }
  };

  const getBackToURL = () => {
    switch (title) {
      case SPECIAL_PAGES.OFFERS:
        return "/internal/Vacancies";
      default:
        return "/";
    }
  };
  const getBackText = () => {
    switch (title) {
      case SPECIAL_PAGES.OFFERS:
        return "go back";
      default:
        return "back to home page";
    }
  };

  const sideImage = getImage();
  const backToUrl: any = getBackToURL();
  const backText: any = getBackText();
  const getMarginBottomImage = (): string => {
    if (
      sideImage === Asset4 ||
      sideImage === Asset5 ||
      sideImage === Asset6 ||
      sideImage === Asset7
    ) {
      return "70px";
    }
    return "0px";
  };

  const getMarginRightImage = (): string => {
    if (sideImage === Asset7) {
      return "5px";
    }
    return "0px";
  };

  const getExtraStyles = (): any => {
    if (title === SPECIAL_PAGES.OFFERS) {
      return { maxHeight: "-webkit-fill-available", top: "30px" };
    }
    return {};
  };

  return (
    <>
      <Header title={title} />
      {needInternalContainer() && (
        <Container ref={divRef} $title={title}>
          <div
            style={{
              padding: "50px 70px 50px 235px",
              minHeight: "700px",
              paddingBottom: title === SPECIAL_PAGES.SERVICES ? "0" : "50px",
              flex: 1,
              width: "100%",
              paddingRight: getRightPadding(),
              paddingLeft: getLeftPadding(),
              marginBottom: [
                SPECIAL_PAGES.GALLERY,
                SPECIAL_PAGES.THEY_TRUST_US,
                SPECIAL_PAGES.INDUSTRIES,
                SPECIAL_PAGES.CLIENT_WE_SERVED_ON_THE_MAP,
                SPECIAL_PAGES.ECOSYSTEM,
                SPECIAL_PAGES.KNOW_US,
              ].includes(title?.trim())
                ? 160
                : title === SPECIAL_PAGES.SERVICES
                ? 0
                : 60,
            }}
          >
            <BackTo onClick={() => navigate(backToUrl)}>
              <ArrowLeftOutlined rev={undefined} />
              {backText}
            </BackTo>
            <Title>{getTitle()}</Title>
            {getInternalPages()}
          </div>
          {!isMobile && sideImage && (
            <div
              style={{
                marginLeft: "100px",
                marginTop: "72px",
              }}
            >
              <img
                src={getImage()}
                style={{
                  position: "absolute",
                  bottom: getMarginBottomImage(),
                  right: getMarginRightImage(),
                  ...getExtraStyles(),
                }}
              />
            </div>
          )}
        </Container>
      )}

      <Footer />
    </>
  );
};

export default InternalPage;
