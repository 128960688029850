import { Tabs } from "antd";
import AvailabilitySettings from "./Availability/AvailabilitySettings";
import Reservations from "./Reservations/Reservations";

const LetsChatCalendarManager = () => {
  const tabItems = [
    {
      label: "Reservations",
      key: "reservations",
      children: <Reservations />,
    },
    {
      label: "Availability",
      key: "availability",
      children: <AvailabilitySettings />,
    },
  ];

  return <Tabs defaultActiveKey="reservations" items={tabItems} />;
};

export default LetsChatCalendarManager;
