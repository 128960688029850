import { Auth, SET_USER, SetUser } from "../actions/userObject/types";

const initialState: Auth = {
  user: {
    id: -1,
    last_login: "",
    type: "",
    is_superuser: false,
    email: "",
    username: "",
    first_name: "",
    is_staff: false,
  },
};

export const userReducer = (state = initialState, action: SetUser) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload.user };
    default:
      return state;
  }
};
