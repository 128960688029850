import React, { useEffect, useState } from "react";
import { Form, Button, Divider, message } from "antd";
import dayjs, { Dayjs } from "dayjs"; // Import dayjs
import { letsChatAPI } from "../../LetsChatCalendarAPI";
import { formatAvailabilityData } from "../../utils/availability-format";
import WeeklyHours from "./WeeklyHours/WeeklyHours";
import DateSpecificSlots from "./DateSpecific/DateSpecific";
import styles from "./AvailabilitySettings.module.css";

interface TimeSlot {
  startTime: Dayjs;
  endTime: Dayjs;
  key: number;
}

interface DaySlots {
  [key: string]: TimeSlot[];
}

interface DateSpecificSlot {
  date: Dayjs;
  startTime: Dayjs;
  endTime: Dayjs;
  key: number;
}

interface Availability {
  day_of_week: number;
  id: number;
  start_time: string;
  end_time: string;
  type: string;
  specific_date: string;
}

const format = "HH:mm";
const defaultStartTime = dayjs("07:00", format);
const defaultEndTime = dayjs("07:30", format);

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const AvailabilitySettings: React.FC = () => {
  const [form] = Form.useForm();
  const [daySlots, setDaySlots] = useState<DaySlots>(
    daysOfWeek.reduce((acc, day) => ({ ...acc, [day]: [] }), {})
  );
  const [intiallyLoaded, setIsInitiallyLoaded] = useState(false);

  const [dateSpecificSlots, setDateSpecificSlots] = useState<
    DateSpecificSlot[]
  >([]);

  const addTimeSlot = (day: string) => {
    let startTime, endTime;
    const slots = daySlots[day];
    const slotDurationMinutes = 30; // Set the default slot duration

    if (slots.length > 0) {
      // Set start time to the end time of the last slot
      startTime = slots[slots.length - 1].endTime;

      // Increment the end time by the slot duration
      endTime = dayjs(startTime).add(slotDurationMinutes, "minute");
    } else {
      // Use default times if no slots are present
      startTime = defaultStartTime;
      endTime = dayjs(defaultStartTime).add(slotDurationMinutes, "minute");
    }

    const newSlot: TimeSlot = {
      startTime: startTime,
      endTime: endTime,
      key: Date.now(),
    };

    // Check if the new slot overlaps with any existing slot
    if (isOverlapping(newSlot, slots)) {
      message.error("Cannot add overlapping slot.");
      return;
    }

    setDaySlots({ ...daySlots, [day]: [...slots, newSlot] });
  };

  const removeTimeSlot = (day: string, key: number) => {
    const updatedSlots = daySlots[day].filter((slot) => slot.key !== key);
    setDaySlots({ ...daySlots, [day]: updatedSlots });

    // Reset the Form fields for the weekly slots
    const fields = updatedSlots.map((slot, index) => [
      { name: [day, index, "startTime"], value: slot.startTime },
      { name: [day, index, "endTime"], value: slot.endTime },
    ]);
    fields.forEach((field) => form.setFields(field));

    if (updatedSlots.length === 0) {
      form.setFieldsValue({ [day]: { available: false } });
    }
  };

  const toggleDayAvailability = (day: string, checked: boolean) => {
    if (!checked) {
      setDaySlots({ ...daySlots, [day]: [] });
    } else {
      addTimeSlot(day);
    }
  };

  const isOverlapping = (newSlot: TimeSlot, existingSlots: TimeSlot[]) => {
    return existingSlots.some(
      (slot) =>
        newSlot.startTime.isBefore(slot.endTime) &&
        newSlot.endTime.isAfter(slot.startTime)
    );
  };

  const onFormFinish = (values: any) => {
    const formattedData = formatAvailabilityData(values);
    letsChatAPI.updateOrCreateAvailability(formattedData);
  };

  const validateTimeSlot = async (
    value: Dayjs,
    day: string,
    index: number,
    slotType: "startTime" | "endTime"
  ) => {
    const updatedSlot = { ...daySlots[day][index], [slotType]: value };
    const overlapping = daySlots[day].some((slot, i) => {
      if (i !== index) {
        return (
          updatedSlot.startTime.isBefore(slot.endTime) &&
          updatedSlot.endTime.isAfter(slot.startTime)
        );
      }
      return false;
    });

    if (overlapping) {
      throw new Error("Time slots cannot overlap");
    }
  };
  const onTimeChange = (
    day: string,
    index: number,
    time: Dayjs,
    type: "startTime" | "endTime"
  ) => {
    const updatedSlots = [...daySlots[day]];
    updatedSlots[index] = { ...updatedSlots[index], [type]: time };
    setDaySlots({ ...daySlots, [day]: updatedSlots });
    // Trigger form validation
    form.validateFields([
      [day, index, type === "startTime" ? "endTime" : "startTime"],
    ]);
  };

  const addDateSpecificSlot = () => {
    const newSlot: DateSpecificSlot = {
      date: dayjs(), // Default to current date
      startTime: defaultStartTime,
      endTime: defaultEndTime,
      key: Date.now(),
    };
    setDateSpecificSlots([...dateSpecificSlots, newSlot]);
  };

  const removeDateSpecificSlot = (key: number): void => {
    const updatedSlots = dateSpecificSlots.filter((slot) => slot.key !== key);
    setDateSpecificSlots([...updatedSlots]);

    // Create a new form value object for the date-specific slots
    const newFormValues: Record<string, any> = {
      dateSlots: updatedSlots.map((slot, index) => ({
        date: slot.date,
        startTime: slot.startTime,
        endTime: slot.endTime,
      })),
    };

    // Update the form with the new values
    form.setFieldsValue(newFormValues);
  };

  const updateDateSpecificSlot = (
    key: number,
    field: "date" | "startTime" | "endTime",
    value: Dayjs
  ) => {
    const updatedSlots = dateSpecificSlots.map((slot) => {
      if (slot.key === key) {
        const updatedSlot = { ...slot, [field]: value };
        if (field !== "date" && doesOverlap(updatedSlot, dateSpecificSlots)) {
          alert("Time slots cannot overlap on the same date");
          return slot; // Return the original slot if overlapping
        }
        return updatedSlot;
      }
      return slot;
    });

    setDateSpecificSlots(updatedSlots);
  };

  const doesOverlap = (
    newSlot: DateSpecificSlot,
    slots: DateSpecificSlot[]
  ) => {
    return slots.some(
      (slot) =>
        slot.date.isSame(newSlot.date) &&
        newSlot.startTime.isBefore(slot.endTime) &&
        newSlot.endTime.isAfter(slot.startTime)
    );
  };

  const mapAvailabilities = (availabilities: Availability[]) => {
    const newDaySlots: DaySlots = {
      ...daysOfWeek.reduce((acc, day) => ({ ...acc, [day]: [] }), {}),
    };
    const newDateSpecificSlots: any = [];

    availabilities.forEach((availability) => {
      const { id, type, start_time, end_time, day_of_week, specific_date } =
        availability;

      const startTime = dayjs(start_time, format);
      const endTime = dayjs(end_time, format);

      if (type === "weekly") {
        const day = daysOfWeek[day_of_week]; // Adjust for 0-based indexing
        const slot = { startTime, endTime, key: id };

        if (newDaySlots[day]) {
          newDaySlots[day].push(slot);
        } else {
          newDaySlots[day] = [slot];
        }
      } else if (type === "date_specific" && specific_date) {
        const date = dayjs(specific_date);
        const slot = { date, startTime, endTime, key: id };
        newDateSpecificSlots.push(slot);
      }
    });
    setDaySlots(newDaySlots);
    setDateSpecificSlots(newDateSpecificSlots);
  };

  useEffect(() => {
    letsChatAPI.getAvailability().then((res) => {
      mapAvailabilities(res);
      setIsInitiallyLoaded(true);
    });
  }, []);

  return (
    <Form form={form} layout="vertical" onFinish={onFormFinish}>
      <div className={styles.availabilitySettingsContainer}>
        <div className={styles.column}>
          <Divider>Weekly Hours</Divider>
          {intiallyLoaded && (
            <WeeklyHours
              addTimeSlot={addTimeSlot}
              daySlots={daySlots}
              daysOfWeek={daysOfWeek}
              onTimeChange={onTimeChange}
              removeTimeSlot={removeTimeSlot}
              toggleDayAvailability={toggleDayAvailability}
              validateTimeSlot={validateTimeSlot}
              format="HH:mm"
            />
          )}
        </div>
        <Divider type="vertical" className={styles.verticalDivider} />
        <div className={styles.column}>
          <Divider>Date Specific Slots</Divider>
          <DateSpecificSlots
            addDateSpecificSlot={addDateSpecificSlot}
            dateSpecificSlots={dateSpecificSlots}
            format="HH:mm"
            removeDateSpecificSlot={removeDateSpecificSlot}
            updateDateSpecificSlot={updateDateSpecificSlot}
            form={form}
          />
        </div>
      </div>

      <Divider />
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save Availability
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AvailabilitySettings;
