import "./Careers.css";
import { useNavigate } from "react-router-dom";
import {
  InnerContianer,
  Container,
  Header,
  Divider,
  Desc,
} from "./CareersStyles.js";
import { i18n } from "../../translations";
import { RightOutlined } from "@ant-design/icons";
import { ReactComponent as JoinUsIcon } from "../../images/join-us.svg";

import { Title } from "../../theme/general";
import { StyledButton } from "../Services/ServiceStyles";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";

const Careers = () => {
  const navigate = useNavigate();
  const { info } = useSelector((state: RootState) => ({
    info: state.ASALInfo.info,
  }));
  return (
    <Container>
      <Header>
        <Divider />
        <Title>{i18n.t("ready_to_join")}</Title>
        <InnerContianer>
          <JoinUsIcon />
          <Desc>{info.READY_TO_JOIN_US_TEXT}</Desc>
          <StyledButton onClick={() => navigate("/internal/Vacancies")}>
            {i18n.t("ready_to_join_button_text")}
            <RightOutlined rev={undefined} style={{ color: "#ffd100" }} />
          </StyledButton>
        </InnerContianer>
      </Header>
    </Container>
  );
};

export default Careers;
