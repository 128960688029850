import { useState, useEffect } from "react";
import api from "../../api/axiosInterceptor";
import { EmployeeExperience, LifeAtAsal } from "../../types/types";

export function useGetLifeAtAsal() {
  const [lifeAtAsal, setLifeAtAsal] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await api.get("api/lifeAtAsal/", config);
        setLifeAtAsal(response?.data);
      } catch (e) {
        console.log(e);
      }
    };
    fetch();
  }, []);

  return lifeAtAsal;
}

export function useGetActiveLifeAtAsal() {
  const [lifeAtAsal, setLifeAtAsal] = useState<LifeAtAsal | null>(null);

  useEffect(() => {
    const fetch = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await api.get(
          `api/lifeAtAsal/?is_active=true`,
          config
        );
        if (response?.data?.length > 0) {
          setLifeAtAsal(response?.data[0]);
        } else {
          setLifeAtAsal(null);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetch();
  }, []);

  return lifeAtAsal;
}
