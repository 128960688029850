import line from "../../images/line.png";
import { ReactComponent as JoinUsIcon } from "../../images/join-us.svg";
import { Desc, SDTitle } from "../Services/ServiceStyles";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { Box, Option, Button, VacanciesContiner } from "./JoinUsStyle";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useGetVacancies } from "../../network/controllers/vacancy";

const JoinUs = () => {
  const { info } = useSelector((state: RootState) => ({
    info: state.ASALInfo.info,
  }));
  const navigate = useNavigate();
  const vacancies: any[] = useGetVacancies();
  return (
    <div style={{ textAlign: "center" }}>
      <Box>
        <img src={line} height={50} />
        <SDTitle>Ready to Join us?</SDTitle>
        <JoinUsIcon />
        <Desc style={{ color: "#4D4D4D", padding: "10px 100px" }}>
          {info.READY_TO_JOIN_US_TEXT}
        </Desc>
      </Box>
      <VacanciesContiner>
        {vacancies &&
          vacancies.map((item, index) => {
            return (
              <Option key={index}>
                <img src={item.image} style={{ marginBottom: "20px" }} />
                {item.title}
                <Button
                  onClick={() =>
                    navigate(`/internal/Offers/${item.department}`)
                  }
                >
                  Apply Now
                  <DownOutlined rev={undefined} style={{ color: "#ffd100" }} />
                </Button>
              </Option>
            );
          })}
      </VacanciesContiner>
    </div>
  );
};

export default JoinUs;
