import { Col, Row, Input, Button, Form, message } from "antd";
import Header from "../components/Header/Header";
import styled from "styled-components";
import { i18n } from "../translations";
import { usePost } from "../network/controllers/contactus";
import { useEffect, useRef } from "react";

const Contact = () => {
  const [form] = Form.useForm();

  const { TextArea } = Input;
  const { postData } = usePost();
  const divRef = useRef<HTMLInputElement>(null);

  const StyledLabel = styled("div")`
    font-size: 18px;
    font-family: abdomaster-medium, sans-serif;
  `;

  const CustomInput = styled(Input)`
    width: 100%;
    font-family: Cairo-SemiBold, sans-serif;
    border-color: transparent;

    &.ant-input:hover,
    &.ant-input:active,
    &.ant-input:focus,
    &.ant-input-status-error {
      border-color: transparent !important;
      box-shadow: none !important;
    }
  `;

  const FormItem = styled(Form.Item)`
    height: 90px;
    border: 1.5px solid black;
    display: flex;
    align-items: center;
    padding: 23px;
    border-radius: 12px;
    margin-bottom: 20px;

    .ant-row {
      width: 100%;
      height: 100%;
      align-items: center;
    }
    .ant-form-item-explain {
      padding-left: 10px;
    }

    @media (max-width: 768px) {
      padding: 10px;
    }
  `;

  const CustomButton = styled(Button)`
    min-height: 64px;
    font-size: 25px;
    border-radius: 10px;
    width: 200px;
  `;

  const CustomArea = styled(TextArea)`
    border-radius: 10px;
    font-family: Cairo-SemiBold, sans-serif;
    border-color: transparent;
    &.ant-input:hover,
    &.ant-input:active,
    &.ant-input:focus,
    &.ant-input-status-error {
      border-color: transparent !important;
      box-shadow: none !important;
    }
  `;

  const validateMessages = {
    required: "This field is required!",
    types: {
      email: "This is not a valid email!",
    },
  };

  const FormFields = [
    {
      field_label: "full_name",
      name: "name",
      field_placeholder: "Full Name",
      rule: [{ required: true }],
    },
    {
      field_label: "email_address",
      name: "email",
      field_placeholder: "example@domain.com",
      rule: [{ required: true }, { type: "email" }],
    },
    {
      field_label: "subject",
      rule: [{ required: true }],
      name: "subject",
      field_placeholder: "Subject",
    },
  ];

  const onFinish = async (values: any) => {
    try {
      await form.validateFields();
      await postData(values);
      message.success("sent successfully");
    } catch (e) {
      console.log(e);
      message.error("Failed to send the email. please try again later.");
    }
  };

  const scrollToBottom = () => {
    divRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  return (
    <>
      <Row ref={divRef} justify="center" align="middle">
        <Col xs={{ span: 24 }} span={12}>
          <Form
            form={form}
            layout="horizontal"
            onFinish={onFinish}
            validateMessages={validateMessages}
          >
            {FormFields.map((field) => {
              return (
                <FormItem
                  label={<StyledLabel>{i18n.t(field.field_label)}</StyledLabel>}
                  name={field.name}
                  hasFeedback={false}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  rules={[...field.rule]}
                >
                  <CustomInput placeholder={field.field_placeholder} />
                </FormItem>
              );
            })}

            <FormItem
              label={<StyledLabel>{i18n.t("your_message")}</StyledLabel>}
              name={"message"}
              style={{
                height: "150px",
              }}
              rules={[{ required: true }]}
            >
              <CustomArea
                showCount={false}
                maxLength={100}
                style={{ height: "100%", width: "100%", resize: "none" }}
                placeholder="Please Enter your message here"
              />
            </FormItem>

            <Form.Item style={{ display: "flex", justifyContent: "center" }}>
              <CustomButton type="primary" htmlType="submit">
                {i18n.t("send")}
              </CustomButton>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Contact;
