import React from "react";
import TalentDetailsHeader from "./TalentDetailsHeader";
import TalentDetailsDesc from "./TalentDetailsDesc";
import TalentDeatilsContent from "./TalentDeatilsContent";
import { i18n } from "../../translations";
import { ContentTitle, TalentDetailsContainer } from "./TalentDetailsStyles";
import TalentDetailsFAQs from "./TalentDetailsFAQs";

const TalentDetails: React.FC = () => {
  const objectives = i18n.t("talent_details_objectives") as string[];
  const benefits = i18n.t("talent_details_benefits") as string[];

  return (
    <>
      <TalentDetailsContainer>
        <TalentDetailsHeader />
        <TalentDetailsDesc />
        <TalentDeatilsContent items={objectives} />
        <ContentTitle>{i18n.t("talent_details_benefit_question")}</ContentTitle>
        <TalentDeatilsContent items={benefits} />
        <ContentTitle>{i18n.t("talent_details_faqs")}</ContentTitle>
        <TalentDetailsFAQs />
      </TalentDetailsContainer>
    </>
  );
};

export default TalentDetails;
