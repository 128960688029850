import { Carousel } from "antd";
import { CarouselRef } from "antd/es/carousel";
import { RightCircleOutlined, LeftCircleOutlined } from "@ant-design/icons";
import {
  Container,
  Header,
  Divider,
  SliderContainer,
  TransImage,
  Arrow,
} from "./PartnersStyles";
import { i18n } from "../../translations";
import { useGetPartners } from "../../network/controllers/partner";
import { Partner } from "../../types/types";
import { Title } from "../../theme/general";
import { useRef } from "react";

interface PartnersProps {
  rows?: number;
}
function Partners({ rows = 1 }: PartnersProps) {
  const carousel: any = useRef<CarouselRef>(null);
  const isMobile = window.innerWidth <= 768;

  const partners = useGetPartners();

  const next = () => {
    carousel?.current?.next();
  };

  const previous = () => {
    carousel?.current?.prev();
  };

  const rightArrow = (
    <Arrow onClick={next}>
      <RightCircleOutlined rev={undefined} />
    </Arrow>
  );
  const leftArrow = (
    <Arrow onClick={previous}>
      <LeftCircleOutlined rev={undefined} />
    </Arrow>
  );

  return (
    <Container>
      <Header>
        <Divider />
        <Title>{i18n.t("partners")}</Title>
      </Header>
      <SliderContainer>
        {!isMobile && leftArrow}
        <Carousel
          ref={carousel}
          slidesToShow={isMobile ? 2 : 3}
          rows={1}
          dots={false}
          adaptiveHeight
          infinite
        >
          {partners.map((partner: Partner) => (
            <TransImage src={partner.image} preview={false} />
          ))}
        </Carousel>
        {!isMobile && rightArrow}
      </SliderContainer>
    </Container>
  );
}

export default Partners;
