import React from "react";
import { Menu, MenuProps } from "antd";
import { ReactComponent as NewLogo } from "../../images/Logo.svg";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";
import "./NavBar.css";
import { DownOutlined, MenuOutlined, SettingOutlined } from "@ant-design/icons";
import { Header, SubHeader } from "../../types/types";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const Wrapper = styled.div`
  border-bottom: 1px solid #fed109;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 100px 0 60px;
  padding-top: 60px;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const Logo = styled(NewLogo)`
  width: 220px;
  min-width: 220px;
  margin: 0px 10px 10px;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 100px;
    min-width: 100px;
  }
`;

export const StyledMenu = styled(Menu)`
  background-color: transparent;
  max-height: 35px;
  width: -webkit-fill-available;
  justify-content: end;
  .ant-menu-item-active {
    background: #0a53be !important;
  }
  .ant-menu-overflow-item {
    border-right: 1px solid #fed109;
    border-top: 0;
    border-bottom: 0;
    color: white;
    &:first-child {
      border-left: 1px solid #fed109;
    }

    .ant-menu-submenu-title {
      height: 100%;
      display: flex;
      align-items: center;
    }

    @media (max-width: 768px) {
      border: none;
    }
  }
`;

const NavBar = () => {
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 768;
  const { userDetails, headerData } = useSelector((state: RootState) => ({
    userDetails: state.userObj.user,
    headerData: state.headerFooterItems,
  }));

  const items: MenuProps["items"] = headerData.map((item: Header, index) => {
    return {
      key: `${index}-${item.id}`,

      overflowedIndicator: () => {
        return <DownOutlined />;
      },
      label: (
        <div
          style={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {item.title}
          <DownOutlined rev={undefined} style={{ marginLeft: "5px" }} />
        </div>
      ),
      children: item.subtitles.map((subItem: SubHeader, index) => {
        return {
          key: `${index}+${subItem.id}`,
          destroyPopupOnHide: true,
          label: (
            <Link
              style={{ textDecoration: "none" }}
              to={`/internal/${encodeURIComponent(subItem.subtitle)}`}
            >
              {subItem?.subtitle}
            </Link>
          ),
        };
      }),
    };
  });

  return (
    <>
      <Wrapper>
        <Logo onClick={() => navigate("/")} />

        {(userDetails.is_staff || userDetails.is_superuser) && !isMobile && (
          <SettingOutlined
            onClick={() => window.open("/admin", "_blank")}
            style={{
              color: "#fed109",
              position: "absolute",
              right: "60px",
              fontSize: " 20px",
              top: "80px",
              cursor: "pointer",
            }}
          />
        )}

        <StyledMenu
          mode="horizontal"
          selectable={false}
          items={items}
          overflowedIndicator={<MenuOutlined />}
        />
      </Wrapper>
    </>
  );
};
// getPopupContainer: menuRef,
//   destroyPopupOnHide: true,
//   autoAdjustOverflow: false,
export default NavBar;
