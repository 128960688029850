import styled from "styled-components";

export const BranchContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 80px;
  justify-content: space-evenly;
  font-size: 40px;
  margin-bottom: 50px;
  max-width: 1000px;
  margin: 0 auto;
`;

export const Branchs = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  font-size: 2.5em;
`;

export const ReachUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-radius: 25px;
  width: 70%;
  margin-top: 100px;
  margin-bottom: 230px;
  margin: 50px auto;
`;
