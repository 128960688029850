import { Table } from "antd";

import { useGetMessages } from "../../network/controllers/contactus";

const ContactFormManage = () => {
  const messages: any = useGetMessages()

  const columns = [
    {
      title: "id",
      width: "5%",
      render: (_: any, record: any) => messages.indexOf(record) + 1,
    },
    {
      title: "name",
      dataIndex: "name",
      key: "name",
      align: "center" as const,
    },
    {
      title: "email",
      dataIndex: "email",
      key: "email",
      align: "center" as const,
    },
    {
      title: "subject",
      dataIndex: "subject",
      key: "subject",
      align: "center" as const,
    },
  ];

  return (
    <div style={{ margin: "50px" }}>
      <Table bordered columns={columns} dataSource={messages} pagination={false} />
    </div>
  );
};

export default ContactFormManage;
