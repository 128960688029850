import { useGetPartners } from "../../network/controllers/partner";
import { Partner } from "../../types/types";
import { TransImage } from "../Partners/PartnersStyles";
import { Col, Row } from "antd";

function TrustUs() {
  const partners = useGetPartners();

  return (
    <Row
      gutter={[16, 16]}
      style={{ marginTop: 20, marginBottom: 50, minHeight: 250 }}
      justify="center"
      align="middle"
    >
      {partners.map((partner: Partner) => (
        <Col key={partner.id} xs={24} sm={12} md={8} lg={6}>
          <TransImage
            src={partner.image}
            preview={false}
            style={{
              maxHeight: 100,
            }}
          />
        </Col>
      ))}
    </Row>
  );
}

export default TrustUs;
