import { Space, Table } from "antd";
import { useState } from "react";
import api from "../../api/axiosInterceptor";
import { DeleteFilled, PlusCircleTwoTone } from "@ant-design/icons";
import InsertModal from "../Modals/InsertModal";
import DeleteModal from "../Modals/DeleteModal";
import { useGetPartners } from "../../network/controllers/partner";
import Partner from "../../network/models/Partner";

const PartnerManage = () => { 
  const [refresh, setRefresh] = useState(false);
  const partners: Partner [] = useGetPartners(refresh)
  const [isInsertOpen, setIsInsertOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [id, setId] = useState<number>();

  const columns = [
    {
      title: "id",
      width: "5%",
      render: (_: any, record: any) => partners.indexOf(record) + 1,
    },
    {
      title: "image",
      dataIndex: "image",
      key: "image",
      render: (text: string) => (
        <a href={text} target={"_blank"}>
          {text ? "see image" : "-"}
        </a>
      ),
      align: "center" as const,
    },
    {
      title: "Actions",
      key: "action",
      render: (_: any, record: any) => (
        <Space size="middle">
          <a
            onClick={() => {
              setIsDeleteOpen(true);
              setId(record.id);
            }}
          >
            <DeleteFilled
              style={{ fontSize: "20px", color: "red" }}
              rev={undefined}
            />
          </a>
        </Space>
      ),
      align: "center" as const,
    },
  ];

  return (
    <div style={{ margin: "50px" }}>
      <div onClick={() => setIsInsertOpen(true)}>
        <PlusCircleTwoTone
          rev={undefined}
          style={{
            fontSize: "25px",
            margin: "15px",
            verticalAlign: "middle",
          }}
        />
        <span
          style={{
            verticalAlign: "middle",
            marginBottom: "25px",
          }}
        >
          Insert new Partner
        </span>
      </div>
      <Table bordered columns={columns} dataSource={partners} pagination={false} />
      <InsertModal
        isInsertOpen={isInsertOpen}
        setIsInsertOpen={setIsInsertOpen}
        refresh={refresh}
        setRefresh={setRefresh}
        name="partners"
      />
      <DeleteModal
        isDeleteOpen={isDeleteOpen}
        setIsDeleteOpen={setIsDeleteOpen}
        id={id}
        refresh={refresh}
        setRefresh={setRefresh}
        name="partners"
      />
    </div>
  );
};

export default PartnerManage;
