import {
  Modal,
  InputNumber,
  Button,
  message,
  Form,
  Input,
  Upload,
  Radio,
} from "antd";
import api from "../../api/axiosInterceptor";
import { useEffect, useState } from "react";
import { EmployeeExperience } from "../../types/types";
import TextArea from "antd/es/input/TextArea";
import { DeleteFilled } from "@ant-design/icons";

interface EditModalProps {
  setIsEditOpen: (isModalOpen: boolean) => void;
  isEditOpen: boolean;
  refresh: boolean;
  id: number | undefined;
  setRefresh: (refresh: boolean) => void;
  data: EmployeeExperience;
}

const EditModal = (props: EditModalProps) => {
  const [form] = Form.useForm();
  const [Image, setImage] = useState<Blob>();

  const handleCancel = () => {
    props.setIsEditOpen(false);
  };

  const handleEdit = (values: any) => {
    const data = new FormData();
    data.append("full_name", values.full_name);
    data.append("job_title", values.job_title);
    data.append("description", values.description);
    data.append("gender", values.gender);
    data.append("rate", values.rate);
    let image: Blob | string = Image || props.data.image;

    if (typeof image === "string") {
      image = image.split("media")[1];
    }
    image && data.append("image", image);

    onFinish(data, false);
  };

  const onFinish = async (data: any, image: boolean) => {
    await api
      .put(`api/employeeExperience/${props?.id}/`, data)
      .then((result: { data: any }) => {
        image
          ? message.success("image deleted successfully")
          : message.success(`Edited successfully`);
        props.setRefresh(!props.refresh);
        !image && props.setIsEditOpen(false);
        return result && result.data;
      })
      .catch(() => {
        message.error(`Edited failed.`);
      });
  };

  const deleteImage = () => {
    const values = {
      job_title: props.data.job_title,
      description: props.data.description,
      rate: props.data?.rate,
      image: null,
      full_name: props.data.full_name,
      gender: props.data.gender,
    };
    onFinish(values, true);
    setImage(undefined);
  };

  useEffect(() => {
    form.setFieldsValue({
      job_title: props.data?.job_title,
      description: props.data?.description,
      rate: props.data?.rate,
      full_name: props.data?.full_name,
      gender: props.data?.gender,
    });
  }, [props.data]);

  return (
    <Modal
      title="Edit Employee Experience"
      open={props.isEditOpen}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel} type="primary">
          Cancel
        </Button>,
      ]}
      width={"50%"}
    >
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        style={{ maxWidth: 1000 }}
        onFinish={handleEdit}
        initialValues={{
          job_title: props.data?.job_title,
          description: props.data?.description,
          rate: props.data?.rate,
        }}
        form={form}
      >
        <Form.Item
          label="Full Name"
          name="full_name"
          rules={[{ required: true }]}
        >
          <Input placeholder="full name" />
        </Form.Item>
        <Form.Item
          label="Job Title"
          name="job_title"
          rules={[{ required: true }]}
        >
          <Input placeholder="job title" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true }]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          name="gender"
          label="Gender"
          rules={[{ required: true, message: "Please select your gender!" }]}
        >
          <Radio.Group>
            <Radio.Button value="male">Male</Radio.Button>
            <Radio.Button value="female">Female</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Rate" name="rate" rules={[{ required: true }]}>
          <InputNumber defaultValue={5} min={0.5} max={5} placeholder="rate" />
        </Form.Item>
        <Form.Item label="Image" name="image">
          <Upload
            name={"image"}
            id={"image"}
            accept="image/*"
            beforeUpload={(file) => {
              setImage(file);
              return false;
            }}
          >
            <Button>Choose Image</Button>
          </Upload>
          {props.data?.image && (
            <p>
              {props.data.image.split("media/")[1]}{" "}
              <DeleteFilled
                style={{ fontSize: "15px", color: "red" }}
                rev={undefined}
                onClick={() => deleteImage()}
              />
            </p>
          )}
        </Form.Item>
        <Form.Item style={{ float: "right", marginLeft: "5px" }}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditModal;
