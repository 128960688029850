import { HeaderBg, SET_BG, SetBackground } from "../actions/background/types";
import Img from "../../images/backgrounds/bg-normal.jpg";

const initialState: HeaderBg = {
  image: {
    background: Img,
  },
};

export const backgroundReducer = (
  state = initialState,
  action: SetBackground,
) => {
  switch (action.type) {
    case SET_BG:
      return { ...state, image: action.payload.image };
    default:
      return state;
  }
};
