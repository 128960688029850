import { useState, useEffect } from "react";
import api from "../../api/axiosInterceptor";

export function useGetLeaders() {
  const [leaders, setLeaders] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await api.get("api/leader/", config);
        setLeaders(response?.data);
      } catch (e) {
        console.log(e);
      }
    };
    fetch();
  }, []);

  return leaders;
}

export function useGetLeaderDetails(jobID: string | undefined) {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await api.get(`api/leader/${jobID}/`, config);
      setJobs(response?.data);
    };
    fetch();
  }, []);

  return jobs;
}
