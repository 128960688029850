import { useState, useEffect } from "react";
import api from "../../api/axiosInterceptor";

export function useGetBranches() {
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await api.get("api/branch/", config);
        setBranches(response?.data);
      } catch (e) {
        console.log(e);
      }
    };
    fetch();
  }, []);

  return branches;
}
