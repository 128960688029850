import Popup from "./Popup";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Col, Layout, Row, Space, Typography } from "antd";
import {
  FacebookOutlined,
  GithubOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import footerBG from "../../images/bg_footer.png";
import { ReactComponent as Logo } from "../../images/Logo.svg";
import api from "../../api/axiosInterceptor";
import { Header, SubHeader } from "../../types/types";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import dayjs from "dayjs";
import { HeaderFooterItem } from "../../redux/actions/HeaderFooterItems/types";
const { Text } = Typography;
const { Footer } = Layout;

const StyledButton = styled(Button)`
  background-color: transparent;
  border: none;
  font-family: DIN-NEXT-ARABIC-LIGHT, sans-serif;
  color: #fff !important;
  font-size: 13px;
  display: block;
  line-height: 24px;
`;

export const FooterLogo = styled(Logo)`
  width: 220px;
  min-width: 220px;
  margin: 0px 10px 10px;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 100px;
    min-width: 100px;
  }
`;

const Title = styled(Typography.Text)`
  font-family: DIN-NEXT-ARABIC-LIGHT, sans-serif;
  color: #929292 !important;
  font-size: 16px;
  display: block;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-bottom: 5px;
  }
`;

const SubTitle = styled(Typography.Text)`
  font-family: DIN-NEXT-ARABIC-LIGHT, sans-serif;
  color: #fff !important;
  font-size: 13px;
  display: block;
  line-height: 24px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-family: DIN-NEXT-ARABIC-LIGHT, sans-serif;
  color: #fff !important;
  font-size: 13px;
  display: block;
  line-height: 28px;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const StyledFooter = styled(Footer)`
  width: 100%;
  background-image: url("${footerBG}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  min-height: 545px;
  padding: 65px 55px 55px 200px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    width: 100%;
    padding: 65px 15px 55px 35px;
  }
`;

const FooterContainer = () => {
  const isMobile = window.innerWidth <= 768;
  const [popup, setPopup] = useState(false);
  const { info, footerData } = useSelector((state: RootState) => ({
    info: state.ASALInfo.info,
    footerData: state.headerFooterItems,
  }));

  return (
    <StyledFooter>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          flex: "1",
          justifyContent: "space-between",
        }}
      >
        {/* First Row */}
        <Row justify={"space-between"}>
          <Col>
            <FooterLogo />
          </Col>
          <Col>
            <Space size="large">
              {/* Icons go here */}
              {info.FACEBOOK_URL && (
                <StyledLink to={info.FACEBOOK_URL} target="_blank">
                  <FacebookOutlined
                    style={{
                      color: "#fff",
                      fontSize: "24px",
                    }}
                  />
                </StyledLink>
              )}
              {info.INSTAGRAM_URL && (
                <StyledLink to={info.INSTAGRAM_URL} target="_blank">
                  <InstagramOutlined
                    style={{
                      color: "#fff",
                      fontSize: "24px",
                    }}
                  />
                </StyledLink>
              )}
              {info.LINKEDIN_URL && (
                <StyledLink to={info.LINKEDIN_URL} target="_blank">
                  <LinkedinOutlined
                    style={{
                      color: "#fff",
                      fontSize: "24px",
                    }}
                  />
                </StyledLink>
              )}
            </Space>
          </Col>
        </Row>

        {/* Second Row */}
        <Row justify={"start"} style={{ margin: 0 }}>
          {footerData?.map((title: any) => {
            return (
              <Col
                key={title}
                style={{
                  width: isMobile ? "100%" : "215px",
                  marginBottom: isMobile ? "20px" : "0",
                }}
              >
                <Title>{title.title}</Title>
                {title.subtitles.map((sub: any) => {
                  return (
                    <StyledLink
                      style={{ textDecoration: "none" }}
                      to={`/internal/${encodeURIComponent(sub.subtitle)}`}
                    >
                      {sub?.subtitle}
                    </StyledLink>
                  );
                })}
              </Col>
            );
          })}
        </Row>

        {/* Third Row */}
        <Row justify="space-between" align="middle">
          <Col>
            <SubTitle>
              All rights reserved, Asal Technologies {dayjs().year()} ©
            </SubTitle>
          </Col>
          <Col>
            <StyledButton onClick={() => setPopup(true)}>
              Privacy Policy
            </StyledButton>
          </Col>
        </Row>
      </div>
      <Popup popupOpen={popup} setPopup={setPopup} />
    </StyledFooter>
  );
};
export default FooterContainer;
