import React from "react";
import {
  Space,
  Form,
  DatePicker,
  TimePicker,
  Button,
  message,
  FormInstance,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs, { Dayjs } from "dayjs";
import styles from "./DateSpecificSlots.module.css"; // Import your stylesheet

type DateSpecificSlot = {
  date: Dayjs;
  startTime: Dayjs;
  endTime: Dayjs;
  key: number;
};

type DateSpecificSlotsProps = {
  dateSpecificSlots: DateSpecificSlot[];
  updateDateSpecificSlot: (
    key: number,
    field: "date" | "startTime" | "endTime",
    value: Dayjs,
  ) => void;
  removeDateSpecificSlot: (key: number) => void;
  addDateSpecificSlot: () => void;
  format: string;
  form: FormInstance;
};

const DateSpecificSlots: React.FC<DateSpecificSlotsProps> = ({
  dateSpecificSlots,
  updateDateSpecificSlot,
  removeDateSpecificSlot,
  addDateSpecificSlot,
  format,
  form,
}) => {
  const handleEndTimeChange = (
    slotKey: number,
    newEndTime: Dayjs,
    currentStartTime: Dayjs,
  ) => {
    if (newEndTime.isBefore(currentStartTime)) {
      message.error("End time must be after start time");
      return;
    }
    updateDateSpecificSlot(slotKey, "endTime", newEndTime);
  };

  const handleStartTimeChange = (
    slotKey: number,
    newStartTime: Dayjs,
    currentEndTime: Dayjs,
  ) => {
    if (newStartTime.isAfter(currentEndTime)) {
      const updatedEndTime = newStartTime.add(30, "minute");
      updateDateSpecificSlot(slotKey, "endTime", updatedEndTime);
    }
    updateDateSpecificSlot(slotKey, "startTime", newStartTime);
  };

  const doesOverlapWithOtherSlots = (
    currentKey: number,
    currentDate: Dayjs,
    currentStartTime: Dayjs,
    currentEndTime: Dayjs,
  ) => {
    return dateSpecificSlots.some(
      (slot) =>
        slot.key !== currentKey &&
        slot.date.isSame(currentDate, "day") &&
        !(
          currentEndTime.isBefore(slot.startTime) ||
          currentStartTime.isAfter(slot.endTime)
        ),
    );
  };

  return (
    <div>
      {dateSpecificSlots.map((slot, index) => (
        <Space key={slot.key} className={styles.slotContainer} align="baseline">
          <Form.Item
            name={["dateSlots", index, "date"]}
            rules={[
              { required: true, message: "Date required" },
              () => ({
                validator(_, value) {
                  if (value) {
                    const startTime = dayjs(
                      form.getFieldValue(["dateSlots", index, "startTime"]),
                    );
                    const endTime = dayjs(
                      form.getFieldValue(["dateSlots", index, "endTime"]),
                    );
                    if (
                      doesOverlapWithOtherSlots(
                        slot.key,
                        value,
                        startTime,
                        endTime,
                      )
                    ) {
                      return Promise.reject(
                        new Error("Overlapping slots on the same date"),
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            initialValue={slot.date}
          >
            <DatePicker
              disabledDate={(current: any) =>
                current && current < dayjs().startOf("day")
              }
              onChange={(date: any) =>
                updateDateSpecificSlot(slot.key, "date", date as Dayjs)
              }
            />
          </Form.Item>

          <Form.Item
            name={["dateSlots", index, "startTime"]}
            rules={[{ required: true, message: "Start time required" }]}
            initialValue={slot.startTime}
          >
            <TimePicker
              onChange={(time) =>
                handleStartTimeChange(slot.key, time as Dayjs, slot.endTime)
              }
              format={format}
            />
          </Form.Item>

          <Form.Item
            name={["dateSlots", index, "endTime"]}
            rules={[{ required: true, message: "End time required" }]}
            initialValue={slot.endTime}
          >
            <TimePicker
              onChange={(time) =>
                handleEndTimeChange(slot.key, time as Dayjs, slot.startTime)
              }
              format={format}
            />
          </Form.Item>

          <MinusCircleOutlined
            onClick={() => removeDateSpecificSlot(slot.key)}
          />
        </Space>
      ))}
      <Button
        type="dashed"
        onClick={addDateSpecificSlot}
        icon={<PlusOutlined />}
      >
        Add Date Specific Slot
      </Button>
    </div>
  );
};

export default DateSpecificSlots;
