import { Row, Col } from "antd";
import { StyledCard, Line, Desc } from "./SecurityAndComplianceStyles";
import { useGetSecurityAndCompliance } from "../../network/controllers/securityAndCompliance";

interface SecurityAndComplianceInfo {
  description: string;
  ImageUrl: string;
}

function SecurityAndComplianceCard({
  description,
  ImageUrl,
}: SecurityAndComplianceInfo) {
  return (
    <StyledCard
      title={
        <div>
          <img src={ImageUrl} style={{ marginTop: "25px" }} />
          <br />
        </div>
      }
      style={{
        width: "100%",
        margin: "auto",
        marginTop: "60px",
        border: "1px solid",
        borderRadius: "25px",
      }}
    >
      <Line />
      <Desc dangerouslySetInnerHTML={{ __html: description }}></Desc>
    </StyledCard>
  );
}

function SecurityAndCompliance() {
  const securityAndComplianceData: any[] = useGetSecurityAndCompliance();
  return (
    <>
      <div style={{ paddingBottom: "200px" }}>
        <Row gutter={[64, 16]} justify={"center"}>
          {securityAndComplianceData &&
            securityAndComplianceData.map((item, index) => {
              return (
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={12}
                  xl={8}
                  key={index}
                  style={{ display: "flex" }}
                >
                  <SecurityAndComplianceCard
                    description={item.description}
                    ImageUrl={item.image}
                  />
                </Col>
              );
            })}
        </Row>
      </div>
    </>
  );
}

export default SecurityAndCompliance;
