import { Card, Line, Title } from "./CardGridStyle";
import marker from "../../images/blog-news-img.png";
import { Row, Col, Typography } from "antd";

interface GridDate {
  data: any[];
  defaultCardImage: string;
}

function CardGrid({ data, defaultCardImage }: GridDate) {
  const handleClick = (linkTo: string) => {
    window.open(linkTo, "_blank");
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "5% 15%",
      }}
    >
      <Row gutter={[64, 32]}>
        {data &&
          data.map((item, index) => {
            return (
              <Col xs={24} sm={24} md={24} lg={24} xl={12} key={index}>
                <Card
                  imageUrl={item.image ? item.image : defaultCardImage}
                  onClick={() => handleClick(item.link)}
                >
                  <img src={marker} style={{ zIndex: 1 }} />
                  <Line />
                </Card>
                <Typography.Title
                  level={4}
                  ellipsis={{ tooltip: item.title }}
                  style={{
                    width: "100%",
                    maxWidth: "18em",
                    color: "#FFD100",
                    marginTop: "12px",
                  }}
                >
                  {item.title}
                </Typography.Title>
              </Col>
            );
          })}
      </Row>
    </div>
  );
}

export default CardGrid;
