import "./Socials.css";
import facebook_logo from "../../images/facebook_logo.png";
import instagram_logo from "../../images/instagram_logo.png";
import linkedin_logo from "../../images/linkedin_logo.png";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";

const genSocials = (info: any) => {
  const socials = [];

  if (info.FACEBOOK_URL) {
    socials.push({
      name: "facebook_logo",
      href: info.FACEBOOK_URL,
      logo: facebook_logo,
    });
  }
  if (info.INSTAGRAM_URL) {
    socials.push({
      name: "instagram_logo",
      href: info.INSTAGRAM_URL,
      logo: instagram_logo,
    });
  }

  if (info.LINKEDIN_URL) {
    socials.push({
      name: "linkedin_logo",
      href: info.LINKEDIN_URL,
      logo: linkedin_logo,
    });
  }

  return socials;
};

const Socials = () => {
  const { info } = useSelector((state: RootState) => ({
    info: state.ASALInfo.info,
  }));

  const socials = genSocials(info);

  return (
    <div className="socials">
      {socials.map((social) => {
        return (
          <a
            className="nav-link"
            href={social.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={social.logo}
              alt={social.name}
              width="55"
              height="55"
              loading="lazy"
              style={{ margin: "0px 15px 50px" }}
            />
          </a>
        );
      })}
    </div>
  );
};

export default Socials;
