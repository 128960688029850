import axios from "axios";
import { useState, useEffect } from "react";
import api from "../../api/axiosInterceptor";

export function useGetJobs(department: string) {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await api.get(`api/allJobs/${department}`, config);
        setJobs(response?.data);
      } catch (e) {
        console.log(e);
      }
    };
    fetch();
  }, []);

  return jobs;
}

export function useGetJobDetails(jobID: string | undefined) {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await api.get(`api/jobs/${jobID}/`, config);
      console.log(response?.data);
      setJobs(response?.data);
    };
    fetch();
  }, []);

  return jobs;
}
