import Socails from "../Socials/Socials";
import Message from "../../images/Message.png";
import { Branchs, ReachUsContainer } from "./ReachsUsStyle";
import { useGetBranches } from "../../network/controllers/branch";
import { Row, Col } from "antd";

const ReachUs = () => {
  const branches: any[] = useGetBranches();
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <ReachUsContainer>
        <img
          src={Message}
          width={"150px"}
          height={"125px"}
          style={{ margin: " 33px auto", display: "block" }}
        />
        <Row gutter={[64, 64]} justify={"center"} style={{ width: "100%" }}>
          {branches.map((branch, index) => {
            return (
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={12}
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Branchs key={branch.id}>
                  <div style={{ textAlign: "center" }}>
                    {" "}
                    {branch.name} Branch
                  </div>
                  <div style={{ textAlign: "center", fontWeight: "normal" }}>
                    {" "}
                    {branch.location}{" "}
                  </div>
                </Branchs>
              </Col>
            );
          })}
        </Row>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "25%",
            margin: "auto",
          }}
        >
          <hr
            style={{
              color: "#FFD82E",
              borderTop: "5px solid",
              opacity: "1 !important",
            }}
          ></hr>
        </div>
        <Socails></Socails>
      </ReachUsContainer>
    </div>
  );
};

export default ReachUs;
