import { Modal, Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import api from "../../api/axiosInterceptor";

interface InsertModalProps {
  setIsInsertOpen: (isModalOpen: boolean) => void;
  isInsertOpen: boolean;
  refresh: boolean;
  setRefresh: (refresh: boolean) => void;
  name: string;
}

const InsertModal = (props: InsertModalProps) => {
  const handleCancel = () => {
    props.setIsInsertOpen(false);
  };

  const uploadprops: UploadProps = {
    name: "image",
    maxCount: 1,
    accept: "image/*",
    action: `${process.env.REACT_APP_API_URL}api/${props.name}/`,
    headers: {
      authorization: "Token " + sessionStorage.getItem("access_token"),
    },
    onChange(info) {
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        props.setRefresh(!props.refresh);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    async onRemove(info) {
      const id = info?.response?.id;
      const res = await api.delete(`api/${props.name}/${id}/`);
      if (res.status === 204) {
        message.success(`${info.name} file deleted successfully`);
        props.setRefresh(!props.refresh);
      } else {
        message.error(`${info.name} file deleted failed.`);
      }
    },
  };

  return (
    <Modal
      title="Insert new Image"
      open={props.isInsertOpen}
      onCancel={handleCancel}
      footer={[
        <Button key="done" onClick={handleCancel} type="primary">
          Done
        </Button>,
      ]}
    >
      <Upload {...uploadprops}>
        <div>Recommended dimensions: 200 * 40</div>
        <Button icon={<UploadOutlined rev={undefined} />}>
          Click to Upload
        </Button>
      </Upload>
    </Modal>
  );
};

export default InsertModal;
