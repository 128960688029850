import { styled } from "styled-components";
import People from "../../images/people.jpg";
import { Button, Card, Image } from "antd";

export const ServicesCard = styled(Card)`
  .ant-card-head-title {
    text-transform: uppercase;
    font-weight: bold;
    padding: 40px 40px 0;
    font-size: 22pt;
    @media (max-width: 768px) {
      white-space: break-spaces;
      padding: 40px 20px 0;
    }
  }
  .ant-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .ant-card-head {
    border-bottom: none;
    padding: 0;
  }
  font-family: "MyriadVariableConcept-Roman", sans-serif;
`;

export const StyledButton = styled(Button)`
  border: 1px solid #ffd100;
  border-radius: 12px;
  font-weight: 500;
  margin: 22px;
  font-size: 15pt;
  background-color: transparent;
  font-family: "MyriadVariableConcept-Roman", sans-serif;
  display: flex;
  align-items: center;
  line-height: 0;
`;

export const Line = styled.hr`
  margin: 0 auto 2rem !important;
  border-top: 2px solid #ffd100;
  opacity: 1;
  width: 30%;
`;

export const Desc = styled.p`
  font-family: "Co-Headline-W23-Arabic-Light-4", sans-serif;
  font-size: 18pt;

  @media (max-width: 768px) {
    font-size: 14pt;
  }
`;

export const HowWeAreDiffCard = styled(Card)`
  border: 1px solid;
  border-radius: 14px;
  background: url(${People}) center;
  background-size: cover;
  height: 55vh;
  margin: 0 160px;
  cursor: pointer;
  @media (max-width: 768px) {
    margin: 0 10px;
    height: 30vh;
  }
  .ant-card-body {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
  }
`;

export const ServicesHeader = styled.div`
  font-family: Abdo-Master-SemiBold, sans-serif;
  color: #ffd100;
  font-size: 33pt;
  padding: 16px;

  @media (max-width: 768px) {
    font-size: 20pt;
  }
`;

export const SDServices = styled.div`
  font-weight: bold;
  margin: 5% 30%;
  font-size: 16pt;
  padding: 4%;
  border: 1px solid #d8d5d5;
  border-radius: 5px;
`;

export const Line2 = styled.hr`
  margin: 1.2rem auto 0 !important;
  border-top: 2px solid #ffd100;
  opacity: 1;
  width: 80%;
`;

export const SD = styled.div`
  background-color: #f4f4f4;
  margin: 0px -235px 30px;
  padding-bottom: 36px;

  @media (max-width: 768px) {
    margin: 0px -100px 30px;
  }
`;

export const SDTitle = styled.div`
  font-family: Abdo-Master-SemiBold, sans-serif;
  font-size: 20pt;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 16pt;
  }
`;

export const SDSecond = styled.div`
  background-color: #ffd100;
  margin: 0px -235px;
  padding-bottom: 24px;

  @media (max-width: 768px) {
    margin: 0px -80px;
  }

  @media (max-width: 300px) {
    margin: 0px -100px;
  }
`;

export const SwLineOne = styled.div`
  margin-top: 10px;
  font-family: Abdo-Master-SemiBold, sans-serif;
  font-size: 14pt;
`;

export const SwLineTwo = styled.div`
  font-family: MyriadVariableConcept-Roman, sans-serif;
  font-size: 1em;
`;

export const Cell = styled.td`
  border-bottom: 2px solid black;
  width: 35%;
  color: white;
  padding-bottom: 10px;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
`;
