import { Modal, Button, message, Form, Input, Checkbox } from "antd";
import api from "../../api/axiosInterceptor";
import { useRef, useState } from "react";
import { CheckboxChangeEvent } from "antd/es/checkbox";

interface InsertModalProps {
  setIsInsertOpen: (isModalOpen: boolean) => void;
  isInsertOpen: boolean;
  refresh: boolean;
  setRefresh: (refresh: boolean) => void;
}

const InsertModal = (props: InsertModalProps) => {
  const [Video, setVideo] = useState<Blob>();
  const [active, setActive] = useState("0");

  const editorRef: any = useRef(null);
  const [form] = Form.useForm();

  const handleCancel = () => {
    props.setIsInsertOpen(false);
  };

  const onChange = (e: CheckboxChangeEvent) => {
    if (!e.target.value) setActive("1");
    else setActive("0");
  };

  const onFinish = async (values: any) => {
    const data = new FormData();

    values.name ? data.append("name", values.name) : data.append("name", "");
    data.append("is_active", active);
    Video && data.append("video", Video);

    await api
      .post(`api/lifeAtAsal/`, data)
      .then((result: { data: any }) => {
        message.success(`Added successfully`);
        props.setRefresh(!props.refresh);
        props.setIsInsertOpen(false);
        return result && result.data;
      })
      .catch((e) => {
        message.error(`Added failed.`);
      });
  };

  return (
    <Modal
      title="Insert new Video"
      open={props.isInsertOpen}
      onCancel={handleCancel}
      width={"50%"}
      footer={[
        <Button key="cancel" onClick={handleCancel} type="primary">
          Cancel
        </Button>,
      ]}
    >
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 25 }}
        layout="horizontal"
        style={{ maxWidth: 1000 }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input placeholder="name" />
        </Form.Item>
        <Form.Item label="Video" name="video" rules={[{ required: true }]}>
          <Input
            type={"file"}
            name={"video"}
            id={"video"}
            accept="video/mp4,video/x-m4v,video/*"
            onChange={(e: any) => {
              setVideo(e.currentTarget.files[0]);
            }}
          />
        </Form.Item>
        <Form.Item label="Video status" name="is_active">
          <Checkbox onChange={onChange}>Active</Checkbox>
        </Form.Item>
        <Form.Item style={{ float: "right", marginLeft: "5px" }}>
          <Button type="primary" htmlType="submit">
            Insert
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InsertModal;
