import {
  Line2,
  SD,
  SDServices,
  ServicesHeader,
  SDTitle,
  Desc,
  SDSecond,
  SwLineOne,
  SwLineTwo,
  Cell,
} from "./ServiceStyles";
import icon from "../../images/services-page.png";
import icon2 from "../../images/icon2.png";
import line from "../../images/line.png";
import { useEffect, useState } from "react";
import api from "../../api/axiosInterceptor";
import { SWServices } from "../../types/types";
import * as _ from "lodash";
import { Table, Text } from "../Numbers/Number";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const ServicesPage = () => {
  const [swServices, setSwServices] = useState<SWServices[][]>();
  const { info } = useSelector((state: RootState) => ({
    info: state.ASALInfo.info,
  }));

  useEffect(() => {
    const getSWData = async () => {
      try {
        const res = await api.get("/api/swServices/");
        if (res) {
          const rows: SWServices[][] = _.chunk(res.data, 4);
          setSwServices(rows);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getSWData();
  }, []);
  return (
    <div style={{ textAlign: "center" }}>
      <ServicesHeader>{info.SERVICES_HEADER}</ServicesHeader>
      <SD style={{ marginTop: "5%" }}>
        <img src={line} height={50} />
        <SDTitle>Software Development</SDTitle>
        <img src={icon} width={100} height={80} />
        <Desc style={{ color: "#4D4D4D", padding: "10px 100px" }}>
          {info.SW_DEV_TEXT}
        </Desc>
      </SD>
      <SD style={{ marginBottom: 0 }}>
        <img src={line} height={50} />
        <SDTitle>Our Software Services</SDTitle>
        <img src={icon2} />
      </SD>
      <SDSecond>
        <img src={line} height={50} />
        <Table style={{ margin: "0 6%" }}>
          {swServices?.map((row, i) => (
            <tr key={i}>
              {row.map((cell, i) => (
                <Cell key={i} style={{ width: "10%" }}>
                  <img src={cell.image} />
                  <Text>
                    <SwLineOne>{cell.title}</SwLineOne>
                    {cell.examples && (
                      <SwLineTwo>{`${cell.examples}`}</SwLineTwo>
                    )}
                  </Text>
                </Cell>
              ))}
            </tr>
          ))}
        </Table>
      </SDSecond>
    </div>
  );
};

export default ServicesPage;
