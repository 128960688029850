import { useState, useEffect } from "react";
import api from "../../api/axiosInterceptor";

export function useGetVacancies() {
  const [vacancies, setVacancies] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await api.get("api/vacancy/", config);
        setVacancies(response?.data);
      } catch (e) {
        console.log(e);
      }
    };
    fetch();
  }, []);

  return vacancies;
}
