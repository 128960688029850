import "./TStyles.css";
import {
  TitleDiv,
  TitleHeader,
  ImageDiv,
  Line,
  Line2,
  Desc,
} from "./TrainingProgramsStyles";
import { i18n } from "../../translations";
import TalentDetails from "../TalentDetails/TalentDetails";

function TrainingPrograms() {
  return (
    <div style={{ marginTop: "30px" }}>
      <div style={{ textAlign: "center" }}>
        <TitleDiv>
          <TitleHeader>{i18n.t("training_programs_desc")}</TitleHeader>
        </TitleDiv>
        <ImageDiv>
          <Line />
          <div style={{ width: "60%" }}>
            <Desc>{i18n.t("training_programs_first_desc")}</Desc>
            <Line2 />
            <Desc>{i18n.t("training_programs_second_desc")}</Desc>
          </div>
        </ImageDiv>
      </div>
      <TalentDetails />
    </div>
  );
}

export default TrainingPrograms;
