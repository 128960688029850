import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { LeaderCard, MetaLeaderCard } from "./LeadershipStyle";

interface LeadershipCardProps {
  id: string;
  name: string;
  image_url: string;
  setLeader: (id: number) => void;
}

const LeadershipCard = ({
  id,
  name,
  image_url,
  setLeader,
}: LeadershipCardProps) => {
  return (
    <LeaderCard
      onClick={() => setLeader(parseInt(id))}
      cover={<img alt={name} src={image_url} />}
      actions={[<ArrowRightOutlined rev={undefined} />]}
    >
      <MetaLeaderCard
        title={name}
        style={{ alignItems: "flex-start", justifyContent: "flex-start" }}
      />
    </LeaderCard>
  );
};

export default LeadershipCard;
