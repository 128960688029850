import { Modal, Upload, Button, message, Form, Input } from "antd";
import api from "../../api/axiosInterceptor";
import { useEffect, useState } from "react";
import { ASALInfo } from "../../types/types";
import { DeleteFilled } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

interface EditModalProps {
  setIsEditOpen: (isModalOpen: boolean) => void;
  isEditOpen: boolean;
  refresh: boolean;
  id: number | undefined;
  setRefresh: (refresh: boolean) => void;
  data: ASALInfo;
}

const EditModal = (props: EditModalProps) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    props.setIsEditOpen(false);
  };

  const handleEdit = (values: any) => {
    const data = new FormData();
    data.append("title", values.title);
    data.append("description", values.description);
    onFinish(data);
  };

  const onFinish = async (data: any) => {
    await api
      .put(`api/AsalDetails/${props?.id}/`, data)
      .then((result: { data: any }) => {
        props.setRefresh(!props.refresh);
        props.setIsEditOpen(false);
        message.success(`Edited successfully.`);
        return result && result.data;
      })
      .catch(() => {
        message.error(`Edited failed.`);
      });
  };

  useEffect(() => {
    form.setFieldsValue({
      title: props.data?.title,
      description: props.data?.description,
    });
  }, [props.data]);

  return (
    <Modal
      title="Edit Info"
      open={props.isEditOpen}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel} type="primary">
          Cancel
        </Button>,
      ]}
      width={"40%"}
    >
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        style={{ maxWidth: 1000 }}
        onFinish={handleEdit}
        initialValues={{
          title: props.data?.title,
          description: props.data?.description,
        }}
        form={form}
      >
        <Form.Item label="Key" name="title">
          <Input disabled />
        </Form.Item>
        <Form.Item label="Value" name="description">
          <TextArea />
        </Form.Item>
        <Form.Item style={{ float: "right", marginLeft: "5px" }}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditModal;
