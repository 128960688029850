import { Modal, Button, message, Form, Input, InputNumber, Radio } from "antd";
import api from "../../api/axiosInterceptor";
import { useRef, useState } from "react";
import TextArea from "antd/es/input/TextArea";

interface InsertModalProps {
  setIsInsertOpen: (isModalOpen: boolean) => void;
  isInsertOpen: boolean;
  refresh: boolean;
  setRefresh: (refresh: boolean) => void;
}

const InsertModal = (props: InsertModalProps) => {
  const [form] = Form.useForm();
  const [Image, setImage] = useState<Blob>();

  const handleCancel = () => {
    props.setIsInsertOpen(false);
  };

  const onFinish = async (values: any) => {
    const data = new FormData();

    values.full_name
      ? data.append("full_name", values.full_name)
      : data.append("full_name", "");

    values.job_title
      ? data.append("job_title", values.job_title)
      : data.append("job_title", "");

    values.description
      ? data.append("description", values.description)
      : data.append("description", "");

    values.gender
      ? data.append("gender", values.gender)
      : data.append("gender", "");

    values.rate ? data.append("rate", values.rate) : data.append("rate", "");
    Image && data.append("image", Image);

    await api
      .post(`api/employeeExperience/`, data)
      .then((result: { data: any }) => {
        message.success(`Added successfully`);
        props.setRefresh(!props.refresh);
        props.setIsInsertOpen(false);
        return result && result.data;
      })
      .catch((e) => {
        message.error(`Added failed.`);
      });
  };

  return (
    <Modal
      title="Insert new Employee Experience"
      open={props.isInsertOpen}
      onCancel={handleCancel}
      width={"50%"}
      footer={[
        <Button key="cancel" onClick={handleCancel} type="primary">
          Cancel
        </Button>,
      ]}
    >
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 25 }}
        layout="horizontal"
        style={{ maxWidth: 1000 }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label="Full Name"
          name="full_name"
          rules={[{ required: true }]}
        >
          <Input placeholder="full name" />
        </Form.Item>
        <Form.Item
          label="Job Title"
          name="job_title"
          rules={[{ required: true }]}
        >
          <Input placeholder="job title" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true }]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          name="gender"
          label="Gender"
          rules={[{ required: true, message: "Please select your gender!" }]}
        >
          <Radio.Group>
            <Radio.Button value="male">Male</Radio.Button>
            <Radio.Button value="female">Female</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Rate" name="rate" rules={[{ required: true }]}>
          <InputNumber
            type="number"
            defaultValue={5}
            min={0.5}
            max={5}
            placeholder="rate"
          />
        </Form.Item>
        <Form.Item label="Image" name="image">
          <Input
            type={"file"}
            name={"image"}
            id={"image"}
            accept="image/*"
            onChange={(e: any) => {
              setImage(e.currentTarget.files[0]);
            }}
          />
        </Form.Item>
        <Form.Item style={{ float: "right", marginLeft: "5px" }}>
          <Button type="primary" htmlType="submit">
            Insert
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InsertModal;
