import { Modal, Button, message, Form, Input, Select } from "antd";
import api from "../../api/axiosInterceptor";
import { useEffect, useState } from "react";
import { Album } from "../../types/types";

interface InsertModalProps {
  setIsInsertOpen: (isModalOpen: boolean) => void;
  isInsertOpen: boolean;
  refresh: boolean;
  setRefresh: (refresh: boolean) => void;
  name: string;
  albums: Album[];
}

const InsertModal = (props: InsertModalProps) => {
  const [file, setFile] = useState<Blob>();
  const [form] = Form.useForm();

  const handleCancel = () => {
    props.setIsInsertOpen(false);
  };

  const onFinish = async (values: any) => {
    const data = new FormData();
    if (props.name === "albums") {
      data.append("album_title", values.album_title);
    } else {
      data.append("album_id", values.album_id);
      file && data.append("image", file);
    }

    await api
      .post(`api/${props.name}/`, data)
      .then((result: { data: any }) => {
        message.success(`Added successfully`);
        props.setRefresh(!props.refresh);
        props.setIsInsertOpen(false);
        return result && result.data;
      })
      .catch(() => {
        message.error(`Added failed.`);
      });
  };

  return (
    <Modal
      title={`Insert new ${props.name}`}
      open={props.isInsertOpen}
      onCancel={handleCancel}
      width={"50%"}
      footer={[
        <Button key="cancel" onClick={handleCancel} type="primary">
          Cancel
        </Button>,
      ]}
    >
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 25 }}
        layout="horizontal"
        style={{ maxWidth: 1000 }}
        onFinish={onFinish}
        form={form}
      >
        {props.name === "albums" ? (
          <>
            <Form.Item
              label="Title"
              name="album_title"
              rules={[{ required: true }]}
            >
              <Input placeholder="Ice cream day" />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item
              label="Album"
              name="album_id"
              rules={[{ required: true }]}
            >
              <Select
                defaultValue="Select Album"
                style={{ width: 120 }}
                options={props.albums.map((album) => {
                  return { label: album.album_title, value: album.id };
                })}
              />
            </Form.Item>
            <Form.Item label="image" name="image" rules={[{ required: true }]}>
              <Input
                type={"file"}
                name={"image"}
                id={"image"}
                accept="image/*"
                onChange={(e: any) => {
                  setFile(e.currentTarget.files[0]);
                }}
              />
            </Form.Item>
          </>
        )}
        <Form.Item style={{ float: "right", marginLeft: "5px" }}>
          <Button type="primary" htmlType="submit">
            Insert
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InsertModal;
