import { Button, Modal } from "antd";
import { i18n } from "../../translations";
import { useEffect, useState } from "react";
import api from "../../api/axiosInterceptor";
import { styled } from "styled-components";

const Popup = (props: {
  popupOpen: boolean;
  setPopup: (popup: boolean) => void;
}) => {
  const [data, setData] = useState([{ title: "", description: "" }]);

  const get_popup_info = async () => {
    const res = await api.get("api/AsalDetails/PrivacyPolicy/");
    if (res) {
      const popupData = res.data;
      setData(popupData);
    }
  };
  const StyledModal = styled(Modal)`
    .ant-modal-title {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #ffd100;
      margin-bottom: 20px;
    }
  `;

  useEffect(() => {
    get_popup_info();
  }, []);

  return (
    <StyledModal
      title={data[0]?.title}
      open={props.popupOpen}
      onCancel={() => props.setPopup(false)}
      footer={null}
      centered
      width={"50%"}
      bodyStyle={{ margin: "0 3%" }}
    >
      <div dangerouslySetInnerHTML={{ __html: data[0]?.description }}></div>
    </StyledModal>
  );
};

export default Popup;
