import api from "../../api/axiosInterceptor";
import { useEffect, useState } from "react";
import { InternalPages } from "../../types/types";
import { Description, Image } from "./InternalCompStyle";
import quarterText from "../../images/quarter.png";
import styled from "styled-components";
import { SPECIAL_PAGES } from "../../constants/strings";

const InternalPageComp = (props: {
  title: string | undefined;

  data: InternalPages | undefined;
}) => {
  const { data } = props;

  const Text = styled.div`
    background-color: rgba(0, 0, 0, 0.7);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
  `;

  return (
    <div>
      {data?.description && (
        <Description
          dangerouslySetInnerHTML={{ __html: data.description }}
        ></Description>
      )}
      {data?.image && (
        <Image
          $title={props.title}
          $url={data.image}
          style={{
            borderRadius:
              props.title === SPECIAL_PAGES.NEWSLETTER ? "14px" : "0",
          }}
        >
          {props.title === SPECIAL_PAGES.NEWSLETTER && (
            <Text>
              <img src={quarterText} alt="" width={"30%"} />
            </Text>
          )}
        </Image>
      )}
    </div>
  );
};
export default InternalPageComp;
