import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Form, Input, Button, Card, Alert } from "antd";
import logo from "../../images/logo2.jpg";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LoginApi } from "../../api/loginApi";
import { setUser } from "../../redux/actions/userObject/userActions";
import React from "react";

const StyledCard = styled(Card)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 30px;
`;

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = React.useState("");

  const onSubmit = (values: any) => {
    const userObj: any = {
      username: values.username,
      password: values.password,
    };
    LoginApi(userObj.username, userObj.password)
      .then((authResult: any) => {
        const { key, user } = authResult;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        dispatch(setUser(user));
        sessionStorage.setItem("access_token", key);
        sessionStorage.setItem("user", JSON.stringify(user));
        navigate("/");
      })
      .catch((e) => {
        setError("Wrong email or password");
      });
  };

  return (
    <StyledCard
      bordered={true}
      style={{ width: "450px", height: "550px", padding: "50px" }}
    >
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onSubmit}
      >
        <Form.Item>
          <img
            src={logo}
            width={"200px"}
            height={"70px"}
            style={{ margin: "auto", display: "block" }}
          />
        </Form.Item>
        <Form.Item>
          {error && <Alert message={error} type="error" showIcon />}
        </Form.Item>
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your Username!",
            },
          ]}
          style={{ marginTop: "50px" }}
        >
          <Input
            name="username"
            suffix={
              <UserOutlined className="site-form-item-icon" rev={undefined} />
            }
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <Input
            suffix={
              <LockOutlined className="site-form-item-icon" rev={undefined} />
            }
            type="password"
            placeholder="Password"
            name="password"
          />
        </Form.Item>

        <Form.Item>
          <Button
            htmlType="submit"
            style={{
              width: "100%",
              marginTop: "60px",
              backgroundColor: "#FFC15B",
            }}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </StyledCard>
  );
};
export default LoginForm;
