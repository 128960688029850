import { Modal, Button, message, Form, Input, Select, InputNumber } from "antd";
import api from "../../api/axiosInterceptor";
import { Header, InternalPages } from "../../types/types";

interface InsertModalProps {
  setIsInsertOpen: (isModalOpen: boolean) => void;
  isInsertOpen: boolean;
  refresh: boolean;
  setRefresh: (refresh: boolean) => void;
  name: string;
  internalPages: InternalPages[];
  headers: Header[];
}

const InsertNavBar = (props: InsertModalProps) => {
  const [form] = Form.useForm();
  const handleCancel = () => {
    props.setIsInsertOpen(false);
    form.setFieldsValue({
      title: "",
      order: 1,
    });
  };

  const handleAdd = (values: any) => {
    const data = new FormData();

    if (props.name === "header") {
      data.append("title", values.title);
      data.append("order", values.order);
    } else {
      data.append("header_title_id", values.header_title_id);
      data.append("subtitle", values.subtitle.label);
      data.append("page_id", values.subtitle.value);
      data.append("order", values.order);
    }

    onFinish(data);
  };

  const onFinish = async (data: any) => {
    await api
      .post(`api/${props.name}/`, data)
      .then((result: { data: any }) => {
        message.success(`Added successfully`);
        props.setRefresh(!props.refresh);
        props.setIsInsertOpen(false);
        form.setFieldsValue({
          title: "",
        });
        return result && result.data;
      })
      .catch(() => {
        message.error(`Added failed.`);
      });
  };

  return (
    <Modal
      title={`Insert New ${props.name} Item`}
      open={props.isInsertOpen}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel} type="primary">
          Cancel
        </Button>,
      ]}
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        style={{ maxWidth: 600 }}
        onFinish={handleAdd}
        form={form}
      >
        {props.name === "header" ? (
          <>
            <Form.Item
              label="Menu Item Title"
              name="title"
              rules={[{ required: true }]}
            >
              <Input placeholder="Our Offering" />
            </Form.Item>
            <Form.Item label="Menu Item Order" name="order">
              <InputNumber
                formatter={(value) => `${parseInt(value as string)}`}
              />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item
              label="Menu Title"
              name="header_title_id"
              rules={[{ required: true }]}
            >
              <Select
                defaultValue="Select Menu Title"
                style={{ width: 200 }}
                options={props.headers.map((header) => {
                  return { label: header.title, value: header.id };
                })}
              />
            </Form.Item>
            <Form.Item
              label="Sub Menu Title"
              name="subtitle"
              rules={[{ required: true }]}
            >
              <Select
                defaultValue="Select Sub Menu Title"
                labelInValue
                style={{ width: 200 }}
                options={props.internalPages.map((internalPage) => {
                  return {
                    label: internalPage.title,
                    value: internalPage.id,
                  };
                })}
              />
            </Form.Item>
            <Form.Item label="Item Order" name="order">
              <InputNumber
                formatter={(value) => `${parseInt(value as string)}`}
              />
            </Form.Item>
          </>
        )}

        <Form.Item style={{ float: "right", marginLeft: "5px" }}>
          <Button type="primary" htmlType="submit">
            Insert
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InsertNavBar;
