import React from "react";
import { Avatar, List } from "antd";
import { useGetMattersToUs } from "../../network/controllers/mattersToUs";

const MattersToUs: React.FC = () => {
  const mattersToUs: any[] = useGetMattersToUs();

  return (
    <List
      itemLayout="horizontal"
      dataSource={mattersToUs}
      renderItem={(item, index) => (
        <List.Item
          key={index}
          style={{
            borderRadius: "10px",
            border: "1px solid #e8e8e8",
            marginBottom: "38px",
          }}
        >
          <List.Item.Meta
            style={{ marginLeft: "15px" }}
            avatar={
              <Avatar
                src={item.image}
                size={{ xs: 24, sm: 32, md: 40, lg: 70, xl: 70, xxl: 70 }}
              />
            }
            title={
              <h1
                style={{
                  fontFamily: "DIN-NEXT-ARABIC-LIGHT, sans-serif",
                  fontWeight: "bold",
                  fontSize: "3em",
                  color: "#333333",
                  marginTop: "15px",
                }}
              >
                {item.title}
              </h1>
            }
            description={
              <p
                style={{
                  fontFamily: "Cairo-Regular, sans-serif",
                  fontSize: "1.8em",
                  color: "#808080",
                }}
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            }
          />
        </List.Item>
      )}
    />
  );
};

export default MattersToUs;
