import { styled } from "styled-components";
import { SPECIAL_PAGES } from "../../constants/strings";

export const Description = styled.div`
  font-size: 19pt;
  color: #4d4d4d;
  font-family: Cairo-Regular, sans-serif;
  margin-bottom: 50px;
`;

export const Image = styled.div<{ $url: string; $title: string | undefined }>`
  background-image: url(${(props) => props.$url});
  height: 65vh;
  background-position-y: ${(props) =>
    props.$title === SPECIAL_PAGES.NEWSLETTER ? "center" : "bottom"};
  background-size: cover;
  margin-bottom: ${(props) =>
    props.$title === SPECIAL_PAGES.NEWSLETTER ? "150px" : "0"};
`;
