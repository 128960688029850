import { Title } from "../../theme/general";
import { Cell, Text, LineOne, LineTwo } from "./Number";
import { i18n } from "../../translations";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import Counter from "./Counter";
import { Col, Row } from "antd";
import { useEffect, useRef, useState } from "react";

const Numbers = () => {
  const { info } = useSelector((state: RootState) => ({
    info: state.ASALInfo.info,
  }));
  const isMobile = window.innerWidth <= 768;
  const [inView, setInView] = useState(isMobile);
  const componentRef = useRef(null);

  useEffect(() => {
    if (!componentRef?.current || isMobile) return;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting && entry.intersectionRatio >= 0.1);
      },
      {
        threshold: 0.2, // Triggers when at least 20% of the component is visible
      },
    );

    observer.observe(componentRef?.current);

    return () => {
      if (!componentRef?.current) return;
      observer.unobserve(componentRef?.current);
    };
  }, [componentRef?.current]);

  return (
    <div
      style={{
        backgroundColor: "#FFD100",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        position: "relative",
        justifyContent: "space-evenly",
      }}
    >
      <Title style={{ fontSize: "42px" }}>{i18n.t("numbers_title")}</Title>
      {info?.CHOICE_OF_EMPLOYMENT ? (
        <div
          ref={componentRef}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minHeight: "calc(100vh * 0.7)",
            gap: isMobile ? 0 : 100,
          }}
        >
          {!isMobile && (
            <>
              <Row gutter={[48, 48]} wrap={false}>
                <Col span={8}>
                  <Cell>
                    <Text>
                      <LineOne>
                        <p>No.</p>
                        <Counter
                          number={Number(info.CHOICE_OF_EMPLOYMENT)}
                          startCount={inView}
                        />
                      </LineOne>
                      <LineTwo>{i18n.t("numbers_1")}</LineTwo>
                    </Text>
                  </Cell>
                </Col>
                <Col span={8}>
                  <Cell>
                    <Text>
                      <Counter
                        number={Number(info.YEARS_EXPERIENCE)}
                        startCount={inView}
                      />
                      <LineTwo>{i18n.t("numbers_2")}</LineTwo>
                    </Text>
                  </Cell>
                </Col>
                <Col span={8}>
                  <Cell>
                    <Text>
                      <Counter
                        number={Number(info.ENGINEERS)}
                        prefix={"+"}
                        startCount={inView}
                      />
                      <LineTwo>{i18n.t("numbers_3")}</LineTwo>
                    </Text>
                  </Cell>
                </Col>
              </Row>
              <Row gutter={[48, 48]} wrap={false}>
                <Col span={8}>
                  <Cell>
                    <Text>
                      <Counter
                        number={Number(info.FEMALES)}
                        postfix={"%"}
                        startCount={inView}
                      />
                      <LineTwo>{i18n.t("numbers_4")}</LineTwo>
                    </Text>
                  </Cell>
                </Col>
                <Col span={8}>
                  <Cell>
                    <Text>
                      <Counter
                        number={Number(info.CLIENTS)}
                        prefix={"+"}
                        startCount={inView}
                      />
                      <LineTwo>{i18n.t("numbers_5")}</LineTwo>
                    </Text>
                  </Cell>
                </Col>
                <Col span={8}>
                  <Cell>
                    <Text>
                      <Counter
                        number={Number(info.LOCATIONS)}
                        startCount={inView}
                      />
                      <LineTwo>{i18n.t("numbers_6")}</LineTwo>
                    </Text>
                  </Cell>
                </Col>
              </Row>
            </>
          )}
          {isMobile && (
            <>
              <Row>
                <Col>
                  <Cell>
                    <Text>
                      <LineOne>
                        <p>No.</p>
                        <Counter
                          number={Number(info.CHOICE_OF_EMPLOYMENT)}
                          startCount={inView}
                        />
                      </LineOne>
                      <LineTwo>{i18n.t("numbers_1")}</LineTwo>
                    </Text>
                  </Cell>
                </Col>
                <Col>
                  <Cell>
                    <Text>
                      <Counter
                        number={Number(info.YEARS_EXPERIENCE)}
                        startCount={inView}
                      />
                      <LineTwo>{i18n.t("numbers_2")}</LineTwo>
                    </Text>
                  </Cell>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Cell>
                    <Text>
                      <Counter
                        number={Number(info.ENGINEERS)}
                        prefix={"+"}
                        startCount={inView}
                      />
                      <LineTwo>{i18n.t("numbers_3")}</LineTwo>
                    </Text>
                  </Cell>
                </Col>
                <Col>
                  <Cell>
                    <Text>
                      <Counter
                        number={Number(info.FEMALES)}
                        postfix={"%"}
                        startCount={inView}
                      />
                      <LineTwo>{i18n.t("numbers_4")}</LineTwo>
                    </Text>
                  </Cell>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Cell>
                    <Text>
                      <Counter
                        number={Number(info.CLIENTS)}
                        prefix={"+"}
                        startCount={inView}
                      />
                      <LineTwo>{i18n.t("numbers_5")}</LineTwo>
                    </Text>
                  </Cell>
                </Col>
                <Col>
                  <Cell>
                    <Text>
                      <Counter
                        number={Number(info.LOCATIONS)}
                        startCount={inView}
                      />
                      <LineTwo>{i18n.t("numbers_6")}</LineTwo>
                    </Text>
                  </Cell>
                </Col>
              </Row>
            </>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Numbers;
