import newsImage from "../../images/news-bg.jpg";
import { useGetNews } from "../../network/controllers/news";
import CardGrid from "../CardGrid/CardGrid";

function News() {
  const newsData = useGetNews();
  return <CardGrid data={newsData} defaultCardImage={newsImage} />;
}

export default News;
