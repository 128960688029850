import { Modal, Upload, Button, message, Form, Input, Checkbox } from "antd";
import api from "../../api/axiosInterceptor";
import { useEffect, useRef, useState } from "react";
import { Leader } from "../../types/types";
import { DeleteFilled } from "@ant-design/icons";
import Editor from "../Editor/Editor";

interface EditModalProps {
  setIsEditOpen: (isModalOpen: boolean) => void;
  isEditOpen: boolean;
  refresh: boolean;
  id: number | undefined;
  setRefresh: (refresh: boolean) => void;
  data: Leader;
}

const EditModal = (props: EditModalProps) => {
  const [coverImage, setCoverImage] = useState<Blob>();
  const [descImage, setDescImage] = useState<Blob>();
  const editorRef: any = useRef(null);
  const [form] = Form.useForm();

  const handleCancel = () => {
    props.setIsEditOpen(false);
  };

  const handleEdit = (values: any) => {
    const data = new FormData();
    data.append("name", values.name);
    data.append("description", values.description);
    let cover_image: Blob | string = coverImage || props.data.cover_image;
    let desc_image: Blob | string = descImage || props.data.desc_image;

    if (typeof cover_image === "string") {
      cover_image = cover_image.split("media")[1];
    }
    if (typeof desc_image === "string") {
      desc_image = desc_image.split("media")[1];
    }
    cover_image && data.append("cover_image", cover_image);
    desc_image && data.append("desc_image", desc_image);

    onFinish(data, false);
  };

  const onFinish = async (data: any, image: boolean) => {
    await api
      .put(`api/leader/${props?.id}/`, data)
      .then((result: { data: any }) => {
        image
          ? message.success("image deleted successfully")
          : message.success(`Edited successfully`);
        props.setRefresh(!props.refresh);
        !image && props.setIsEditOpen(false);
        return result && result.data;
      })
      .catch(() => {
        message.error(`Edited failed.`);
      });
  };

  const deleteCoverImage = () => {
    const values = {
      name: props.data.name,
      description: props.data.description,
      cover_image: null,
      desc_image: props.data.desc_image,
    };
    onFinish(values, true);
    setCoverImage(undefined);
  };

  const deleteDescImage = () => {
    const values = {
      name: props.data.name,
      description: props.data.description,
      cover_image: props.data.cover_image,
      desc_image: null,
    };
    onFinish(values, true);
    setDescImage(undefined);
  };

  useEffect(() => {
    form.setFieldsValue({
      name: props.data?.name,
      description: props.data?.description,
    });
  }, [props.data]);

  return (
    <Modal
      title="Edit Section"
      open={props.isEditOpen}
      destroyOnClose={true}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel} type="primary">
          Cancel
        </Button>,
      ]}
      width={"50%"}
    >
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        style={{ maxWidth: 1000 }}
        onFinish={handleEdit}
        initialValues={{
          name: props.data?.name,
          description: props.data?.description,
        }}
        form={form}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input placeholder="Leader Name" />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Editor
            setEditor={(editor: any) => (editorRef.current = editor)}
            defaultValue={props.data?.description}
            onChange={(text: any) => {
              form.setFieldValue("description", text);
            }}
          />
        </Form.Item>
        <Form.Item label="Cover Image" name="cover_image">
          <Upload
            name={"cover_image"}
            id={"cover_image"}
            accept="image/*"
            beforeUpload={(file) => {
              setCoverImage(file);
              return false;
            }}
          >
            <Button>Choose Cover image</Button>
          </Upload>
          {props.data?.cover_image && (
            <p>
              {props.data.cover_image.split("media/")[1]}{" "}
              <DeleteFilled
                style={{ fontSize: "15px", color: "red" }}
                rev={undefined}
                onClick={() => deleteCoverImage()}
              />
            </p>
          )}
        </Form.Item>
        <Form.Item label="Description Image" name="desc_image">
          <Upload
            name={"desc_image"}
            id={"desc_image"}
            accept="image/*"
            beforeUpload={(file) => {
              setDescImage(file);
              return false;
            }}
          >
            <Button>Choose Description image</Button>
          </Upload>
          {props.data?.desc_image && (
            <p>
              {props.data.desc_image.split("media/")[1]}{" "}
              <DeleteFilled
                style={{ fontSize: "15px", color: "red" }}
                rev={undefined}
                onClick={() => deleteDescImage()}
              />
            </p>
          )}
        </Form.Item>
        <Form.Item style={{ float: "right", marginLeft: "5px" }}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditModal;
