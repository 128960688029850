import { User } from "../userObject/types";

export const SET_HEADER_FOOTER_ITEMS = "SET_HEADER_FOOTER_ITEMS";

interface InternalPages {
  id: number;
  image: string;
  title: string;
  is_enabled: boolean;
  description: string;
  created_at: string;
  updated_at: string;
}

export interface SubHeaderFooterItem {
  id: number;
  order: number;
  header_title: any;
  subtitle: string;
  page: InternalPages;
}

export interface HeaderFooterItem {
  id: number;
  order: number;
  subtitles: SubHeaderFooterItem[];
  title: string;
  created_at: string;
  updated_at: string;
}
export interface SetHeaderFooterItems {
  type: typeof SET_HEADER_FOOTER_ITEMS;
  payload: HeaderFooterItem[];
}
