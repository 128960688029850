// theme.js
const theme = {
  token: {
    colorPrimary: "#FFD100",
    colorPrimaryBgHover: "#FFD100",
    borderRadius: 0,
    motion: false,
  },
  components: {
    Button: { colorPrimary: "#FFD100", backgroundColor: "red" },
  },
};

export default theme;
