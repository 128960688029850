import blogImage from "../../images/blog-bg.jpg";
import { useGetBlogs } from "../../network/controllers/blog";
import CardGrid from "../CardGrid/CardGrid";

function Blog() {
  const blogData = useGetBlogs();
  return <CardGrid data={blogData} defaultCardImage={blogImage} />;
}

export default Blog;
