import { Modal, Button, message, Form, Input } from "antd";
import api from "../../api/axiosInterceptor";
import { useRef } from "react";
import Editor from "../Editor/Editor";

interface InsertModalProps {
  setIsInsertOpen: (isModalOpen: boolean) => void;
  isInsertOpen: boolean;
  refresh: boolean;
  setRefresh: (refresh: boolean) => void;
}

const InsertModal = (props: InsertModalProps) => {
  const editorRef: any = useRef(null);
  const [form] = Form.useForm();

  const handleCancel = () => {
    props.setIsInsertOpen(false);
  };

  const onFinish = async (values: any) => {
    const data = new FormData();

    data.append("name", values.name);
    values.location
      ? data.append("location", values.location)
      : data.append("location", "");

    await api
      .post(`api/branch/`, data)
      .then((result: { data: any }) => {
        message.success(`Added successfully`);
        props.setRefresh(!props.refresh);
        props.setIsInsertOpen(false);
        return result && result.data;
      })
      .catch((e) => {
        if (e.response.status === 500) message.error(`Name must be unique`);
        else message.error(`Added failed.`);
      });
  };

  return (
    <Modal
      title="Insert new Branch"
      open={props.isInsertOpen}
      onCancel={handleCancel}
      width={"50%"}
      footer={[
        <Button key="cancel" onClick={handleCancel} type="primary">
          Cancel
        </Button>,
      ]}
    >
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 25 }}
        layout="horizontal"
        style={{ maxWidth: 1000 }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input placeholder="name" />
        </Form.Item>
        <Form.Item
          label="Location"
          name="location"
          rules={[{ required: true }]}
        >
          <Input placeholder="location" />
        </Form.Item>

        <Form.Item style={{ float: "right", marginLeft: "5px" }}>
          <Button type="primary" htmlType="submit">
            Insert
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InsertModal;
