export const SET_BG = "SET_BG";

export interface Image {
  background: string;
}

export interface HeaderBg {
  image: Image;
}

export interface SetBackground {
  type: typeof SET_BG;
  payload: HeaderBg;
}
