import { Modal, Upload, Button, message, Form, Input, Checkbox } from "antd";
import api from "../../api/axiosInterceptor";
import { useEffect, useRef, useState } from "react";

interface EditModalProps {
  setIsEditOpen: (isModalOpen: boolean) => void;
  isEditOpen: boolean;
  refresh: boolean;
  id: number | undefined;
  setRefresh: (refresh: boolean) => void;
  data: any;
}

const EditModal = (props: EditModalProps) => {
  const editorRef: any = useRef(null);
  const [form] = Form.useForm();

  const handleCancel = () => {
    props.setIsEditOpen(false);
  };

  const handleEdit = (values: any) => {
    const data = new FormData();
    data.append("name", values.name);
    data.append("location", values.location);
    onFinish(data);
  };

  const onFinish = async (data: any) => {
    await api
      .put(`api/branch/${props?.id}/`, data)
      .then((result: { data: any }) => {
        props.setRefresh(!props.refresh);
        props.setIsEditOpen(false);
        message.success(`Edited successfully.`);

        return result && result.data;
      })
      .catch(() => {
        message.error(`Edited failed.`);
      });
  };

  useEffect(() => {
    form.setFieldsValue({
      name: props.data?.name,
      location: props.data?.location,
    });
  }, [props.data]);

  return (
    <Modal
      title="Edit Branch"
      open={props.isEditOpen}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel} type="primary">
          Cancel
        </Button>,
      ]}
      width={"50%"}
    >
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        style={{ maxWidth: 1000 }}
        onFinish={handleEdit}
        initialValues={{
          title: props.data?.title,
          description: props.data?.description,
        }}
        form={form}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input placeholder="name" />
        </Form.Item>
        <Form.Item
          label="Location"
          name="location"
          rules={[{ required: true }]}
        >
          <Input placeholder="location" />
        </Form.Item>

        <Form.Item style={{ float: "right", marginLeft: "5px" }}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditModal;
