export const SET_DETAILS = "SET_DETAILS";

export interface Info {
  [key: string]: string;
}

export interface Details {
  info: Info;
}

export interface SetDetails {
  type: typeof SET_DETAILS;
  payload: Details;
}
