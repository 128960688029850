import api, { jsonApi } from "../../api/axiosInterceptor";

export const letsChatAPI = {
  getAvailableSlots: (
    range_start: string,
    range_end: string,
    period: number
  ) => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const queryParams = new URLSearchParams({
      range_start,
      range_end,
      period: `${period}`,
      timezone: userTimezone,
      // timezone: "Europe/London",
    });

    return api
      .get(`/api/calendar/available-slots/?${queryParams.toString()}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slots:", error);
        // Handle error appropriately
      });
  },
  reserveSlot: (
    start_time: string,
    date: string,
    period: number,
    email: string,
    job_title: string,
    company_name: string,
    name: string,
    phone_number?: string
  ) => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const data = new FormData();
    data.append("start_time", start_time);
    data.append("date", date);
    data.append("period", `${period}`);
    data.append("email", email);
    data.append("timezone", userTimezone);
    phone_number && data.append("phone_number", phone_number);
    data.append("job_title", job_title);
    data.append("company_name", company_name);
    data.append("name", name);
    return api
      .post(`/api/calendar/reserve/`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error Reserving slot:", error);
      });
  },
  getAvailability: () => {
    return api
      .get(`/api/calendar/availability/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching availability:", error);
      });
  },
  updateOrCreateAvailability: (data: any) => {
    return jsonApi
      .post(
        `/api/calendar/availability/`,
        { ...data },
        { headers: { "content-type": "application/json" } }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error updating availability:", error);
      });
  },
};
