import React from "react";
import {
  Space,
  Checkbox,
  TimePicker,
  Form,
  Typography,
  Button,
  Divider,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Dayjs } from "dayjs";
import styles from "./WeeklyHours.module.css"; // Import your stylesheet

const { Text } = Typography;

// Define your types here
type TimeSlot = {
  startTime: Dayjs;
  endTime: Dayjs;
  key: number;
};

type WeeklyHoursProps = {
  daysOfWeek: string[];
  daySlots: Record<string, TimeSlot[]>;
  toggleDayAvailability: (day: string, checked: boolean) => void;
  removeTimeSlot: (day: string, key: number) => void;
  addTimeSlot: (day: string) => void;
  onTimeChange: (
    day: string,
    index: number,
    time: Dayjs,
    type: "startTime" | "endTime"
  ) => void;
  validateTimeSlot: (
    value: Dayjs,
    day: string,
    index: number,
    slotType: "startTime" | "endTime"
  ) => Promise<void>;
  format: string;
};

const WeeklyHours: React.FC<WeeklyHoursProps> = ({
  daysOfWeek,
  daySlots,
  toggleDayAvailability,
  removeTimeSlot,
  addTimeSlot,
  onTimeChange,
  validateTimeSlot,
  format,
}) => {
  return (
    <div>
      {daysOfWeek.map((day, index) => (
        <div key={day} className={styles.dayContainer}>
          <Space align="center" className={styles.dayHeader}>
            <Form.Item
              className={styles.dayAvailability}
              name={[day, "available"]}
              valuePropName="checked"
              initialValue={Boolean(daySlots[day].length > 0)}
            >
              <Checkbox
                onChange={(e) => toggleDayAvailability(day, e.target.checked)}
              />
            </Form.Item>
            <Text strong>{day}</Text>
          </Space>
          {daySlots[day].map((slot, index) => (
            <Space
              key={slot.key}
              className={styles.slotContainer}
              align="baseline"
            >
              <Form.Item
                name={[day, index, "startTime"]}
                initialValue={slot.startTime}
                rules={[
                  { required: true, message: "Start time required" },
                  {
                    message: "Start time is overlapped",
                    validator: (_, value) =>
                      validateTimeSlot(value, day, index, "startTime"),
                  },
                ]}
              >
                <TimePicker
                  format={format}
                  onChange={(time) =>
                    onTimeChange(day, index, time as Dayjs, "startTime")
                  }
                />
              </Form.Item>

              <Form.Item
                name={[day, index, "endTime"]}
                initialValue={slot.endTime}
                rules={[
                  { required: true, message: "End time required" },
                  {
                    message: "End time is overlapped",
                    validator: (_, value) =>
                      validateTimeSlot(value, day, index, "endTime"),
                  },
                ]}
              >
                <TimePicker
                  format={format}
                  onChange={(time) =>
                    onTimeChange(day, index, time as Dayjs, "endTime")
                  }
                />
              </Form.Item>

              <MinusCircleOutlined
                onClick={() => removeTimeSlot(day, slot.key)}
              />
            </Space>
          ))}
          {daySlots[day].length > 0 && (
            <Button
              type="dashed"
              onClick={() => addTimeSlot(day)}
              icon={<PlusOutlined />}
            >
              Add slot
            </Button>
          )}
          {index !== daysOfWeek.length - 1 && <Divider />}
        </div>
      ))}
    </div>
  );
};

export default WeeklyHours;
