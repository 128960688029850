import styled from "styled-components";
import { Row, Space, Layout } from "antd";

export const Arrow = styled(Space)`
  font-size: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 1840px) {
    display: none;
  }
`;

export const CustomRightArrow = styled(Arrow)``;

export const CustomLeftArrow = styled(Arrow)`
  padding-right: 4rem;
`;

export const SliderRow = styled(Row)`
  display: flex;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;
  font-size: 22pt;
`;

export const Divider = styled.div`
  border-style: solid;
  border-right-style: none;
  height: 55px;
  width: 0.5px;
`;

export const Container = styled(Layout)`
  background-color: #f5f5f50;
  min-height: 320px;
  @media (max-width: 800px) {
    padding-bottom: 16px;
  }
`;

export const InnerContianer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Desc = styled.div`
  font-size: 23pt;
  max-width: 1200px;
  text-align: center;
  margintop: 8px;
  font-family: "Co-Headline-W23-Arabic-Light-4", sans-serif;
`;

export const Title = styled.div`
  text-transform: upperCase;
  font-size: 22px;
  font-family: "Abdo-Master-SemiBold", sans-serif;
`;
