import { Rate } from "antd";
import { useGetEmployeeExperience } from "../../network/controllers/employeeExperience";

import {
  Container,
  Desc,
  JobTitle,
  Content,
  CarouselContainer,
  EmpExpCard,
  EmployeeName,
  ReadMoreBtn,
} from "./EmployeeExperienceStyles";
import { Carousel } from "antd";
import { CarouselRef } from "antd/es/carousel";
import { useEffect, useRef, useState } from "react";
import femaleImage from "../../images/girl-emp.png";
import maleImage from "../../images/male-emp.png";
import doubleComma from "../../images/commas.png";
import leftArrow from "../../images/arrow-left-emp.png";
import rightArrow from "../../images/arrow-right-emp.png";

const getCharLengthToDisplay = (width: number) => {
  let charNumberToShow = 190;
  if (width <= 1200) charNumberToShow = 140;
  else if (width <= 1300) charNumberToShow = 90;
  else if (width <= 1500) charNumberToShow = 180;
  return charNumberToShow;
};

const getStringToDisplay = (charNumber: number, desc: string): string => {
  if (desc.length > charNumber) return desc.slice(0, charNumber) + "...";
  return desc;
};

const getImageByGender = (gender: string, image: string): string => {
  if (image) return image;
  else if (gender === "male") return maleImage;
  else return femaleImage;
};

function EmployeeExperience() {
  const carousel: any = useRef<CarouselRef>(null);
  const employeeExperience: any[] = useGetEmployeeExperience();

  if (!employeeExperience) {
    return <div></div>;
  }

  return (
    <Container>
      <CarouselContainer>
        <Carousel
          style={{ width: "auto", height: "auto" }}
          ref={carousel}
          rows={1}
          dots={true}
          slidesToShow={2}
          slidesToScroll={2}
          dotPosition="bottom"
          infinite
          adaptiveHeight
          initialSlide={0}
          arrows={true}
          nextArrow={<img src={rightArrow} />}
          prevArrow={<img src={leftArrow} />}
          responsive={[
            {
              breakpoint: 1536,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: true,
              },
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                dots: true,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                arrows: false,
              },
            },
          ]}
          autoplay
        >
          {employeeExperience.map((experience: any) => (
            <EmpExpCard
              cover={
                <img
                  alt={experience.full_name}
                  src={getImageByGender(experience.gender, experience.image)}
                />
              }
            >
              <div
                key={experience.id}
                style={{
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Content>
                  <img src={doubleComma} />
                  <EmployeeName>{experience.full_name}</EmployeeName>
                  <JobTitle>{experience.job_title}</JobTitle>
                  <Desc>{experience.description}</Desc>
                </Content>
              </div>
            </EmpExpCard>
          ))}
        </Carousel>
      </CarouselContainer>
    </Container>
  );
}

export default EmployeeExperience;
