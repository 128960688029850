import styled from "styled-components";
import trainingImage from "../../images/training_programs.jpg";

export const TitleDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 160px;
  max-width: 100%;
  min-height: 100px;
  margin: 0 auto;
  text-align: center;
  white-space: normal;
`;

export const TitleHeader = styled.h1`
  color: #ffd100;
  font-size: 3.8em;

  @media (max-width: 1090px) {
    font-size: 2.5em;
  }

  @media (max-width: 768px) {
    font-size: 1.7em;
  }
`;
const overlayColor = "rgba(0, 0, 0, 0.5)";
const transparentOverlayColor = "rgba(0, 0, 0, 0)";

export const ImageDiv = styled.div`
  position: relative;
  margin: 30px -100px 30px -235px;
  padding-bottom: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${overlayColor};
    z-index: 1;
    transition: background-color 0.3s; /* Add a smooth transition effect */
  }

  &:hover:before {
    background-color: ${transparentOverlayColor};
  }
  background-image: url(${trainingImage});
  background-size: cover;
  background-repeat: no-repeat;
  height: 645px;
  background-position: center;

  @media (max-width: 768px) {
    margin: 30px -100px 30px -100px;
  }
`;

export const Line = styled.div`
  position: absolute;
  border-left: 5px solid #fff;
  height: 100px;
  left: 50%;
  top: 50px;
  transform: translate(-50%, -50%);
  z-index: 2;
  margin-bottom: 10px;
  @media (max-width: 1024px) {
    height: 50px;
    left: 50%;
    top: 2%;
  }

  @media (max-width: 768px) {
    border-left: 3px solid #fff;
    top: 3%;
  }
`;

export const Line2 = styled.hr`
  margin: 1.7rem auto 1.7rem !important;
  border-top: 2px solid #ffd100;
  opacity: 1;
  width: 20%;
  z-index: 2;
  position: relative;
`;

export const Desc = styled.p`
  font-family: Cairo-Regular, sans-serif;
  font-size: 2em;
  color: #fff;
  text-align: center;
  white-space: pre-wrap;
  z-index: 2;
  position: relative;
  @media (max-width: 768px) {
    font-size: 1.3em;
  }
`;
