import styled from "styled-components";
import { Card as AntdCard } from "antd";

export const Container = styled.div`
  position: relative;
  background-color: #ffd100;
  padding: 10rem;
  min-width: 100%;
  overflow: hidden;
  margin-bottom: 25px;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const Desc = styled.p`
  font-family: Co-Headline-W23-Arabic-Light-4, sans-serif;
  font-size: 1.5em;
  text-align: center;
  color: #4d4d4d;
  font-weight: bold;
  margin-bottom: 5px;
  overflow-y: auto;
  max-height: 200px;
  line-height: normal;

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }

  @media (max-width: 1200px) {
    font-size: 1.57em;
  }

  @media (max-width: 800px) {
    font-size: 1.35em;
  }
`;

export const JobTitle = styled.h1`
  fontfamily: Roboto, DIN-NEXT-ARABIC-LIGHT, sans-serif;
  color: #383838;
  font-weight: bold;
  font-size: 2em;

  @media (max-width: 1200px) {
    font-size: 1.8em;
  }

  @media (max-width: 800px) {
    font-size: 1.5em;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;

  @media (max-width: 1400px) {
    img {
      display: none !important;
    }
  }
`;

export const CarouselContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ant-carousel {
    width: 100%;
    height: 85%;

    .slick-slide {
      margin-top: 72px;
      padding: 25px;
    }

    .slick-slider,
    .slick-list,
    .slick-track,
    .slick-slide {
      height: 100% !important;
    }
    

    .slick-dots {
      bottom: -120px;

      button {
        height: 14px;
        width: 14px;
        border-radius: 50%;
        opacity: 0.6;
      }
    }

`;

export const EmpExpCard = styled(AntdCard)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evently;
    border: 1px solid #ddd;
    text-align: center;
    width: 36rem;
    height: 30rem;
    box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.15);
    border-radius: 3rem;

    @media (max-width: 768px) {
      height: 20rem;
    }

    .ant-card {
      display: none;
      overflow: hidden;
    }

    .ant-card-cover {
      width: 13.5rem;
      height: 13.5rem;
      border-radius: 50%;
      overflow: hidden;
      margin: -6rem auto 0;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.15);
      border-width: 0.1px;

      @media (max-width: 650px) {
        display: none;
      }
    }
    .ant-card-cover img {
      width: 100%;
      object-fit: cover;
    }

    .ant-card-body {
      padding-bottom: 20px;
      overflow: hidden;
    }
  }
`;

export const EmployeeName = styled.h3`
  fontfamily: Roboto, DIN-NEXT-ARABIC-LIGHT, sans-serif;
  color: #383838;
  font-size: 2em;

  @media (max-width: 1200px) {
    font-size: 1.8em;
  }

  @media (max-width: 800px) {
    font-size: 1.5em;
  }
`;

export const ReadMoreBtn = styled.button`
  color: #383838;
  background-color: #fff;
  border: 1px solid #383838;
  border-radius: 8px;
  font-weight: bold;
  width: 88px;
  text-align: center;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  order: 99999;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 800px) {
    display: none;
  }
`;
