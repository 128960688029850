import styled from "styled-components";

interface ContainerProps {
  imageUrl: string;
}

export const Card = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  height: 18em;
  width: 27em;
  background-position-y: center;
  background-position-x: center;

  background-size: cover;
  border-radius: 30px;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
    transition: opacity 0.3s ease;
    border-radius: 30px;
  }

  &:hover {
    &::before {
      opacity: 0; /* Make the overlay fully visible on hover */
    }
  }

  @media (max-width: 768px) {
    width: inherit;
  }
`;

export const Line = styled.hr`
  margin: 20px auto 2rem !important;
  border-top: 2px solid #ffd100;
  opacity: 1;
  width: 20%;
  z-index: 1;
`;

export const Title = styled.h1`
  z-index: 1;
  color: #fff;
  font-style: bold;
  text-align: center;
`;
