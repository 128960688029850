import React from "react";
import { TitleTalentDescContainer, TalentDesc } from "./TalentDetailsStyles";
import { i18n } from "../../translations";

const TalentDetailsDesc: React.FC = () => {
  return (
    <>
      <TitleTalentDescContainer>
        <TalentDesc>{i18n.t("talent_details_desc")}</TalentDesc>
      </TitleTalentDescContainer>
    </>
  );
};

export default TalentDetailsDesc;
