import React from "react";
import {
  Container,
  Divider,
  StyledUl,
  StyledLi,
  StyledLiArrow,
  StyleLiText,
  ListContiner,
} from "./TalentDetailsStyles";

type TalentDetailsContentProps = {
  items: string[];
};

const TalentDeatilsContent: React.FC<TalentDetailsContentProps> = ({
  items,
}: TalentDetailsContentProps) => {
  return (
    <>
      <Container style={{ minWidth: "200px" }}>
        <Divider />
        <ListContiner>
          <StyledUl>
            {items.map((item, index) => (
              <StyledLi key={index}>
                <StyledLiArrow />
                <StyleLiText>{item}</StyleLiText>
              </StyledLi>
            ))}
          </StyledUl>
        </ListContiner>
      </Container>
    </>
  );
};

export default TalentDeatilsContent;
