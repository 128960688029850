import React from "react";
import { Dropdown, Menu, Typography } from "antd";
import styles from "./Header.module.css";
import dayjs, { Dayjs } from "dayjs";
import { CaretDownOutlined } from "@ant-design/icons";

type HeaderProps = {
  selectedDate: Dayjs;
  onMonthSelect: (month: number) => void;
  onYearSelect: (year: number) => void;
};

function getAllMonths() {
  const months = [];
  for (let month = 0; month < 12; month++) {
    months.push(dayjs().month(month).format("MMMM"));
  }
  return months;
}

const currentYear = dayjs().year();
const currentMonth = dayjs().month();

const Header: React.FC<HeaderProps> = ({
  selectedDate,
  onMonthSelect,
  onYearSelect,
}) => {
  const monthMenu = (
    <Menu>
      {getAllMonths().map((month, index) => (
        <Menu.Item
          key={month}
          disabled={index < currentMonth && selectedDate.year() === currentYear}
          onClick={() => onMonthSelect(index)}
        >
          {month}
        </Menu.Item>
      ))}
    </Menu>
  );

  const yearMenu = (
    <Menu>
      {Array.from({ length: 10 }, (_, i) => currentYear + i).map((year) => (
        <Menu.Item key={year} onClick={() => onYearSelect(year)}>
          {year}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className={styles.header}>
      <div className={styles.dropdownContainer}>
        <Dropdown overlay={monthMenu} trigger={["click"]}>
          <div className={styles.dropdown}>
            <Typography className={styles.monthText}>
              {selectedDate.format("MMMM")}
            </Typography>
            <CaretDownOutlined className={styles.dropdownArrow} />
          </div>
        </Dropdown>

        <Dropdown overlay={yearMenu} trigger={["click"]}>
          <div className={styles.dropdown}>
            <Typography className={styles.yearText}>
              {selectedDate.format("YYYY")}
            </Typography>
            <CaretDownOutlined className={styles.dropdownArrow} />
          </div>
        </Dropdown>
      </div>

      <Typography className={styles.currentDateText}>
        {selectedDate.date()}
      </Typography>
    </div>
  );
};

export default Header;
