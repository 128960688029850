import { Modal, Button, message, Form, Input } from "antd";
import api from "../../api/axiosInterceptor";
import { useRef, useState } from "react";
import Editor from "../Editor/Editor";

interface InsertModalProps {
  setIsInsertOpen: (isModalOpen: boolean) => void;
  isInsertOpen: boolean;
  refresh: boolean;
  setRefresh: (refresh: boolean) => void;
}

const InsertModal = (props: InsertModalProps) => {
  const [Image, setImage] = useState<Blob>();

  const editorRef: any = useRef(null);
  const [form] = Form.useForm();

  const handleCancel = () => {
    props.setIsInsertOpen(false);
  };

  const onFinish = async (values: any) => {
    const data = new FormData();

    values.description
      ? data.append("description", values.description)
      : data.append("description", "");

    Image && data.append("image", Image);

    await api
      .post(`api/securityAndCompliance/`, data)
      .then((result: { data: any }) => {
        message.success(`Added successfully`);
        props.setRefresh(!props.refresh);
        props.setIsInsertOpen(false);
        return result && result.data;
      })
      .catch((e) => {
        message.error(`Added failed.`);
      });
  };

  return (
    <Modal
      title="Insert new Section"
      open={props.isInsertOpen}
      onCancel={handleCancel}
      destroyOnClose={true}
      width={"50%"}
      footer={[
        <Button key="cancel" onClick={handleCancel} type="primary">
          Cancel
        </Button>,
      ]}
    >
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 25 }}
        layout="horizontal"
        style={{ maxWidth: 1000 }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true }]}
        >
          <Editor
            setEditor={(editor: any) => (editorRef.current = editor)}
            defaultValue={null}
            onChange={(text: any) => {
              form.setFieldValue("description", text);
            }}
          />
        </Form.Item>
        <Form.Item label="Image" name="image">
          <Input
            type={"file"}
            name={"image"}
            id={"image"}
            accept="image/*"
            onChange={(e: any) => {
              setImage(e.currentTarget.files[0]);
            }}
          />
        </Form.Item>
        <Form.Item style={{ float: "right", marginLeft: "5px" }}>
          <Button type="primary" htmlType="submit">
            Insert
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InsertModal;
