import { Button, Modal } from "antd";
import { i18n } from "../../translations";
import { useEffect, useState } from "react";
import api from "../../api/axiosInterceptor";
import { styled } from "styled-components";
import { useGetHowDifferent } from "../../network/controllers/howDifferent";

const StyledModal = styled(Modal)`
  .ant-modal-title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #ffd100;
    margin-bottom: 20px;
    background-color: #d6d6d6;
  }

  .ant-modal-content {
    background-color: #d6d6d6;
    padding: 32px 24px;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #d6d6d6;
  justify-content: center;
  align-items: center;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 20px;
  background-color: #f4f4f4;
  justify-content: center;
  align-items: center;
  min-width: 60%;
  padding: 2%;
  width: -webkit-fill-available;
`;

const StyledTitle = styled.h1`
  color: #ffd100;
  font-size: 3em;
  font-family: abdomaster-medium, sans-serif;
  text-align: center;
  width: 80%;

  @media (max-width: 768px) {
    font-size: 2em;
  }

  @media (max-width: 300px) {
    font-size: 1.5em;
  }
`;

const StyledDesc = styled.p`
  color: #606060;
  font-family: Co-Headline-W23-Arabic-Light-4, sans-serif;
  font-size: 1.5em;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const Popup = (props: {
  popupOpen: boolean;
  setPopup: (popup: boolean) => void;
}) => {
  const howDifferentData: any[] = useGetHowDifferent();

  return (
    <StyledModal
      open={props.popupOpen}
      onCancel={() => props.setPopup(false)}
      footer={null}
      centered
      width={"70%"}
      bodyStyle={{ margin: "0 3%", backgroundColor: "#d6d6d6" }}
    >
      <StyledDiv>
        {howDifferentData &&
          howDifferentData.map((item, index) => {
            return (
              <StyledContent key={index}>
                <StyledTitle>{item.title}</StyledTitle>
                <StyledDesc
                  dangerouslySetInnerHTML={{ __html: item.description }}
                ></StyledDesc>
              </StyledContent>
            );
          })}
      </StyledDiv>
    </StyledModal>
  );
};

export default Popup;
