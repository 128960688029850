import { Modal, Upload, Button, message, Form, Input } from "antd";
import api from "../../api/axiosInterceptor";
import { useEffect, useState } from "react";
import { SWServices } from "../../types/types";
import { DeleteFilled } from "@ant-design/icons";

interface EditModalProps {
  setIsEditOpen: (isModalOpen: boolean) => void;
  isEditOpen: boolean;
  refresh: boolean;
  id: number | undefined;
  setRefresh: (refresh: boolean) => void;
  data: SWServices;
}

const EditModal = (props: EditModalProps) => {
  const [file, setFile] = useState<Blob>();
  const [form] = Form.useForm();

  const handleCancel = () => {
    props.setIsEditOpen(false);
  };

  const handleEdit = (values: any) => {
    const data = new FormData();
    data.append("title", values.title);
    data.append("examples", values.examples);
    let image: Blob | string = file || values.image;
    if (typeof image === "string") {
      image = image.split("media")[1];
    }
    image && data.append("image", image);
    onFinish(data);
  };

  const onFinish = async (data: any) => {
    await api
      .put(`api/swServices/${props?.id}/`, data)
      .then((result: { data: any }) => {
        props.setRefresh(!props.refresh);
        props.setIsEditOpen(false);
        message.success(`Edited successfully.`);
        return result && result.data;
      })
      .catch(() => {
        message.error(`Edited failed.`);
      });
  };

  useEffect(() => {
    form.setFieldsValue({
      title: props.data?.title,
      examples: props.data?.examples,
      image: props.data?.image,
    });
  }, [props.data]);

  return (
    <Modal
      title="Edit Service"
      open={props.isEditOpen}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel} type="primary">
          Cancel
        </Button>,
      ]}
      width={"40%"}
    >
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        style={{ maxWidth: 1000 }}
        onFinish={handleEdit}
        initialValues={{
          title: props.data?.title,
          examples: props.data?.examples,
          image: props.data?.image,
        }}
        form={form}
      >
        <Form.Item label="Service Title" name="title">
          <Input />
        </Form.Item>
        <Form.Item label="Examples" name="examples">
          <Input />
        </Form.Item>
        <Form.Item label="old Image" name="image">
          <Input />
        </Form.Item>
        <Form.Item label="new Image" name="newImage">
          <Input
            type={"file"}
            name={"image"}
            id={"image"}
            accept="image/*"
            onChange={(e: any) => {
              setFile(e.currentTarget.files[0]);
            }}
          />
        </Form.Item>
        <Form.Item style={{ float: "right", marginLeft: "5px" }}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditModal;
