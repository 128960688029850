import { Card as AntdCard } from "antd";
import styled from "styled-components";

export const LeaderCard = styled(AntdCard)`
  && {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ddd;
    text-align: center;
    margin-top: 130px;
    width: 262px;
    height: 292px;
    box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.15);

    .ant-card-cover {
      width: 13.5rem;
      height: 13.5rem;
      border-radius: 50%;
      overflow: hidden;
      margin: -6rem auto 0;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.15);
      border-width: 0.1px;
    }
    .ant-card-cover img {
      width: 100%;
      object-fit: cover;
    }

    .ant-card-body {
      padding-bottom: 20px;
    }
    .ant-card-actions {
      width: 100%;
      display: flex;
      justify-content: center;
      border: none;
    }

    a {
      text-decoration: none;
      color: green;
      justify-content: end;

      &:hover {
        color: black;
      }
    }
  }
`;

export const { Meta: MetaLeaderCard } = LeaderCard;
