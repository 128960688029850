import { styled } from "styled-components";
import { Card } from "antd";

export const StyledCard = styled(Card)`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  .ant-card-head {
    min-width: 100%;
    text-align: center;
  }

  .ant-card-head-title {
    width: 100%;
    img {
      display: block;
      margin: 0 auto;
    }
  }

  .ant-card-body {
    padding: 0 10% 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .ant-card-head {
    border-bottom: none;
  }
`;

export const Line = styled.hr`
  margin: 0 auto 2rem !important;
  border-top: 2px solid #ffd100;
  opacity: 1;
  width: 100%;
`;

export const Desc = styled.p`
  font-family: Cairo-Regular, sans-serif;
  font-size: 1.5em;
  color: #808080;
`;
