import { styled } from "styled-components";

export const Title = styled.h4`
  text-transform: uppercase;
  text-align: center;
  font-family: abdomaster-medium, sans-serif;
  font-size: 18pt;
  margin-top: 10px;
  font-weight: 600;
  @media (max-width: 768px) {
    transform: scale(0.8);
    margin: 0 !important;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Section = styled.div`
  margin-bottom: 65px;
  text-align: center;
  padding: 0 50px 0 12%;
  overflow-x: auto;
`;

export const ServicesSection = styled.div`
  position: relative;
  margin-bottom: 65px;
  text-align: center;
`;
