import { Modal, Upload, Button, message, Form, Input, Checkbox } from "antd";
import api from "../../api/axiosInterceptor";
import { useEffect, useRef, useState } from "react";
import { LifeAtAsal } from "../../types/types";
import { DeleteFilled } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/es/checkbox";

interface EditModalProps {
  setIsEditOpen: (isModalOpen: boolean) => void;
  isEditOpen: boolean;
  refresh: boolean;
  id: number | undefined;
  setRefresh: (refresh: boolean) => void;
  data: LifeAtAsal;
}

const EditModal = (props: EditModalProps) => {
  const [Video, setVideo] = useState<Blob>();
  const editorRef: any = useRef(null);
  const [form] = Form.useForm();
  const [active, setActive] = useState(props.data?.is_active);

  const handleCancel = () => {
    props.setIsEditOpen(false);
  };

  const onChange = (e: CheckboxChangeEvent) => {
    setActive(e.target.checked);
  };

  const handleEdit = (values: any) => {
    const data = new FormData();
    data.append("name", values.name);
    data.append("is_active", active ? "1" : "0");
    let video: Blob | string = Video || props.data.video;

    if (typeof video === "string") {
      video = video.split("media")[1];
    }
    video && data.append("video", video);

    onFinish(data, false);
  };

  const onFinish = async (data: any, video: boolean) => {
    await api
      .put(`api/lifeAtAsal/${props?.id}/`, data)
      .then((result: { data: any }) => {
        video
          ? message.success("video deleted successfully")
          : message.success(`Edited successfully`);
        props.setRefresh(!props.refresh);
        !video && props.setIsEditOpen(false);
        return result && result.data;
      })
      .catch(() => {
        message.error(`Edited failed.`);
      });
  };

  const deleteVideo = () => {
    const values = {
      name: props.data?.name,
      is_active: props.data?.is_active,
      video: null,
    };
    onFinish(values, true);
    setVideo(undefined);
  };

  useEffect(() => {
    form.setFieldsValue({
      name: props.data?.name,
      Video: props.data?.video,
    });
    setActive(props.data?.is_active);
  }, [props.data]);

  return (
    <Modal
      title="Edit Video"
      open={props.isEditOpen}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel} type="primary">
          Cancel
        </Button>,
      ]}
      width={"50%"}
    >
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 20 }}
        layout="horizontal"
        style={{ maxWidth: 1000 }}
        onFinish={handleEdit}
        initialValues={{
          name: props.data?.name,
          is_active: active,
          video: props.data?.video,
        }}
        form={form}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input placeholder="name" />
        </Form.Item>
        <Form.Item label="Video" name="video">
          <Upload
            name={"video"}
            id={"video"}
            accept="video/mp4,video/x-m4v,video/*"
            beforeUpload={(file) => {
              setVideo(file);
              return false;
            }}
          >
            <Button>Choose Video</Button>
          </Upload>
          {props.data?.video && (
            <p>
              {props.data.video.split("media/")[1]}{" "}
              <DeleteFilled
                style={{ fontSize: "15px", color: "red" }}
                rev={undefined}
                onClick={() => deleteVideo()}
              />
            </p>
          )}
        </Form.Item>
        <Form.Item label="Video status" name="is_active">
          <Checkbox onChange={onChange} value={active} checked={active}>
            Active
          </Checkbox>
        </Form.Item>
        <Form.Item style={{ float: "right", marginLeft: "5px" }}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditModal;
