import { Modal, Button, message, Form, Input } from "antd";
import api from "../../api/axiosInterceptor";
import { useState } from "react";

interface InsertModalProps {
  setIsInsertOpen: (isModalOpen: boolean) => void;
  isInsertOpen: boolean;
  refresh: boolean;
  setRefresh: (refresh: boolean) => void;
}

const InsertModal = (props: InsertModalProps) => {
  const [file, setFile] = useState<Blob>();
  const [form] = Form.useForm();

  const handleCancel = () => {
    props.setIsInsertOpen(false);
  };
  const onFinish = async (values: any) => {
    const data = new FormData();
    data.append("title", values.title);
    values.examples
      ? data.append("examples", values.examples)
      : data.append("examples", "");

    file && data.append("image", file);

    await api
      .post(`api/swServices/`, data)
      .then((result: { data: any }) => {
        message.success(`Added successfully`);
        props.setRefresh(!props.refresh);
        props.setIsInsertOpen(false);
        return result && result.data;
      })
      .catch((e) => {
        if (e.response.status === 500) message.error(`Title must be unique`);
        else message.error(`Added failed.`);
      });
  };

  return (
    <Modal
      title="Insert new Software Service"
      open={props.isInsertOpen}
      onCancel={handleCancel}
      width={"40%"}
      footer={[
        <Button key="cancel" onClick={handleCancel} type="primary">
          Cancel
        </Button>,
      ]}
    >
      <Form
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 25 }}
        layout="horizontal"
        style={{ maxWidth: 1000 }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label="Service Title"
          name="title"
          rules={[{ required: true }]}
        >
          <Input placeholder="Frontend" />
        </Form.Item>
        <Form.Item label="Service Examples" name="examples">
          <Input placeholder="React, JavaScript, Angular" />
        </Form.Item>
        <Form.Item label="Image" name="image" rules={[{ required: true }]}>
          <Input
            type={"file"}
            name={"image"}
            id={"image"}
            accept="image/*"
            onChange={(e: any) => {
              setFile(e.currentTarget.files[0]);
            }}
          />
        </Form.Item>
        <Form.Item style={{ float: "right", marginLeft: "5px" }}>
          <Button type="primary" htmlType="submit">
            Insert
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InsertModal;
