import { useEffect } from "react";
import "./Offers.css";

interface OffersProps {
  department: string;
}

const loadScript = (src: string) => {
  return new Promise<void>((resolve, reject) => {
    // Check if script already exists before appending
    if (document.querySelector(`script[src="${src}"]`)) {
      resolve();
      return;
    }

    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(); // Resolve without argument
    };
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

const dropScript = (src: string) => {
  const ravenScript =
    document.querySelectorAll(`script[src="${src}"]`).length > 0;

  if (ravenScript) {
    document
      .querySelectorAll(`script[src="${src}"]`)
      .forEach((script) => script.parentNode?.removeChild(script));
  }
};

const removeElementsById = (id: string) => {
  const elements = document.querySelectorAll(`[id="${id}"]`);
  elements.forEach((element) => element.remove());
};

const Offers = ({ department }: OffersProps) => {
  const companyId = 40756;

  useEffect(() => {
    const scriptSrc = "https://jobs-widget.recruiteecdn.com/widget.js";
    const ravenScr =
      "https://cdnjs.cloudflare.com/ajax/libs/raven.js/3.25.2/raven.js";

    const recruitee_elem_id = "recruitee-careers";
    const recruitee_details_id = `${recruitee_elem_id}-details-container`;

    const originalAppendChild = document.body.appendChild;
    document.body.appendChild = function <T extends Node>(childNode: T): T {
      const sameIdCond =
        childNode instanceof Element &&
        childNode.id &&
        document.getElementById(childNode.id);

      if (sameIdCond) {
        return childNode;
      }

      dropScript(ravenScr);
      return originalAppendChild.call(this, childNode) as T;
    };

    // Load the script and wait for it to be loaded
    loadScript(scriptSrc)
      .then(() => {
        // Now you can use the global variable RTWidget
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const RTWidget = window.RTWidget;
        RTWidget.LOCALES.en.offer.description = "Job Description";
        RTWidget.LOCALES.en.offer.requirements = "Job Requirements";

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const widget: any = new RTWidget({
          elementId: recruitee_elem_id,
          companies: [companyId],
          detailsMode: "popup",
          language: "en",
          departmentsFilter: [department],
          tagsFilter: [],
          themeVars: {
            primary: "#FFD100",
            secondary: "#121317",
            text: "#5a5f72",
            textDark: "#FFD100",
            fontFamily:
              '"Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;',
            baseFontSize: "16px",
            boxShadow: "unset",
            defaultButtonBackground: "white",
            defaultButtonText: "red",
            actionButtonBackground: "red",
            actionButtonText: "blue",
            cancelButtonBackground: "green",
            cancelButtonText: "orange",
          },
          flags: {
            showLocation: false,
            showCountry: true,
            showCity: true,
            showState: true,
            groupByLocation: false,
            groupByState: false,
            groupByDepartment: true,
            groupByCompany: false,
            showApplyWithIndeed: false,
          },
        });
      })
      .catch((error) => {
        console.error("Error loading script:", error);
      });

    return () => {
      document.body.appendChild = originalAppendChild;
      removeElementsById(recruitee_details_id);
      dropScript(ravenScr);
    };
  }, []);

  return <div id="recruitee-careers"></div>;
};

export default Offers;
