import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Routers from "./routes/Routers";
import { Provider, useDispatch } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import api from "./api/axiosInterceptor";
import { ASALInfo, Header } from "./types/types";
import { setDetails } from "./redux/actions/asalDetails/detailsAction";
import { setHeaderFooterItems } from "./redux/actions/HeaderFooterItems/HeaderFooterItemsAction";
import {
  HeaderFooterItem,
  SubHeaderFooterItem,
} from "./redux/actions/HeaderFooterItems/types";
import TagManager from "react-gtm-module";

const CX = () => {
  const dispatch = useDispatch();
  const getInfo = async () => {
    try {
      const res = await api.get("/api/AsalDetails/");
      const data: { [x: string]: string }[] = [];
      res.data.map((item: ASALInfo) => {
        data.push({ [item.title]: item.description });
      });
      const object = Object.assign({}, ...data);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      dispatch(setDetails(object));
    } catch (e) {
      console.log(e);
    }
  };
  const getNavBarData = async () => {
    try {
      const res = await api.get("api/header/");
      const sub = await api.get("api/subheaders/");
      const headerItems = res.data.map((item: HeaderFooterItem) => {
        return {
          ...item,
          subtitles: sub.data.filter((subItem: SubHeaderFooterItem) => {
            return (
              subItem.header_title.id === item.id && subItem.page.is_enabled
            );
          }),
        };
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      dispatch(setHeaderFooterItems(headerItems));
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getNavBarData();
  }, []);

  useEffect(() => {
    getInfo();
  }, []);

  return <></>;
};

function App() {
  useEffect(() => {
    const gtmId = process.env.REACT_APP_GTM_ID || "";
    const tagManagerArgs = {
      gtmId: gtmId,
    };

    TagManager.initialize(tagManagerArgs);
  }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CX />
        <BrowserRouter>
          <Routers />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
