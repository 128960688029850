import {
  SET_HEADER_FOOTER_ITEMS,
  SetHeaderFooterItems,
  HeaderFooterItem,
} from "../actions/HeaderFooterItems/types";

const initialState: HeaderFooterItem[] = [];

export const headerFooterItemsReducer = (
  state = initialState,
  action: SetHeaderFooterItems,
) => {
  switch (action.type) {
    case SET_HEADER_FOOTER_ITEMS:
      return action.payload;
    default:
      return state;
  }
};
