import { styled } from "styled-components";
export const Table = styled.table`
  border-spacing: 11vh;
  border-collapse: separate;
  flex: 1;
  margin: 0 0 5% 10%;

  @media (max-width: 768px) {
    margin: 0 25%;
    td {
      display: table;
    }
  }
`;

export const LineTwo = styled.span`
  text-transform: uppercase;
  font-size: 23px;
`;

export const Cell = styled.div`
  border-bottom: 2px solid black;
  min-width: 300px;
  color: white;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    min-width: unset;
    width: 168px;
    height: 100%;
    transform: scale(0.8);
  }
`;

export const LineOne = styled.span`
  display: flex;
  justify-content: center;
  font-size: 72px;
  vertical-align: middle;
  font-weight: 600;
  & > p {
    align-self: center;
    font-size: 20px;
    margin-bottom: 0px;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;
