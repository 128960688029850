import { DeleteFilled, PlusCircleTwoTone, EditFilled } from "@ant-design/icons";
import { Space, Table } from "antd";
import { EngagmentModels } from "../../types/types";
import { useState, useEffect } from "react";
import api from "../../api/axiosInterceptor";
import DeleteModal from "../Modals/DeleteModal";
import EditModal from "./EditModal";
import InsertModal from "./InsertModal";

const EngagementModelsManage = () => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [id, setId] = useState<number>();
  const [data, setData] = useState<EngagmentModels[]>([]);
  const [isInsertOpen, setIsInsertOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [recordId, seRecordtId] = useState<number>(0);

  const columns = [
    {
      title: "id",
      width: "5%",
      render: (_: any, record: EngagmentModels) => data.indexOf(record) + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text: string) => text,
      align: "center" as const,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text: string) => text,
      align: "center" as const,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (text: string) => (
        <a href={text} target={"_blank"}>
          {text ? "see image" : "-"}
        </a>
      ),
      align: "center" as const,
    },
    {
      title: "Actions",
      key: "action",
      render: (_: any, record: any) => (
        <Space size="middle">
          {record.is_enabled}
          <a
            onClick={() => {
              setIsEditOpen(true);
              seRecordtId(data.indexOf(record) + 1);
              setId(record.id);
            }}
          >
            <EditFilled
              style={{ fontSize: "20px", color: "green" }}
              rev={undefined}
            />
          </a>
          <a
            onClick={() => {
              setIsDeleteOpen(true);
              setId(record.id);
            }}
          >
            <DeleteFilled
              style={{ fontSize: "20px", color: "red" }}
              rev={undefined}
            />
          </a>
        </Space>
      ),
      align: "center" as const,
    },
  ];

  const getPages = async () => {
    try {
      const res = await api.get("api/engagmentModels/");

      if (res) {
        setData(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getPages();
  }, [refresh]);

  return (
    <div style={{ margin: "50px" }}>
      <div onClick={() => setIsInsertOpen(true)}>
        <PlusCircleTwoTone
          rev={undefined}
          style={{
            fontSize: "25px",
            margin: "15px",
            verticalAlign: "middle",
          }}
        />
        <span
          style={{
            verticalAlign: "middle",
            marginBottom: "25px",
          }}
        >
          Insert new Engagment Model
        </span>
      </div>
      <Table bordered columns={columns} dataSource={data} pagination={false} />
      <DeleteModal
        isDeleteOpen={isDeleteOpen}
        setIsDeleteOpen={setIsDeleteOpen}
        id={id}
        refresh={refresh}
        setRefresh={setRefresh}
        name="engagmentModels"
      />
      <EditModal
        isEditOpen={isEditOpen}
        setIsEditOpen={setIsEditOpen}
        id={id}
        data={data[recordId - 1]}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <InsertModal
        isInsertOpen={isInsertOpen}
        setIsInsertOpen={setIsInsertOpen}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    </div>
  );
};

export default EngagementModelsManage;
