import Offers from "../Offers/Offers";
import "./OffersContainer.css";
import { useParams } from "react-router-dom";
import { SPECIAL_PAGES } from "../../constants/strings";

const OffersContainer = () => {
  const param = useParams();
  let department = "";
  if (param.title === SPECIAL_PAGES.OFFERS) department = param.subtitle!;
  return (
    <div className="offers-container">
      <Offers department={department}></Offers>
    </div>
  );
};

export default OffersContainer;
