import styled from "styled-components";
import { Card, Layout } from "antd";

export const Container = styled(Layout)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  background-color: #f5f5f5;
  min-height: 320px;
  margin-top: 10px;
  margin-bottom: 10px;
  @media (max-width: 800px) {
    padding-bottom: 16px;
  }
`;

// TalentDeatilsHeader Styles
export const TitleContainer = styled(Container)`
  background-color: #e9e9e9;
  margin: 30px -100px 30px -235px;
  justify-content: flex-end;
  padding-bottom: 10px;

  @media (max-width: 768px) {
    margin: 30px -40px 30px -15px;
  }
`;

export const Title = styled.h1`
  font-size: 2.5em;
  color: #ffd100;

  @media (max-width: 768px) {
    font-size: 1.8em;
  }
`;

// end TalentDeatilsHeader Styles

// start TalentDeatilsDesc Style
export const TitleTalentDescContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em;
  overflow: hidden;
  border: 1px solid;
  border-radius: 14px;
  text-align: center;
`;

export const TalentDesc = styled.p`
  font-size: 1.3em;
  margin-bottom: 0px;
`;

// end TalentDeatilsDesc Style

// start TalentDeatilsContent Style

export const Divider = styled.div`
  border-style: solid;
  border-right-style: none;
  height: 55px;
  width: 0.5px;
`;

export const StyledUl = styled.ul`
  list-style: none;
`;

export const StyledLi = styled.li`
  position: relative;
  padding-bottom: 10px;
`;

export const StyledLiArrow = styled.div`
  content: "";
  position: absolute;
  border-right: 4px solid #ffd100;
  border-bottom: 4px solid #ffd100;
  width: 20px;
  height: 20px;
  top: calc(50% - 5px);
  left: -40px;
  transform: translateY(-50%) rotate(-45deg);
`;

export const ListContiner = styled.div`
  display: flex;
  flex-direction: column;
  align-self: start;
  margin-bottom: 80px;
  margin-left: 72px;
  @media (max-width: 500px) {
    margin-left: 25px;
  }
`;

export const StyleLiText = styled.p`
  font-size: 2.2em;
  margin-bottom: 0;
  text-align: start;
  font-weight: bold;
  padding-bottom: 0px;
  @media (max-width: 650px) {
    font-size: 1.8em;
  }
`;

export const ContentTitle = styled.h2`
  font-size: 2.2em;
`;
// end TalentDeatilsObjective Style

export const StyledCard = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f4f4f4;
  .ant-card-head {
    min-width: 100%;
    text-align: center;
  }

  .ant-card-head-title {
    width: 100%;
    img {
      display: block;
      margin: 0 auto;
    }
  }

  .ant-card-body {
    padding: 0 10% 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .ant-card-head {
    border-bottom: none;
  }
`;

export const Line = styled.hr`
  margin: 0 auto 2rem !important;
  border-top: 2px solid #ffd100;
  opacity: 1;
  width: 40%;
`;

export const Desc = styled.p`
  font-family: Cairo-Regular, sans-serif;
  font-size: 1.5em;
  color: #000;
  text-align: center;
`;

export const TalentDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;
