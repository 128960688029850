export const PARTNERS_HEADER_TITLE = "Partners";
export const Gallery_HEADER_TITLE = "within Asal";
export const NUMBERS_HEADER_TITLE = "our numbers show our story";
export const SERVICES_HEADER_TITLE = "services";
export const SPECIAL_PAGES_TITLES = {
  OFFERS_TITLE: "Vacancies",
  OFFERS_INTERNSHIP_SUBTITLE: "Internship",
};

export const SPECIAL_PAGES = {
  ECOSYSTEM: "Ecosystem",
  GALLERY: "Gallery",
  KNOW_US: "Get to know us",
  SERVICES: "Services",
  OFIICES: "Offices",
  INDUSTRIES: "Industries",
  REACH_US_FORM: "Reach Us Form",
  REACH_US: "Let's Stay Connected!",
  OUR_LEADERSHIP: "Our Leadership",
  NEWSLETTER: "ASAL Newsletter",
  VACANCIES: "Vacancies",
  EMPLOYEE_EXP: "Employee Experience",
  JOIN_US: "Ready to Join Us",
  HOW_DIFFERENT: "How are We Different",
  THEY_TRUST_US: "They Trust Us",
  WHAT_MATTER_TO_US: "What matters to Us",
  WITHIN_COMMUNITY: "Community Engagement",
  SECURITY_AND_COMPLIANCE: "Security and Compliance",
  OFFERS: "Offers",
  LETS_CHAT: "Want to Know More? Lets' Chat!",
  ENGAGEMENT_MODELS: "Engagement Models",
  BLOG: "Blog",
  NEWS: "Press Releases",
  TRAINING_PROGRAMS: "Talent Development",
  APPLAY_ANYWAY: "No Openings? Apply Anyways",
  LIFE_AT_ASAL: "Life at ASAL",
  CLIENT_WE_SERVED_ON_THE_MAP: "Clients We Served on the Map",
};
