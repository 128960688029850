import React from "react";
import { useGetWithinCommunity } from "../../network/controllers/withinCommunity";
import WithinCommunityImage from "../../images/news-bg.jpg";
import CardGrid from "../CardGrid/CardGrid";

const WithinCommunity: React.FC = () => {
  const withinCommunity: any[] = useGetWithinCommunity();
  return (
    <CardGrid data={withinCommunity} defaultCardImage={WithinCommunityImage} />
  );
};

export default WithinCommunity;
